import React from 'react';
import { Element } from 'react-scroll';
import AppliedAISolutionsSectionTitle from './AppliedAISolutionsSectionTitle';

const OurAppliedAISolutions = () => {

  // const items = [
  //   {
  //     id: 1,
  //     image: "FourSight.png",
  //     title: "Predictive Maintenance: FourSight",
  //     link: "/solutions/applied-ai-solutions/foursight"
  //   },
  //   {
  //     id: 2,
  //     image: "PostAI_black_bg.png",
  //     title: "Generative AI: PostAI",
  //     link: "/solutions/applied-ai-solutions/postai",
  //   },
  //   {
  //     id: 3,
  //     image: "FourSight.png",
  //     title: "Anomaly Detection: Real Estate Outlier",
  //     link: "/solutions/applied-ai-solutions/real-estate-outlier"
  //   },
  //   {
  //     id: 4,
  //     image: "FourSight.png",
  //     title: "Sentiment Analysis: CourseVibe",
  //     link: "/solutions/applied-ai-solutions/coursevibe"
  //   },
  //   {
  //     id: 5,
  //     image: "FourSight.png",
  //     title: "Recommendation Systems: SDG Navigator",
  //     link: "/solutions/applied-ai-solutions/sdg-navigator"
  //   },
  //   {
  //     id: 6,
  //     image: "FourSight.png",
  //     title: "Natural Language Processing: TestGenius",
  //     link: "/solutions/applied-ai-solutions/testgenius"
  //   },
  //   {
  //     id: 7,
  //     image: "FourSight.png",
  //     title: "Knowledge Mining: HealthGuide+",
  //     link: "/solutions/applied-ai-solutions/healthguide-plus"
  //   },
  // ]

  return (
    <>
      <Element
        name="our-applied-ai-solutions"
        className="edu-about-area about-style-1 edu-section-gap service-wrapper-1 bg-white"
      >
        <div className="container our-applied-ai-solutions-container">
          <div className="row g-5">
            <div className="col-12">
              <AppliedAISolutionsSectionTitle
                title="Our Applied AI Solutions"
                classes="text-center"
              />
            </div>
            <div className="col-12 d-flex flex-column flex-md-row gap-5 p-md-5" id="FourSight">
              <div className="col-12 col-lg-4 d-flex align-items-center justify-content-center">
                <img src={`${process.env.PUBLIC_URL}/images/applied-ai-solutions/Foursight.jpg`} width="400px" alt="FourSight" />
              </div>
              <div className="col-12 col-lg-8 flex-shrink-1">
                <div className="content">
                  <h4>Predictive Maintenance: FourSight</h4>
                  <p>
                    FourSight offers Predictive Maintenance that leverages Advanced Analytics and our Machine Learning models to forecast equipment failures before they occur, minimizing downtime and optimizing maintenance schedules. This allows Manufacturing and CleanTech companies like you to adopt a proactive approach to maintenance rather than reactive.
                  </p>
                  <h5>Key Components</h5>
                  <ul>
                    <li>
                      <strong>Data Collection</strong>: Continuous monitoring of equipment performance data using sensors, IoT devices, and industrial control systems to capture real-time operational data and performance metrics.
                    </li>
                    <li>
                      <strong>Data Analytics</strong>: Analysis of historical equipment data, including patterns, trends, and anomalies, to identify early indicators of potential failures and develop predictive models.
                    </li>
                    <li>
                      <strong>Predictive Models</strong>: Development and deployment of machine learning models and algorithms that use historical data to predict future equipment behavior and anticipate maintenance needs.
                    </li>
                    <li>
                      <strong>Condition Monitoring</strong>: Real-time monitoring of equipment health and performance indicators to assess the current condition and identify any deviations from normal operating parameters.
                    </li>
                    <li>
                      <strong>Maintenance Planning</strong>: Utilization of predictive maintenance insights to optimize maintenance schedules, prioritize maintenance tasks, and allocate resources efficiently.
                    </li>
                    <li>
                      <strong>Integration with CMMS</strong>: Integration of predictive maintenance systems with computerized maintenance management systems (CMMS) to streamline work order generation, track maintenance activities, and record maintenance history.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 section-divider d-flex justify-content-center mt--60">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-05-02.png`} alt="illustration" />
            </div>
            <div className="col-12 d-flex flex-column flex-md-row gap-5 p-md-5" id="PostAI">
              <div className="col-12 col-lg-4 d-flex align-items-center justify-content-center">
                <img src={`${process.env.PUBLIC_URL}/images/applied-ai-solutions/postAI.jpg`} width="400px" alt="PostAI" />
              </div>
              <div className="col-12 col-lg-8 flex-shrink-1">
                <div className="content">
                  <h4>Generative AI: PostAI</h4>
                  <p>
                    Optimize your Digital Marketing strategies through our seamless integration of automated content creation and scheduling for Social Media platforms using PostAI, ensuring consistent and engaging posts tailored to your target audience.
                  </p>
                  <h5>Key Benefits</h5>
                  <ul>
                    <li>
                      <strong>Enhanced Efficiency</strong>: Automated content creation and scheduling streamline the marketing workflow, reducing the time and resources required for manual content development and posting. This efficiency allows marketers to focus on strategic planning and analysis, leading to more effective campaigns.
                    </li>
                    <li>
                      <strong>Consistent Brand Presence</strong>: By scheduling posts in advance and ensuring regular updates across social media platforms, businesses can maintain a consistent brand presence online. Consistency in posting frequency and content quality helps reinforce brand identity and fosters trust and engagement with the audience.
                    </li>
                    <li>
                      <strong>Audience Engagement</strong>: Tailored content generated through automation tools can resonate better with target audiences, leading to increased engagement rates. By delivering relevant and engaging content consistently, businesses can attract and retain followers, encourage interactions, and drive traffic to their digital channels.
                    </li>
                    <li>
                      <strong>Improved Performance Tracking</strong>: Integration with analytics tools enables marketers to track the performance of automated content campaigns in real-time. By analyzing metrics such as reach, engagement, and conversion rates, businesses can gain valuable insights into audience preferences and behavior, allowing for data-driven optimization of future marketing efforts.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 section-divider d-flex justify-content-center mt--60">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-05-02.png`} alt="illustration" />
            </div>
            <div className="col-12 d-flex flex-column flex-md-row gap-5 p-md-5" id="RealEstateOutlier">
              <div className="col-12 col-lg-4 d-flex align-items-center justify-content-center">
                <img src={`${process.env.PUBLIC_URL}/images/applied-ai-solutions/anomaly_detection.jpg`} width="400px" alt="AnomalyDetection" />
              </div>
              <div className="col-12 col-lg-8 flex-shrink-1">
                <div className="content">
                  <h4>Anomaly Detection: Real Estate Outlier</h4>
                  <p>
                    REO is a cutting-edge anomaly detection solution tailored for the real estate industry, specifically designed to identify unusual fluctuations or anomalies in property prices. Leveraging advanced Machine Learning algorithms and Statistical Analysis, REO empowers real estate professionals, investors, and market analysts to detect potential price anomalies that may indicate opportunities or risks in the market.
                  </p>
                  <h5>Key Features</h5>
                  <ul>
                    <li>
                      <strong>Comprehensive Data Analysisy</strong>: REO conducts comprehensive analysis of real estate market data, including property prices, location attributes, market trends, and historical transaction data.
                    </li>
                    <li>
                      <strong>Anomaly Detection Algorithms</strong>: Utilizes advanced anomaly detection algorithms to identify deviations from typical price patterns.
                    </li>
                    <li>
                      <strong>Customizable Thresholds</strong>: Allows users to set customizable anomaly detection thresholds based on property type, location, price range, and other relevant parameters.
                    </li>
                    <li>
                      <strong>Interactive Visualization</strong>: Provides interactive visualization tools to explore detected anomalies, visualize price trends, and analyze the impact of anomalies on market dynamics.
                    </li>
                    <li>
                      <strong>Historical Insights</strong>: Offers historical anomaly detection insights to understand past price fluctuations and their correlation with market events or economic indicators.
                    </li>
                    <li>
                      <strong>Market Segmentation</strong>: Segments the real estate market into different categories, such as residential, commercial, or industrial properties, to provide tailored anomaly detection models for specific market segments.
                    </li>
                    <li>
                      <strong>Risk Assessment</strong>: Assesses the potential risks associated with detected anomalies and provides recommendations for risk mitigation strategies, investment decisions, and pricing strategies.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 section-divider d-flex justify-content-center mt--60">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-05-02.png`} alt="illustration" />
            </div>
            <div className="col-12 d-flex flex-column flex-md-row gap-5 p-md-5" id="CourseVibe">
              <div className="col-12 col-lg-4 d-flex align-items-center justify-content-center">
                <img src={`${process.env.PUBLIC_URL}/images/applied-ai-solutions/courseVibe.jpg`} width="400px" alt="CourseVibe" />
              </div>
              <div className="col-12 col-lg-8 flex-shrink-1">
                <div className="content">
                  <h4>Sentiment Analysis: CourseVibe</h4>
                  <p>
                    Online courses including MOOCs have become increasingly popular, but gathering feedback and gauging user satisfaction can be challenging. Sentiment analysis can help analyze user comments and feedback to understand their sentiment towards the course content, instructor, platform usability, and overall learning experience. By extracting sentiment from user feedback, Course Creators can identify positive aspects that resonate with users and areas where improvements are needed.
                  </p>
                  <h5>Key Benefits</h5>
                  <ul>
                    <li>
                      <strong>User Engagement</strong>: Gain insights into user sentiment and satisfaction levels, allowing you to tailor course content, delivery methods, and learning materials to meet user needs and preferences.
                    </li>
                    <li>
                      <strong>Quality Improvement</strong>: Identify strengths and weaknesses in course content, instructor performance, and platform usability to drive continuous improvement efforts and enhance the overall learning experience.
                    </li>
                    <li>
                      <strong>Instructor Feedback</strong>: Provide instructors with valuable feedback from learners to help them understand their teaching effectiveness, address learner concerns, and adjust their teaching approach accordingly.
                    </li>
                    <li>
                      <strong>Course Personalization</strong>: Personalize course offerings and recommendations based on user sentiment and feedback, ensuring that learners receive relevant and engaging learning experiences.
                    </li>
                    <li>
                      <strong>Retention and Loyalty</strong>: Improve learner retention and loyalty by addressing user concerns, resolving issues promptly, and fostering a positive learning environment that encourages ongoing participation and engagement.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 section-divider d-flex justify-content-center mt--60">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-05-02.png`} alt="illustration" />
            </div>
            <div className="col-12 d-flex flex-column flex-md-row gap-5 p-md-5" id="SDGNavigator">
              <div className="col-12 col-lg-4 d-flex align-items-center justify-content-center">
                <img src={`${process.env.PUBLIC_URL}/images/applied-ai-solutions/sdg_navigator.jpg`} width="400px" alt="SDG Navigator" />
              </div>
              <div className="col-12 col-lg-8 flex-shrink-1">
                <div className="content">
                  <h4>Recommendation Systems: SDG Navigator</h4>
                  <p>
                    SDG Navigator is a sophisticated recommendation system designed to assist businesses in identifying and prioritizing Sustainable Development Goals (SDGs) that align with their business values, operations, and objectives. By analyzing the content of companies' websites and leveraging natural language processing (NLP) techniques, SDG Navigator offers tailored recommendations on SDGs that companies can feasibly integrate into their sustainability strategies.
                  </p>
                  <h5>Key Features</h5>
                  <ul>
                    <li>
                      <strong>Website Content Analysis</strong>: SDG Navigator utilizes advanced NLP algorithms to analyze the textual content of businesses' websites, including mission statements, corporate social responsibility (CSR) reports, and sustainability initiatives.
                    </li>
                    <li>
                      <strong>SDG Relevance Assessment</strong>: Based on the analysis of website content, SDG Navigator assesses the relevance of each of the 17 SDGs to the company's core values, industry sector, and business activities.
                    </li>
                    <li>
                      <strong>Impact Assessment</strong>: Estimates the potential social, environmental, and economic impact of aligning with recommended SDGs, providing insights into the benefits and opportunities associated with each goal.
                    </li>
                    <li>
                      <strong>Actionable Insights</strong>: Provides actionable insights and guidance on how companies can incorporate recommended SDGs into their sustainability frameworks, including suggested initiatives, partnerships, and metrics for monitoring progress.
                    </li>
                    <li>
                      <strong>Benchmarking and Comparison</strong>: Enables companies to benchmark their SDG alignment against industry peers and competitors, facilitating performance comparisons and identifying areas for improvement.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 section-divider d-flex justify-content-center mt--60">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-05-02.png`} alt="illustration" />
            </div>
            <div className="col-12 d-flex flex-column flex-md-row gap-5 p-md-5" id="TestGenius">
              <div className="col-12 col-lg-4 d-flex align-items-center justify-content-center">
                <img src={`${process.env.PUBLIC_URL}/images/applied-ai-solutions/testGenius.jpg`} width="400px" alt="TestGenius" />
              </div>
              <div className="col-12 col-lg-8 flex-shrink-1">
                <div className="content">
                  <h4>Natural Language Processing: TestGenius</h4>
                  <p>
                    TestGenius is an innovative solution that leverages advanced natural language processing (NLP) techniques to automate the generation of test scripts directly from natural language requirements. By interpreting and analyzing textual descriptions of software requirements, TestGenius transforms them into executable test scenarios, streamlining the testing process and enhancing efficiency.
                  </p>
                  <h5>Key Features</h5>
                  <ul>
                    <li>
                      <strong>Natural Language Interpretation</strong>: TestGenius employs state-of-the-art NLP algorithms to understand and extract actionable insights from textual software requirements.
                    </li>
                    <li>
                      <strong>Automated Test Script Generation</strong>: Using the interpreted natural language requirements, TestGenius generates comprehensive test scripts automatically, eliminating the need for manual script writing.
                    </li>
                    <li>
                      <strong>Customizable Test Scenarios</strong>: Users can customize and refine generated test scripts to accommodate specific testing scenarios and requirements.
                    </li>
                    <li>
                      <strong>Integration Capabilities</strong>: TestGenius seamlessly integrates with existing software development and testing workflows, supporting popular testing frameworks and tools.
                    </li>
                    <li>
                      <strong>Enhanced Testing Efficiency</strong>: By automating the test script generation process, TestGenius accelerates testing cycles, reduces manual effort, and improves overall testing efficiency.
                    </li>
                    <li>
                      <strong>Scalability and Adaptability</strong>: With its scalable architecture and adaptable NLP models, TestGenius can handle projects of varying sizes and complexities across different domains.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 section-divider d-flex justify-content-center mt--60">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-05-02.png`} alt="illustration" />
            </div>
            <div className="col-12 d-flex flex-column flex-md-row gap-5 p-md-5" id="HealthGuidePlus">
              <div className="col-12 col-lg-4 d-flex align-items-center justify-content-center">
                <img src={`${process.env.PUBLIC_URL}/images/applied-ai-solutions/health_guide_plus.jpg`} width="400px" alt="Health Guide Plus" />
              </div>
              <div className="col-12 col-lg-8 flex-shrink-1">
                <div className="content">
                  <h4>Knowledge Mining: HealthGuide+</h4>
                  <p>
                    HealthGuide+ is an innovative knowledge mining platform designed to provide personalized health recommendations for individuals visiting or relocating to a new city. Leveraging advanced Data Analytics and Natural Language Processing (NLP) techniques, HealthGuide+ aggregates and analyzes relevant health-related information from diverse data sources, including government health agencies, local healthcare providers.
                  </p>
                  <h5>Key Features</h5>
                  <ul>
                    <li>
                      <strong>Comprehensive City Health Profiles</strong>: HealthGuide+ offers comprehensive profiles of cities, providing detailed insights into local health conditions, environmental factors, disease prevalence rates, and healthcare infrastructure. Users can access up-to-date information on air quality, water safety, infectious disease outbreaks, vaccination requirements, and emergency medical services available in their chosen city.
                    </li>
                    <li>
                      <strong>Personalized Health Recommendations</strong>: By analyzing user preferences, medical history, and demographic data, HealthGuide+ generates personalized health advisories tailored to individual needs and preferences. Users can specify their age, gender, pre-existing medical conditions, and lifestyle preferences to receive targeted recommendations on preventive healthcare measures, dietary guidelines, exercise routines, and wellness activities.
                    </li>
                    <li>
                      <strong>Real-Time Health Alerts</strong>: HealthGuide+ delivers real-time health alerts and notifications to users, informing them of potential health risks, disease outbreaks, or environmental hazards in their current location. Users receive proactive recommendations on protective measures, such as wearing masks during high pollution days, avoiding crowded areas during flu season, or seeking medical attention in case of emergency situations.
                    </li>
                    <li>
                      <strong>Community Health Forums</strong>: HealthGuide+ fosters community engagement and knowledge sharing through online health forums and social media integration. Users can connect with local residents, healthcare professionals, and fellow newcomers to exchange health tips, share experiences, and seek advice on navigating the local healthcare system.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default OurAppliedAISolutions;