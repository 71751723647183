import React from 'react';

const RiskTrendInfo = ({hideRiskTrendPopup}) => {

  return (
    <div className="risk-classification-container hidePopup" onClick={hideRiskTrendPopup} id="RiskTrendPopup">
      <div className="risk-classification-content">
        <p>This graph show the change in Risk level of entities for the current year.</p>
      </div>
    </div>
  )
}

export default RiskTrendInfo;