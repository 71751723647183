import React, { useEffect, useState } from 'react';
import { Element } from 'react-scroll';
import ReturnToWorkPredictionSectionTitle from './ReturnToWorkPredictionSectionTitle';
import ReturnToWorkPredictionForm from '../form/return-to-work-prediction-form/ReturnToWorkPredictionForm';
import ReturnToWorkPredictionDownloadButton from './ReturnToWorkPredictionDownloadButton';
import Select from 'react-select';
import Pie from '../../utils/pie';
// import Tooltip from '../../common/Tooltip';
import { Tooltip } from "react-tooltip";

const clients = [
  {
    clientId: 1,
    clientUUID: "100001",
    clientName: "John",
    clientAge: "31",
    clientNationality: "Japanese",
    clientYearsInCanada: 1,
    // clientStream: "Stream A",
  },
  {
    clientId: 2,
    clientUUID: "100002",
    clientName: "Frank",
    clientAge: "32",
    clientNationality: "Brazillian",
    clientYearsInCanada: 2,
    // clientStream: "Stream B",
  },
  {
    cliendId: 3,
    clientUUID: "100003",
    clientName: "Tony",
    clientAge: "33",
    clientNationality: "Australian",
    clientYearsInCanada: 3,
    // clientStream: "Stream C",
  },
  {
    cliendId: 4,
    clientUUID: "100004",
    clientName: "Stuart",
    clientAge: "34",
    clientNationality: "Nigerian",
    clientYearsInCanada: 4,
    // clientStream: "Stream A",
  },
  {
    clientId: 5,
    clientUUID: "100005",
    clientName: "Frank",
    clientAge: "35",
    clientNationality: "Iranian",
    clientYearsInCanada: 1,
    // clientStream: "Stream A",
  },
]

const clients2 = [
  {
    Client_ID: 100001,
    BirthDate: "1998-01-23",
    Age: 26,
    City: "Toronto",
    Province: "ON",
    Preferred_Language: "French",
    Birth_Country: "Canada",
    Year_of_Arrival_to_Canada: "Not applicable",
    Years_in_Canada: 26,
    Status_In_Canada: "Canadian citizen",
    Immigration_Category: "Not applicable",
    Marital_Status: "Married",
    Number_of_Dependents: 4,
    Gender_Identity: "Man",
    LGBTQ: "no",
    Francophone: "yes",
    Indigenous: "No",
    Race: "South Asian",
    Education_Level: "Grade 9",
    Education_Completion_Year: "2007",
    Education_Location: "Outside of Canada",
    Language_Skills_Help: "yes",
    Writing_Skills: "bad",
    Verbal_Skills: "bad",
    Numerical_Skills: "good",
    Computer_Skills: "bad",
    Support_Connections: "yes",
    Internet_Access: "yes",
    Transportation_Access: "yes",
    Caregiver: "no",
    Housing_Situation: "Living with family/friend",
    Housing_help: "no",
    Food_Insecurity: "no",
    Immediate_Assistance_Need: "no",
    Safety_Concerns: "no",
    Mental_Health_Support: "no",
    Substance_Use_Support: "no",
    Source_of_Income: "Ontario Works (OW) applied or receiving",
    Visual_or_hearing_Disability: "no",
    Physical_disability: "no",
    Learning_Disability: "no",
    Intellectual_Disability: "no",
    Mental_Health_Condition: "no",
    Long_Term_Health_Condition: "no",
    Cronic_pain: "no",
    Crime_Conviction: "no",
    Current_Student: "no",
    Currently_Working: "yes",
    months_since_last_job: 0,
    Work_duration: 12,
    Hours_worked_last_job: 40,
    Hourly_wage_last_job: 26,
    Canadian_work_experience: "no",
    Ability_to_work: "very bad",
    Hours_available_to_work: 40,
    Desired_Hourly_Wage: 30,
    clientName: "Tony Stark",
  },
  {
    Client_ID: 100002,
    BirthDate: "1960-09-16",
    Age: 63,
    City: "Halifax",
    Province: "NS",
    Preferred_Language: "French",
    Birth_Country: "Canada",
    Year_of_Arrival_to_Canada: "Not applicable",
    Years_in_Canada: 63,
    Status_In_Canada: "Canadian citizen",
    Immigration_Category: "Not applicable",
    Marital_Status: "Common law",
    Number_of_Dependents: 1,
    Gender_Identity: "Another gender identity",
    LGBTQ: "yes",
    Francophone: "yes",
    Indigenous: "No",
    Race: "Middle Eastern",
    Education_Level: "apprenticeship",
    Education_Completion_Year: "1976",
    Education_Location: "Canada",
    Language_Skills_Help: "yes",
    Writing_Skills: "bad",
    Verbal_Skills: "good",
    Numerical_Skills: "good",
    Computer_Skills: "bad",
    Support_Connections: "no",
    Internet_Access: "no",
    Transportation_Access: "no",
    Caregiver: "no",
    Housing_Situation: "Homeowner",
    Housing_help: "no",
    Food_Insecurity: "yes",
    Immediate_Assistance_Need: "no",
    Safety_Concerns: "no",
    Mental_Health_Support: "no",
    Substance_Use_Support: "no",
    Source_of_Income: "Dependent of someone receiving OW or ODSP",
    Visual_or_hearing_Disability: "yes",
    Physical_disability: "no",
    Learning_Disability: "no",
    Intellectual_Disability: "no",
    Mental_Health_Condition: "no",
    Long_Term_Health_Condition: "no",
    Cronic_pain: "no",
    Crime_Conviction: "yes",
    Current_Student: "no",
    Currently_Working: "yes",
    months_since_last_job: 0,
    Work_duration: 60,
    Hours_worked_last_job: 27,
    Hourly_wage_last_job: 30,
    Canadian_work_experience: "yes",
    Ability_to_work: "ok",
    Hours_available_to_work: 40,
    Desired_Hourly_Wage: 30,
    clientName: "Frank Hays",
  },
  {
    Client_ID: 100003,
    BirthDate: "1977-03-11",
    Age: 46,
    City: "St.John",
    Province: "NL",
    Preferred_Language: "French",
    Birth_Country: "Canada",
    Year_of_Arrival_to_Canada: "Not applicable",
    Years_in_Canada: 46,
    Status_In_Canada: "Canadian citizen",
    Immigration_Category: "Not applicable",
    Marital_Status: "Separated",
    Number_of_Dependents: 0,
    Gender_Identity: "Transgender woman",
    LGBTQ: "yes",
    Francophone: "yes",
    Indigenous: "Metis",
    Race: "Indigenous",
    Education_Level: "Journeyperson",
    Education_Completion_Year: "1992",
    Education_Location: "Canada",
    Language_Skills_Help: "no",
    Writing_Skills: "bad",
    Verbal_Skills: "good",
    Numerical_Skills: "bad",
    Computer_Skills: "bad",
    Support_Connections: "no",
    Internet_Access: "no",
    Transportation_Access: "yes",
    Caregiver: "no",
    Housing_Situation: "Renting-private",
    Housing_help: "no",
    Food_Insecurity: "no",
    Immediate_Assistance_Need: "no",
    Safety_Concerns: "no",
    Mental_Health_Support: "yes",
    Substance_Use_Support: "yes",
    Source_of_Income: "Employment",
    Visual_or_hearing_Disability: "no",
    Physical_disability: "no",
    Learning_Disability: "no",
    Intellectual_Disability: "no",
    Mental_Health_Condition: "no",
    Long_Term_Health_Condition: "no",
    Cronic_pain: "yes",
    Crime_Conviction: "no",
    Current_Student: "no",
    Currently_Working: "no",
    months_since_last_job: 3,
    Work_duration: 41,
    Hours_worked_last_job: 38,
    Hourly_wage_last_job: 26,
    Canadian_work_experience: "no",
    Ability_to_work: "very bad",
    Hours_available_to_work: 4,
    Desired_Hourly_Wage: 26,
    clientName: "Anna Hathway",
  },
  {
    Client_ID: 100004,
    BirthDate: "1969-10-17",
    Age: 54,
    City: "Quebec City",
    Province: "QC",
    Preferred_Language: "English",
    Birth_Country: "Canada",
    Year_of_Arrival_to_Canada: "Not applicable",
    Years_in_Canada: 54,
    Status_In_Canada: "Canadian citizen",
    Immigration_Category: "Not applicable",
    Marital_Status: "Separated",
    Number_of_Dependents: 3,
    Gender_Identity: "Woman",
    LGBTQ: "no",
    Francophone: "yes",
    Indigenous: "First Nations",
    Race: "Indigenous",
    Education_Level: "Grade 10",
    Education_Completion_Year: "1979",
    Education_Location: "Canada",
    Language_Skills_Help: "no",
    Writing_Skills: "bad",
    Verbal_Skills: "good",
    Numerical_Skills: "bad",
    Computer_Skills: "bad",
    Support_Connections: "yes",
    Internet_Access: "yes",
    Transportation_Access: "no",
    Caregiver: "no",
    Housing_Situation: "Renting-private",
    Housing_help: "no",
    Food_Insecurity: "yes",
    Immediate_Assistance_Need: "yes",
    Safety_Concerns: "no",
    Mental_Health_Support: "no",
    Substance_Use_Support: "no",
    Source_of_Income: "Other (specify)",
    Visual_or_hearing_Disability: "no",
    Physical_disability: "no",
    Learning_Disability: "no",
    Intellectual_Disability: "yes",
    Mental_Health_Condition: "yes",
    Long_Term_Health_Condition: "yes",
    Cronic_pain: "no",
    Crime_Conviction: "no",
    Current_Student: "no",
    Currently_Working: "yes",
    months_since_last_job: 0,
    Work_duration: 3,
    Hours_worked_last_job: 33,
    Hourly_wage_last_job: 44,
    Canadian_work_experience: "yes",
    Ability_to_work: "very good",
    Hours_available_to_work: 20,
    Desired_Hourly_Wage: 50,
    clientName: "Kacy Murdock",
  },
  {
    Client_ID: 100005,
    BirthDate: "1972-05-01",
    Age: 51,
    City: "Calgary",
    Province: "AB",
    Preferred_Language: "English",
    Birth_Country: "Canada",
    Year_of_Arrival_to_Canada: "Not applicable",
    Years_in_Canada: 51,
    Status_In_Canada: "Canadian citizen",
    Immigration_Category: "Not applicable",
    Marital_Status: "Married",
    Number_of_Dependents: 2,
    Gender_Identity: "Prefer not to answer",
    LGBTQ: "no",
    Francophone: "Prefer not to answer",
    Indigenous: "First Nations",
    Race: "Indigenous",
    Education_Level: "Grade 12 or equivalent (GED)",
    Education_Completion_Year: "1984",
    Education_Location: "Canada",
    Language_Skills_Help: "no",
    Writing_Skills: "good",
    Verbal_Skills: "bad",
    Numerical_Skills: "bad",
    Computer_Skills: "good",
    Support_Connections: "no",
    Internet_Access: "yes",
    Transportation_Access: "no",
    Caregiver: "yes",
    Housing_Situation: "Emergency hoste",
    Housing_help: "yes",
    Food_Insecurity: "yes",
    Immediate_Assistance_Need: "yes",
    Safety_Concerns: "yes",
    Mental_Health_Support: "no",
    Substance_Use_Support: "yes",
    Source_of_Income: "Other (specify)",
    Visual_or_hearing_Disability: "no",
    Physical_disability: "no",
    Learning_Disability: "no",
    Intellectual_Disability: "no",
    Mental_Health_Condition: "no",
    Long_Term_Health_Condition: "yes",
    Cronic_pain: "no",
    Crime_Conviction: "no",
    Current_Student: "no",
    Currently_Working: "yes",
    months_since_last_job: 0,
    Work_duration: 8,
    Hours_worked_last_job: 60,
    Hourly_wage_last_job: 35,
    Canadian_work_experience: "yes",
    Ability_to_work: "very good",
    Hours_available_to_work: 40,
    Desired_Hourly_Wage: 35,
    clientName: "Tony Stark",
  },
  {
    Client_ID: 100006,
    BirthDate: "1969-12-15",
    Age: 54,
    City: "Montreal",
    Province: "QC",
    Preferred_Language: "English",
    Birth_Country: "Canada",
    Year_of_Arrival_to_Canada: "Not applicable",
    Years_in_Canada: 54,
    Status_In_Canada: "Canadian citizen",
    Immigration_Category: "Not applicable",
    Marital_Status: "Single",
    Number_of_Dependents: 1,
    Gender_Identity: "Man",
    LGBTQ: "yes",
    Francophone: "yes",
    Indigenous: "Inuit (Inuk)",
    Race: "Indigenous",
    Education_Level: "Grade 11",
    Education_Completion_Year: "1980",
    Education_Location: "Canada",
    Language_Skills_Help: "no",
    Writing_Skills: "bad",
    Verbal_Skills: "good",
    Numerical_Skills: "good",
    Computer_Skills: "bad",
    Support_Connections: "no",
    Internet_Access: "yes",
    Transportation_Access: "yes",
    Caregiver: "no",
    Housing_Situation: "Renting-private",
    Housing_help: "no",
    Food_Insecurity: "yes",
    Immediate_Assistance_Need: "no",
    Safety_Concerns: "no",
    Mental_Health_Support: "no",
    Substance_Use_Support: "no",
    Source_of_Income: "Ontario Disability Support Program (ODSP) applied or receiving",
    Visual_or_hearing_Disability: "yes",
    Physical_disability: "no",
    Learning_Disability: "no",
    Intellectual_Disability: "no",
    Mental_Health_Condition: "no",
    Long_Term_Health_Condition: "yes",
    Cronic_pain: "no",
    Crime_Conviction: "no",
    Current_Student: "no",
    Currently_Working: "no",
    months_since_last_job: 4,
    Work_duration: 12,
    Hours_worked_last_job: 12,
    Hourly_wage_last_job: 32,
    Canadian_work_experience: "no",
    Ability_to_work: "very good",
    Hours_available_to_work: 40,
    Desired_Hourly_Wage: 35,
    clientName: "Lisa Merchant",
  },
  {
    Client_ID: 100007,
    BirthDate: "2002-11-14",
    Age: 21,
    City: "St.John",
    Province: "NL",
    Preferred_Language: "English",
    Birth_Country: "Canada",
    Year_of_Arrival_to_Canada: "Not applicable",
    Years_in_Canada: 21,
    Status_In_Canada: "Canadian citizen",
    Immigration_Category: "Not applicable",
    Marital_Status: "Single",
    Number_of_Dependents: 0,
    Gender_Identity: "Man",
    LGBTQ: "no",
    Francophone: "no",
    Indigenous: "Inuit (Inuk)",
    Race: "Indigenous",
    Education_Level: "apprenticeship",
    Education_Completion_Year: "2018",
    Education_Location: "Outside of Canada",
    Language_Skills_Help: "no",
    Writing_Skills: "bad",
    Verbal_Skills: "bad",
    Numerical_Skills: "good",
    Computer_Skills: "good",
    Support_Connections: "no",
    Internet_Access: "no",
    Transportation_Access: "no",
    Caregiver: "yes",
    Housing_Situation: "Homeowner",
    Housing_help: "no",
    Food_Insecurity: "yes",
    Immediate_Assistance_Need: "no",
    Safety_Concerns: "no",
    Mental_Health_Support: "no",
    Substance_Use_Support: "no",
    Source_of_Income: "Self-Employment",
    Visual_or_hearing_Disability: "no",
    Physical_disability: "yes",
    Learning_Disability: "no",
    Intellectual_Disability: "no",
    Mental_Health_Condition: "no",
    Long_Term_Health_Condition: "yes",
    Cronic_pain: "no",
    Crime_Conviction: "no",
    Current_Student: "yes",
    Currently_Working: "yes",
    months_since_last_job: 0,
    Work_duration: 16,
    Hours_worked_last_job: 40,
    Hourly_wage_last_job: 33,
    Canadian_work_experience: "no",
    Ability_to_work: "good",
    Hours_available_to_work: 30,
    Desired_Hourly_Wage: 35,
    clientName: "Richard Parker",
  },
  {
    Client_ID: 100008,
    BirthDate: "2000-02-02",
    Age: 24,
    City: "St.John",
    Province: "NL",
    Preferred_Language: "French",
    Birth_Country: "Canada",
    Year_of_Arrival_to_Canada: "Not applicable",
    Years_in_Canada: 24,
    Status_In_Canada: "Canadian citizen",
    Immigration_Category: "Not applicable",
    Marital_Status: "Married",
    Number_of_Dependents: 4,
    Gender_Identity: "Man",
    LGBTQ: "no",
    Francophone: "yes",
    Indigenous: "No",
    Race: "Latino",
    Education_Level: "Post graduate",
    Education_Completion_Year: "2022",
    Education_Location: "Canada",
    Language_Skills_Help: "no",
    Writing_Skills: "good",
    Verbal_Skills: "good",
    Numerical_Skills: "good",
    Computer_Skills: "good",
    Support_Connections: "yes",
    Internet_Access: "no",
    Transportation_Access: "yes",
    Caregiver: "no",
    Housing_Situation: "Renting-subsidized",
    Housing_help: "no",
    Food_Insecurity: "no",
    Immediate_Assistance_Need: "yes",
    Safety_Concerns: "yes",
    Mental_Health_Support: "yes",
    Substance_Use_Support: "no",
    Source_of_Income: "Employment Insurance (EI)",
    Visual_or_hearing_Disability: "no",
    Physical_disability: "no",
    Learning_Disability: "no",
    Intellectual_Disability: "no",
    Mental_Health_Condition: "no",
    Long_Term_Health_Condition: "no",
    Cronic_pain: "no",
    Crime_Conviction: "no",
    Current_Student: "no",
    Currently_Working: "no",
    months_since_last_job: 18,
    Work_duration: 94,
    Hours_worked_last_job: 40,
    Hourly_wage_last_job: 38,
    Canadian_work_experience: "no",
    Ability_to_work: "good",
    Hours_available_to_work: 20,
    Desired_Hourly_Wage: 40,
    clientName: "John Ivans",
  },
  {
    Client_ID: 100009,
    BirthDate: "1978-10-30",
    Age: 45,
    City: "Saskatoon",
    Province: "SK",
    Preferred_Language: "English",
    Birth_Country: "Canada",
    Year_of_Arrival_to_Canada: "Not applicable",
    Years_in_Canada: 45,
    Status_In_Canada: "Canadian citizen",
    Immigration_Category: "Not applicable",
    Marital_Status: "Married",
    Number_of_Dependents: 5,
    Gender_Identity: "Transgender man",
    LGBTQ: "yes",
    Francophone: "no",
    Indigenous: "Inuit (Inuk)",
    Race: "Indigenous",
    Education_Level: "Post graduate",
    Education_Completion_Year: "1996",
    Education_Location: "Canada",
    Language_Skills_Help: "no",
    Writing_Skills: "bad",
    Verbal_Skills: "good",
    Numerical_Skills: "good",
    Computer_Skills: "good",
    Support_Connections: "yes",
    Internet_Access: "yes",
    Transportation_Access: "yes",
    Caregiver: "no",
    Housing_Situation: "Emergency hoste",
    Housing_help: "yes",
    Food_Insecurity: "yes",
    Immediate_Assistance_Need: "yes",
    Safety_Concerns: "no",
    Mental_Health_Support: "yes",
    Substance_Use_Support: "yes",
    Source_of_Income: "Ontario Works (OW) applied or receiving",
    Visual_or_hearing_Disability: "no",
    Physical_disability: "no",
    Learning_Disability: "no",
    Intellectual_Disability: "no",
    Mental_Health_Condition: "yes",
    Long_Term_Health_Condition: "yes",
    Cronic_pain: "no",
    Crime_Conviction: "no",
    Current_Student: "no",
    Currently_Working: "yes",
    months_since_last_job: 0,
    Work_duration: 2,
    Hours_worked_last_job: 36,
    Hourly_wage_last_job: 18,
    Canadian_work_experience: "yes",
    Ability_to_work: "ok",
    Hours_available_to_work: 30,
    Desired_Hourly_Wage: 20,
    clientName: "Richard Johnson",
  },
  {
    Client_ID: 100010,
    BirthDate: "1972-04-27",
    Age: 51,
    City: "Saskatoon",
    Province: "SK",
    Preferred_Language: "French",
    Birth_Country: "Canada",
    Year_of_Arrival_to_Canada: "Not applicable",
    Years_in_Canada: 51,
    Status_In_Canada: "Canadian citizen",
    Immigration_Category: "Not applicable",
    Marital_Status: "Prefer not to answer",
    Number_of_Dependents: 3,
    Gender_Identity: "Woman",
    LGBTQ: "no",
    Francophone: "yes",
    Indigenous: "Metis",
    Race: "Indigenous",
    Education_Level: "Grade 10",
    Education_Completion_Year: "1982",
    Education_Location: "Outside of Canada",
    Language_Skills_Help: "no",
    Writing_Skills: "bad",
    Verbal_Skills: "good",
    Numerical_Skills: "bad",
    Computer_Skills: "bad",
    Support_Connections: "yes",
    Internet_Access: "yes",
    Transportation_Access: "yes",
    Caregiver: "no",
    Housing_Situation: "BoardingOrlodging",
    Housing_help: "no",
    Food_Insecurity: "no",
    Immediate_Assistance_Need: "no",
    Safety_Concerns: "yes",
    Mental_Health_Support: "yes",
    Substance_Use_Support: "no",
    Source_of_Income: "Self-Employment",
    Visual_or_hearing_Disability: "yes",
    Physical_disability: "no",
    Learning_Disability: "no",
    Intellectual_Disability: "no",
    Mental_Health_Condition: "yes",
    Long_Term_Health_Condition: "no",
    Cronic_pain: "no",
    Crime_Conviction: "yes",
    Current_Student: "no",
    Currently_Working: "no",
    months_since_last_job: 6,
    Work_duration: 17,
    Hours_worked_last_job: 35,
    Hourly_wage_last_job: 41,
    Canadian_work_experience: "yes",
    Ability_to_work: "good",
    Hours_available_to_work: 40,
    Desired_Hourly_Wage: 45,
    clientName: "Jackie Chan",
  },
]

const items = {
  "100001": {
    "stream": "Stream B: (Employability and Employment)",
    "employability_percentage": 60.0,
    "plan_items": [
      {
        "item": "Employability skills training",
        "weight": 6.95,
        "reason": "Client has poor writing, verbal and computer skills.",
        "recommended": true
      },
      {
        "item": "Job seeker - Short-term skills training",
        "weight": 6.95,
        "reason": "Client needs to improve their skills to increase their employability.",
        "recommended": true
      },
      {
        "item": "Career exploration, planning and management",
        "weight": 5.21,
        "reason": "Client needs to improve their employability skills and career planning.",
        "recommended": true
      },
      {
        "item": "Job coaching",
        "weight": 5.21,
        "reason": "Client is currently working but has rated their ability to work as very bad.",
        "recommended": true
      },
      {
        "item": "Referral to Literacy and Basic Skills",
        "weight": 5.21,
        "reason": "Client has poor writing and verbal skills and may benefit from literacy and basic skills training.",
        "recommended": true
      },
      {
        "item": "Basic Needs - Transportation",
        "weight": 3.47,
        "reason": "Client has access to transportation but may need support to maintain it.",
        "recommended": true
      },
      {
        "item": "Resource and Information Services - Digital Services",
        "weight": 3.47,
        "reason": "Client has poor computer skills and may benefit from digital services.",
        "recommended": true
      },
      {
        "item": "Retention support planning",
        "weight": 3.47,
        "reason": "Client is currently working but may need support to retain their job.",
        "recommended": true
      },
      {
        "item": "Additional services to find another job",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Crisis resolution",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Financial support",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Food security",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Housing",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Client counselling",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Cultural transition",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Dependent care",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Justice and legal support",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Newcomer services",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employer - Job placements with financial supports",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employer - Job trials with financial supports",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employer coaching",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employment services for newcomers",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Health Supports - Mental health and addictions",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Health Supports - Primary care and ongoing medical concerns",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job matching and development",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job retention crisis response",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job search",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Academic credential or professional accreditation assessment",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Accommodation needs - job-specific communication skills training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Accommodation needs - on-the-job supports",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Certification charges",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Emergency/infrequent child care",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Employment-related special equipment and supplies",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Employment-related transportation",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Specialized hardware",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Translation of international academic documents",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Work clothing and/or grooming",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Mentoring",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Provision of SSM In-Service Self Employment Services",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Academic Upgrading (College Program)",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Apprenticeship Development Benefit",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Better Jobs Ontario Training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to English as a Second Language Training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to External Self-employment/Entrepreneurship Services",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Government Services - Federal",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Government Services - Municipal",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Micro-credentials",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Occupational Skills training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Other Ministry Delivered or Funded Programs",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Post Secondary Education",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Secondary Education",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Resource and Information Services - General",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Supporting changes in work activities and supervision",
        "weight": 0.5,
        "recommended": false
      }
    ]
  },
  "100002": {
  "stream": "Stream B: (Employability and Employment)",
  "employability_percentage": 60.0,
  "plan_items": [
    {
      "item": "Basic Needs - Food security",
      "weight": 6.06,
      "reason": "The client has indicated food insecurity.",
      "recommended": true
    },
    {
      "item": "Employability skills training",
      "weight": 4.84,
      "reason": "The client has indicated that they have poor writing and computer skills.",
      "recommended": true
  },
    {
      "item": "Job seeker - Short-term skills training",
      "weight": 4.84,
      "reason": "The client has indicated that they have poor writing and computer skills.",
      "recommended": true
    },
    {
      "item": "Community Supports - Cultural transition",
      "weight": 4.04,
      "reason": "The client identifies as LGBTQ and may need support in navigating cultural norms in the workplace.",
      "recommended": true
    },
    {
      "item": "Job seeker - Employment-related transportation",
      "weight": 4.04,
      "reason": "The client has indicated that they do not have access to transportation.",
      "recommended": true
    },
    {
      "item": "Referral to Literacy and Basic Skills",
      "weight": 4.04,
      "reason": "The client has indicated that they have poor writing skills.",
      "recommended": true
    },
    {
      "item": "Resource and Information Services - Digital Services",
      "weight": 4.04,
      "reason": "The client has indicated that they have poor computer skills.",
      "recommended": true
    },
    {
      "item": "Job coaching",
      "weight": 4.04,
      "reason": "The client has a criminal record and may need additional support in finding employment.",
      "recommended": true
    },
    {
      "item": "Job seeker - Accommodation needs - on-the-job supports",
      "weight": 4.04,
      "reason": "The client has a visual or hearing disability and may need accommodations at work.",
      "recommended": true
    },
    {
      "item": "Additional services to find another job",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Basic Needs - Crisis resolution",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Basic Needs - Financial support",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Basic Needs - Housing",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Basic Needs - Transportation",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Career exploration, planning and management",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Client counselling",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Community Supports - Dependent care",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Community Supports - Justice and legal support",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Community Supports - Newcomer services",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Employer - Job placements with financial supports",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Employer - Job trials with financial supports",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Employer coaching",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Employment services for newcomers",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Health Supports - Mental health and addictions",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Health Supports - Primary care and ongoing medical concerns",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Job matching and development",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Job retention crisis response",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Job search",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Job seeker - Academic credential or professional accreditation assessment",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Job seeker - Accommodation needs - job-specific communication skills training",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Job seeker - Certification charges",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Job seeker - Emergency/infrequent child care",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Job seeker - Employment-related special equipment and supplies",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Job seeker - Specialized hardware",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Job seeker - Translation of international academic documents",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Job seeker - Work clothing and/or grooming",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Mentoring",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Provision of SSM In-Service Self Employment Services",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Referral to Academic Upgrading (College Program)",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Referral to Apprenticeship Development Benefit",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Referral to Better Jobs Ontario Training",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Referral to English as a Second Language Training",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Referral to External Self-employment/Entrepreneurship Services",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Referral to Government Services - Federal",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Referral to Government Services - Municipal",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Referral to Micro-credentials",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Referral to Occupational Skills training",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Referral to Other Ministry Delivered or Funded Programs",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Referral to Post Secondary Education",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Referral to Secondary Education",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Resource and Information Services - General",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Retention support planning",
      "weight": 0.5,
      "recommended": false
    },
    {
      "item": "Supporting changes in work activities and supervision",
      "weight": 0.5,
      "recommended": false
    }
  ]
  },
  "100003": {
    "stream": "Stream B: (Employability and Employment)",
    "employability_percentage": 50,
    "plan_items": [
      {
        "item": "Basic Needs - Food security",
        "weight": 6.06,
        "reason": "The client has indicated food insecurity.",
        "recommended": true
      },
      {
        "item": "Employability skills training",
        "weight": 4.84,
        "reason": "The client has indicated that they have poor writing and computer skills.",
        "recommended": true
    },
      {
        "item": "Job seeker - Short-term skills training",
        "weight": 4.84,
        "reason": "The client has indicated that they have poor writing and computer skills.",
        "recommended": true
      },
      {
        "item": "Community Supports - Cultural transition",
        "weight": 4.04,
        "reason": "The client identifies as LGBTQ and may need support in navigating cultural norms in the workplace.",
        "recommended": true
      },
      {
        "item": "Job seeker - Employment-related transportation",
        "weight": 4.04,
        "reason": "The client has indicated that they do not have access to transportation.",
        "recommended": true
      },
      {
        "item": "Referral to Literacy and Basic Skills",
        "weight": 4.04,
        "reason": "The client has indicated that they have poor writing skills.",
        "recommended": true
      },
      {
        "item": "Resource and Information Services - Digital Services",
        "weight": 4.04,
        "reason": "The client has indicated that they have poor computer skills.",
        "recommended": true
      },
      {
        "item": "Job coaching",
        "weight": 4.04,
        "reason": "The client has a criminal record and may need additional support in finding employment.",
        "recommended": true
      },
      {
        "item": "Job seeker - Accommodation needs - on-the-job supports",
        "weight": 4.04,
        "reason": "The client has a visual or hearing disability and may need accommodations at work.",
        "recommended": true
      },
      {
        "item": "Additional services to find another job",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Crisis resolution",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Financial support",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Housing",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Transportation",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Career exploration, planning and management",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Client counselling",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Dependent care",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Justice and legal support",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Newcomer services",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employer - Job placements with financial supports",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employer - Job trials with financial supports",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employer coaching",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employment services for newcomers",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Health Supports - Mental health and addictions",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Health Supports - Primary care and ongoing medical concerns",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job matching and development",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job retention crisis response",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job search",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Academic credential or professional accreditation assessment",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Accommodation needs - job-specific communication skills training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Certification charges",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Emergency/infrequent child care",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Employment-related special equipment and supplies",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Specialized hardware",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Translation of international academic documents",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Work clothing and/or grooming",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Mentoring",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Provision of SSM In-Service Self Employment Services",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Academic Upgrading (College Program)",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Apprenticeship Development Benefit",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Better Jobs Ontario Training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to English as a Second Language Training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to External Self-employment/Entrepreneurship Services",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Government Services - Federal",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Government Services - Municipal",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Micro-credentials",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Occupational Skills training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Other Ministry Delivered or Funded Programs",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Post Secondary Education",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Secondary Education",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Resource and Information Services - General",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Retention support planning",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Supporting changes in work activities and supervision",
        "weight": 0.5,
        "recommended": false
      }
    ]
  },
  "100004": {
    "stream": "Stream C: (Employability Focused)",
    "employability_percentage": 50,
    "plan_items": [
      {
        "item": "Basic Needs - Food security",
        "weight": 6.06,
        "reason": "The client has indicated food insecurity.",
        "recommended": true
      },
      {
        "item": "Employability skills training",
        "weight": 4.84,
        "reason": "The client has indicated that they have poor writing and computer skills.",
        "recommended": true
    },
      {
        "item": "Job seeker - Short-term skills training",
        "weight": 4.84,
        "reason": "The client has indicated that they have poor writing and computer skills.",
        "recommended": true
      },
      {
        "item": "Community Supports - Cultural transition",
        "weight": 4.04,
        "reason": "The client identifies as LGBTQ and may need support in navigating cultural norms in the workplace.",
        "recommended": true
      },
      {
        "item": "Job seeker - Employment-related transportation",
        "weight": 4.04,
        "reason": "The client has indicated that they do not have access to transportation.",
        "recommended": true
      },
      {
        "item": "Referral to Literacy and Basic Skills",
        "weight": 4.04,
        "reason": "The client has indicated that they have poor writing skills.",
        "recommended": true
      },
      {
        "item": "Resource and Information Services - Digital Services",
        "weight": 4.04,
        "reason": "The client has indicated that they have poor computer skills.",
        "recommended": true
      },
      {
        "item": "Job coaching",
        "weight": 4.04,
        "reason": "The client has a criminal record and may need additional support in finding employment.",
        "recommended": true
      },
      {
        "item": "Job seeker - Accommodation needs - on-the-job supports",
        "weight": 4.04,
        "reason": "The client has a visual or hearing disability and may need accommodations at work.",
        "recommended": true
      },
      {
        "item": "Additional services to find another job",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Crisis resolution",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Financial support",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Housing",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Transportation",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Career exploration, planning and management",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Client counselling",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Dependent care",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Justice and legal support",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Newcomer services",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employer - Job placements with financial supports",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employer - Job trials with financial supports",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employer coaching",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employment services for newcomers",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Health Supports - Mental health and addictions",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Health Supports - Primary care and ongoing medical concerns",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job matching and development",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job retention crisis response",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job search",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Academic credential or professional accreditation assessment",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Accommodation needs - job-specific communication skills training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Certification charges",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Emergency/infrequent child care",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Employment-related special equipment and supplies",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Specialized hardware",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Translation of international academic documents",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Work clothing and/or grooming",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Mentoring",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Provision of SSM In-Service Self Employment Services",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Academic Upgrading (College Program)",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Apprenticeship Development Benefit",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Better Jobs Ontario Training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to English as a Second Language Training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to External Self-employment/Entrepreneurship Services",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Government Services - Federal",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Government Services - Municipal",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Micro-credentials",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Occupational Skills training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Other Ministry Delivered or Funded Programs",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Post Secondary Education",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Secondary Education",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Resource and Information Services - General",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Retention support planning",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Supporting changes in work activities and supervision",
        "weight": 0.5,
        "recommended": false
      }
    ]
  },
  "100005": {
    "stream": "Stream B: (Employability and Employment)",
    "employability_percentage": 50,
    "plan_items": [
      {
        "item": "Basic Needs - Food security",
        "weight": 6.06,
        "reason": "The client has indicated food insecurity.",
        "recommended": true
      },
      {
        "item": "Employability skills training",
        "weight": 4.84,
        "reason": "The client has indicated that they have poor writing and computer skills.",
        "recommended": true
    },
      {
        "item": "Job seeker - Short-term skills training",
        "weight": 4.84,
        "reason": "The client has indicated that they have poor writing and computer skills.",
        "recommended": true
      },
      {
        "item": "Community Supports - Cultural transition",
        "weight": 4.04,
        "reason": "The client identifies as LGBTQ and may need support in navigating cultural norms in the workplace.",
        "recommended": true
      },
      {
        "item": "Job seeker - Employment-related transportation",
        "weight": 4.04,
        "reason": "The client has indicated that they do not have access to transportation.",
        "recommended": true
      },
      {
        "item": "Referral to Literacy and Basic Skills",
        "weight": 4.04,
        "reason": "The client has indicated that they have poor writing skills.",
        "recommended": true
      },
      {
        "item": "Resource and Information Services - Digital Services",
        "weight": 4.04,
        "reason": "The client has indicated that they have poor computer skills.",
        "recommended": true
      },
      {
        "item": "Job coaching",
        "weight": 4.04,
        "reason": "The client has a criminal record and may need additional support in finding employment.",
        "recommended": true
      },
      {
        "item": "Job seeker - Accommodation needs - on-the-job supports",
        "weight": 4.04,
        "reason": "The client has a visual or hearing disability and may need accommodations at work.",
        "recommended": true
      },
      {
        "item": "Additional services to find another job",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Crisis resolution",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Financial support",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Housing",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Transportation",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Career exploration, planning and management",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Client counselling",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Dependent care",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Justice and legal support",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Newcomer services",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employer - Job placements with financial supports",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employer - Job trials with financial supports",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employer coaching",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employment services for newcomers",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Health Supports - Mental health and addictions",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Health Supports - Primary care and ongoing medical concerns",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job matching and development",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job retention crisis response",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job search",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Academic credential or professional accreditation assessment",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Accommodation needs - job-specific communication skills training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Certification charges",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Emergency/infrequent child care",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Employment-related special equipment and supplies",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Specialized hardware",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Translation of international academic documents",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Work clothing and/or grooming",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Mentoring",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Provision of SSM In-Service Self Employment Services",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Academic Upgrading (College Program)",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Apprenticeship Development Benefit",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Better Jobs Ontario Training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to English as a Second Language Training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to External Self-employment/Entrepreneurship Services",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Government Services - Federal",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Government Services - Municipal",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Micro-credentials",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Occupational Skills training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Other Ministry Delivered or Funded Programs",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Post Secondary Education",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Secondary Education",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Resource and Information Services - General",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Retention support planning",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Supporting changes in work activities and supervision",
        "weight": 0.5,
        "recommended": false
      }
    ]
  },
  "100006": {
    "stream": "Stream B: (Employability and Employment)",
    "employability_percentage": 60,
    "plan_items": [
      {
        "item": "Basic Needs - Food security",
        "weight": 6.06,
        "reason": "The client has indicated food insecurity.",
        "recommended": true
      },
      {
        "item": "Employability skills training",
        "weight": 4.84,
        "reason": "The client has indicated that they have poor writing and computer skills.",
        "recommended": true
    },
      {
        "item": "Job seeker - Short-term skills training",
        "weight": 4.84,
        "reason": "The client has indicated that they have poor writing and computer skills.",
        "recommended": true
      },
      {
        "item": "Community Supports - Cultural transition",
        "weight": 4.04,
        "reason": "The client identifies as LGBTQ and may need support in navigating cultural norms in the workplace.",
        "recommended": true
      },
      {
        "item": "Job seeker - Employment-related transportation",
        "weight": 4.04,
        "reason": "The client has indicated that they do not have access to transportation.",
        "recommended": true
      },
      {
        "item": "Referral to Literacy and Basic Skills",
        "weight": 4.04,
        "reason": "The client has indicated that they have poor writing skills.",
        "recommended": true
      },
      {
        "item": "Resource and Information Services - Digital Services",
        "weight": 4.04,
        "reason": "The client has indicated that they have poor computer skills.",
        "recommended": true
      },
      {
        "item": "Job coaching",
        "weight": 4.04,
        "reason": "The client has a criminal record and may need additional support in finding employment.",
        "recommended": true
      },
      {
        "item": "Job seeker - Accommodation needs - on-the-job supports",
        "weight": 4.04,
        "reason": "The client has a visual or hearing disability and may need accommodations at work.",
        "recommended": true
      },
      {
        "item": "Additional services to find another job",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Crisis resolution",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Financial support",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Housing",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Transportation",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Career exploration, planning and management",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Client counselling",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Dependent care",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Justice and legal support",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Newcomer services",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employer - Job placements with financial supports",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employer - Job trials with financial supports",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employer coaching",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employment services for newcomers",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Health Supports - Mental health and addictions",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Health Supports - Primary care and ongoing medical concerns",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job matching and development",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job retention crisis response",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job search",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Academic credential or professional accreditation assessment",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Accommodation needs - job-specific communication skills training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Certification charges",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Emergency/infrequent child care",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Employment-related special equipment and supplies",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Specialized hardware",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Translation of international academic documents",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Work clothing and/or grooming",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Mentoring",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Provision of SSM In-Service Self Employment Services",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Academic Upgrading (College Program)",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Apprenticeship Development Benefit",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Better Jobs Ontario Training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to English as a Second Language Training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to External Self-employment/Entrepreneurship Services",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Government Services - Federal",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Government Services - Municipal",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Micro-credentials",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Occupational Skills training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Other Ministry Delivered or Funded Programs",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Post Secondary Education",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Secondary Education",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Resource and Information Services - General",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Retention support planning",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Supporting changes in work activities and supervision",
        "weight": 0.5,
        "recommended": false
      }
    ]
  },
  "100007": {
    "stream": "Stream C: (Employability Focused)",
    "employability_percentage": 50,
    "plan_items": [
      {
        "item": "Basic Needs - Food security",
        "weight": 6.06,
        "reason": "The client has indicated food insecurity.",
        "recommended": true
      },
      {
        "item": "Employability skills training",
        "weight": 4.84,
        "reason": "The client has indicated that they have poor writing and computer skills.",
        "recommended": true
    },
      {
        "item": "Job seeker - Short-term skills training",
        "weight": 4.84,
        "reason": "The client has indicated that they have poor writing and computer skills.",
        "recommended": true
      },
      {
        "item": "Community Supports - Cultural transition",
        "weight": 4.04,
        "reason": "The client identifies as LGBTQ and may need support in navigating cultural norms in the workplace.",
        "recommended": true
      },
      {
        "item": "Job seeker - Employment-related transportation",
        "weight": 4.04,
        "reason": "The client has indicated that they do not have access to transportation.",
        "recommended": true
      },
      {
        "item": "Referral to Literacy and Basic Skills",
        "weight": 4.04,
        "reason": "The client has indicated that they have poor writing skills.",
        "recommended": true
      },
      {
        "item": "Resource and Information Services - Digital Services",
        "weight": 4.04,
        "reason": "The client has indicated that they have poor computer skills.",
        "recommended": true
      },
      {
        "item": "Job coaching",
        "weight": 4.04,
        "reason": "The client has a criminal record and may need additional support in finding employment.",
        "recommended": true
      },
      {
        "item": "Job seeker - Accommodation needs - on-the-job supports",
        "weight": 4.04,
        "reason": "The client has a visual or hearing disability and may need accommodations at work.",
        "recommended": true
      },
      {
        "item": "Additional services to find another job",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Crisis resolution",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Financial support",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Housing",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Transportation",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Career exploration, planning and management",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Client counselling",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Dependent care",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Justice and legal support",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Newcomer services",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employer - Job placements with financial supports",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employer - Job trials with financial supports",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employer coaching",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employment services for newcomers",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Health Supports - Mental health and addictions",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Health Supports - Primary care and ongoing medical concerns",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job matching and development",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job retention crisis response",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job search",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Academic credential or professional accreditation assessment",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Accommodation needs - job-specific communication skills training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Certification charges",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Emergency/infrequent child care",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Employment-related special equipment and supplies",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Specialized hardware",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Translation of international academic documents",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Work clothing and/or grooming",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Mentoring",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Provision of SSM In-Service Self Employment Services",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Academic Upgrading (College Program)",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Apprenticeship Development Benefit",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Better Jobs Ontario Training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to English as a Second Language Training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to External Self-employment/Entrepreneurship Services",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Government Services - Federal",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Government Services - Municipal",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Micro-credentials",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Occupational Skills training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Other Ministry Delivered or Funded Programs",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Post Secondary Education",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Secondary Education",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Resource and Information Services - General",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Retention support planning",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Supporting changes in work activities and supervision",
        "weight": 0.5,
        "recommended": false
      }
    ]
  },
  "100008": {
    "stream": "Stream C: (Employability Focused)",
    "employability_percentage": 50,
    "plan_items": [
      {
        "item": "Basic Needs - Food security",
        "weight": 6.06,
        "reason": "The client has indicated food insecurity.",
        "recommended": true
      },
      {
        "item": "Employability skills training",
        "weight": 4.84,
        "reason": "The client has indicated that they have poor writing and computer skills.",
        "recommended": true
    },
      {
        "item": "Job seeker - Short-term skills training",
        "weight": 4.84,
        "reason": "The client has indicated that they have poor writing and computer skills.",
        "recommended": true
      },
      {
        "item": "Community Supports - Cultural transition",
        "weight": 4.04,
        "reason": "The client identifies as LGBTQ and may need support in navigating cultural norms in the workplace.",
        "recommended": true
      },
      {
        "item": "Job seeker - Employment-related transportation",
        "weight": 4.04,
        "reason": "The client has indicated that they do not have access to transportation.",
        "recommended": true
      },
      {
        "item": "Referral to Literacy and Basic Skills",
        "weight": 4.04,
        "reason": "The client has indicated that they have poor writing skills.",
        "recommended": true
      },
      {
        "item": "Resource and Information Services - Digital Services",
        "weight": 4.04,
        "reason": "The client has indicated that they have poor computer skills.",
        "recommended": true
      },
      {
        "item": "Job coaching",
        "weight": 4.04,
        "reason": "The client has a criminal record and may need additional support in finding employment.",
        "recommended": true
      },
      {
        "item": "Job seeker - Accommodation needs - on-the-job supports",
        "weight": 4.04,
        "reason": "The client has a visual or hearing disability and may need accommodations at work.",
        "recommended": true
      },
      {
        "item": "Additional services to find another job",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Crisis resolution",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Financial support",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Housing",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Transportation",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Career exploration, planning and management",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Client counselling",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Dependent care",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Justice and legal support",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Newcomer services",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employer - Job placements with financial supports",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employer - Job trials with financial supports",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employer coaching",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employment services for newcomers",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Health Supports - Mental health and addictions",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Health Supports - Primary care and ongoing medical concerns",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job matching and development",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job retention crisis response",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job search",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Academic credential or professional accreditation assessment",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Accommodation needs - job-specific communication skills training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Certification charges",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Emergency/infrequent child care",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Employment-related special equipment and supplies",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Specialized hardware",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Translation of international academic documents",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Work clothing and/or grooming",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Mentoring",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Provision of SSM In-Service Self Employment Services",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Academic Upgrading (College Program)",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Apprenticeship Development Benefit",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Better Jobs Ontario Training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to English as a Second Language Training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to External Self-employment/Entrepreneurship Services",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Government Services - Federal",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Government Services - Municipal",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Micro-credentials",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Occupational Skills training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Other Ministry Delivered or Funded Programs",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Post Secondary Education",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Secondary Education",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Resource and Information Services - General",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Retention support planning",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Supporting changes in work activities and supervision",
        "weight": 0.5,
        "recommended": false
      }
    ]
  },
  "100009": {
    "stream": "Stream C: (Employability Focused)",
    "employability_percentage": 50,
    "plan_items": [
      {
        "item": "Basic Needs - Food security",
        "weight": 6.06,
        "reason": "The client has indicated food insecurity.",
        "recommended": true
      },
      {
        "item": "Employability skills training",
        "weight": 4.84,
        "reason": "The client has indicated that they have poor writing and computer skills.",
        "recommended": true
    },
      {
        "item": "Job seeker - Short-term skills training",
        "weight": 4.84,
        "reason": "The client has indicated that they have poor writing and computer skills.",
        "recommended": true
      },
      {
        "item": "Community Supports - Cultural transition",
        "weight": 4.04,
        "reason": "The client identifies as LGBTQ and may need support in navigating cultural norms in the workplace.",
        "recommended": true
      },
      {
        "item": "Job seeker - Employment-related transportation",
        "weight": 4.04,
        "reason": "The client has indicated that they do not have access to transportation.",
        "recommended": true
      },
      {
        "item": "Referral to Literacy and Basic Skills",
        "weight": 4.04,
        "reason": "The client has indicated that they have poor writing skills.",
        "recommended": true
      },
      {
        "item": "Resource and Information Services - Digital Services",
        "weight": 4.04,
        "reason": "The client has indicated that they have poor computer skills.",
        "recommended": true
      },
      {
        "item": "Job coaching",
        "weight": 4.04,
        "reason": "The client has a criminal record and may need additional support in finding employment.",
        "recommended": true
      },
      {
        "item": "Job seeker - Accommodation needs - on-the-job supports",
        "weight": 4.04,
        "reason": "The client has a visual or hearing disability and may need accommodations at work.",
        "recommended": true
      },
      {
        "item": "Additional services to find another job",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Crisis resolution",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Financial support",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Housing",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Transportation",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Career exploration, planning and management",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Client counselling",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Dependent care",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Justice and legal support",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Newcomer services",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employer - Job placements with financial supports",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employer - Job trials with financial supports",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employer coaching",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employment services for newcomers",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Health Supports - Mental health and addictions",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Health Supports - Primary care and ongoing medical concerns",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job matching and development",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job retention crisis response",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job search",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Academic credential or professional accreditation assessment",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Accommodation needs - job-specific communication skills training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Certification charges",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Emergency/infrequent child care",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Employment-related special equipment and supplies",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Specialized hardware",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Translation of international academic documents",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Work clothing and/or grooming",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Mentoring",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Provision of SSM In-Service Self Employment Services",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Academic Upgrading (College Program)",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Apprenticeship Development Benefit",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Better Jobs Ontario Training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to English as a Second Language Training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to External Self-employment/Entrepreneurship Services",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Government Services - Federal",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Government Services - Municipal",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Micro-credentials",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Occupational Skills training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Other Ministry Delivered or Funded Programs",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Post Secondary Education",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Secondary Education",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Resource and Information Services - General",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Retention support planning",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Supporting changes in work activities and supervision",
        "weight": 0.5,
        "recommended": false
      }
    ]
  },
  "100010": {
    "stream": "Stream C: (Employability Focused)",
    "employability_percentage": 40,
    "plan_items": [
      {
        "item": "Basic Needs - Food security",
        "weight": 6.06,
        "reason": "The client has indicated food insecurity.",
        "recommended": true
      },
      {
        "item": "Employability skills training",
        "weight": 4.84,
        "reason": "The client has indicated that they have poor writing and computer skills.",
        "recommended": true
    },
      {
        "item": "Job seeker - Short-term skills training",
        "weight": 4.84,
        "reason": "The client has indicated that they have poor writing and computer skills.",
        "recommended": true
      },
      {
        "item": "Community Supports - Cultural transition",
        "weight": 4.04,
        "reason": "The client identifies as LGBTQ and may need support in navigating cultural norms in the workplace.",
        "recommended": true
      },
      {
        "item": "Job seeker - Employment-related transportation",
        "weight": 4.04,
        "reason": "The client has indicated that they do not have access to transportation.",
        "recommended": true
      },
      {
        "item": "Referral to Literacy and Basic Skills",
        "weight": 4.04,
        "reason": "The client has indicated that they have poor writing skills.",
        "recommended": true
      },
      {
        "item": "Resource and Information Services - Digital Services",
        "weight": 4.04,
        "reason": "The client has indicated that they have poor computer skills.",
        "recommended": true
      },
      {
        "item": "Job coaching",
        "weight": 4.04,
        "reason": "The client has a criminal record and may need additional support in finding employment.",
        "recommended": true
      },
      {
        "item": "Job seeker - Accommodation needs - on-the-job supports",
        "weight": 4.04,
        "reason": "The client has a visual or hearing disability and may need accommodations at work.",
        "recommended": true
      },
      {
        "item": "Additional services to find another job",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Crisis resolution",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Financial support",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Housing",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Basic Needs - Transportation",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Career exploration, planning and management",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Client counselling",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Dependent care",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Justice and legal support",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Community Supports - Newcomer services",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employer - Job placements with financial supports",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employer - Job trials with financial supports",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employer coaching",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Employment services for newcomers",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Health Supports - Mental health and addictions",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Health Supports - Primary care and ongoing medical concerns",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job matching and development",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job retention crisis response",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job search",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Academic credential or professional accreditation assessment",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Accommodation needs - job-specific communication skills training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Certification charges",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Emergency/infrequent child care",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Employment-related special equipment and supplies",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Specialized hardware",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Translation of international academic documents",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Job seeker - Work clothing and/or grooming",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Mentoring",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Provision of SSM In-Service Self Employment Services",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Academic Upgrading (College Program)",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Apprenticeship Development Benefit",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Better Jobs Ontario Training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to English as a Second Language Training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to External Self-employment/Entrepreneurship Services",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Government Services - Federal",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Government Services - Municipal",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Micro-credentials",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Occupational Skills training",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Other Ministry Delivered or Funded Programs",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Post Secondary Education",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Referral to Secondary Education",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Resource and Information Services - General",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Retention support planning",
        "weight": 0.5,
        "recommended": false
      },
      {
        "item": "Supporting changes in work activities and supervision",
        "weight": 0.5,
        "recommended": false
      }
    ]
  },
}

const plan_items = [
  {
    id: 1,
    "item": "Basic Needs - Crisis resolution",
    "weight": 0.5,
    "reason": "Supports and financial assistance to manage an individual's immediate crisis/acute needs (e.g., women's shelter, community mental health and addictions services, Indigenous healing lodges, etc.).",
  },
  {
    id: 2,
    "item": "Basic Needs - Housing",
    "weight": 0.5,
    "reason": "Appropriate housing services to stabilize an individual's housing situation (e.g., social housing, transition housing, supportive housing and/or homelessness prevention).",
  },
  {
    id: 3,
    "item": "Basic Needs - Financial support",
    "weight": 0.5,
    "reason": "Information, resources and access to financial assistance for an individual-s basic needs such as shelter, board and lodging, food, medical, etc.",
  },
  {
    id: 4,
    "item": "Basic Needs - Food security",
    "weight": 0.5,
    "reason": "Information, resources and access to food - culturally appropriate, if required and nutritional information (e.g., affordable, healthy food, food co-ops and banks, resources about food systems specific to a client's Indigenous culture and community gardens).",
  },
  {
    id: 5,
    "item": "Basic Needs - Transportation",
    "weight": 0.5,
    "reason": "Access to transportation for health, human, social and other community services (e.g., medical appointments, caregiving responsibilities, counselling, social assistance appointments and ceremony or cultural events). Not intended for employment and training-related transportation.",
  },
  {
    id: 6,
    "item": "Basic skills - Self-efficacy",
    "weight": 0.5,
    "reason": "Recognizing systemic barriers, assisting clients with addressing personal barriers and challenges improved through resilience (e.g., confidence, motivation, accessing resources, social communication skills).",
  },
  {
    id: 7,
    "item": "Health Supports - Primary care and ongoing medical concerns",
    "weight": 0.5,
    "reason": "Access to appropriate community health resources and referral to primary care supports (i.e., family doctor, traditional medicine practitioners).",
  },
  {
    id: 8,
    "item": "Health Supports - Mental health and addictions",
    "weight": 0.5,
    "reason": "Helping individuals obtain appropriate supports for mental health issues and/or addictions that are culturally appropriate, as required (e.g., anxiety, depression, psychosis, substance abuse, problem gambling).",
  },
  {
    id: 9,
    "item": "Community Supports - Dependent care",
    "weight": 0.5,
    "reason": "Addressing challenges related to caregiving responsibilities and access to affordable and safe child and elder care supports (that are physically, mentally, emotionally, spiritually and culturally safe, as required) and income assistance.",
  },
  {
    id: 10,
    "item": "Community Supports - Cultural connections",
    "weight": 0.5,
    "reason": "Providing opportunities for individuals to build connections to cultural and community networks (e.g., volunteering, recreational activities, cultural services, cultural events).",
  },
  {
    id: 11,
    "item": "Community Supports - Cultural transition",
    "weight": 0.5,
    "reason": "Providing one-on-one assistance in navigating cultural differences in work, community and personal environments that are experienced by clients when relocating or adjusting to new communities.",
  },
  {
    id: 12,
    "item": "Community Supports - Justice and legal support",
    "weight": 0.5,
    "reason": "Addressing challenges to the justice system by providing resources and access to diversion options, legal, restorative justice options, postincarceration and integration supports, and for other legal issues (e.g., family law, Gladue writer and report).",
  },
  {
    id: 13,
    "item": "Community Supports - Newcomer services",
    "weight": 0.5,
    "reason": "Supporting clients who have been residing in Canada less than five years in accessing resources and supports targeted to specialized needs related to newcomers (e.g., settlement services, referral to professional associations, Canadian workplace culture/expectations).",
  },
  {
    id: 14,
    "item": "Custom basic plan item",
    "weight": 0.5,
    "reason": "For exceptions when no other plan item fits the activity. Details must be provided in the accompanying comment/text field. The ministry may provide further guidance on use.",
  },
  {
    id: 15,
    "item": "Resource and information services (R & I) - general",
    "weight": 0.5,
    "reason": "Resources and information on local training and employment opportunities, community service supports, and occupational and training requirements to support independent job search (e.g., access to computers, digital services, workshops, presentations). R & I also includes information on career clarification and planning and providing the resources and tools for clients to make selfdirected, informed decisions related to education, training and employment (including access to ministry skills training programs and other secondary or post-secondary education)",
  },
  {
    id: 16,
    "item": "Resource and information services (R & I) - digital services",
    "weight": 0.5,
    "reason": "Online employment services for self-directed clients to help them make informed choices about his/her/their employment goals and to find work (e.g., resources, guides, and toolkits to support virtual learning and independent job search, career planning, training information, access to job boards, job matching and vacancies, digital FAQs and/or direction to other services and resources).",
  },
  {
    id: 17,
    "item": "Client counselling",
    "weight": 0.5,
    "reason": "One-on-one assistance with an employment caseworker that includes provision of advice/guidance, support and information to help clients determine his/her/their employment goals and needs, and support to secure and maintain employment.",
  },
  {
    id: 18,
    "item": "Employability skills training",
    "weight": 0.5,
    "reason": "Short-term activities and/or interventions, including workshops, designed to help clients make the transition to or maintain work or develop career advancement skills. This service component includes fundamental employmentrelated soft skills, personal management and workplace integration skills (e.g., communication, self-confidence and awareness, time management and teamwork). Given unique client needs, this service may be provided concurrently with life stabilization supports and other employment service components.",
  },
  {
    id: 19,
    "item": "Career exploration, planning and management",
    "weight": 0.5,
    "reason": "Career exploration, planning and management services for informed decision-making to determine career goals and the services required to help achieve them. This could include assistance with: exploring career occupations, skill sets and pathways; considering relevant/current labour market information and employment prospects; and help with self-management to complete skills training, including trades/apprenticeship and/or pursue employment.",
  },
  {
    id: 20,
    "item": "Job search",
    "weight": 0.5,
    "reason": "Structured support for planning and conducting successful job searches, including realistic goalsetting, resume writing, job search methods, assessing skills against qualifications, job application and interviewing assistance.",
  },
  {
    id: 21,
    "item": "Job matching and development",
    "weight": 0.5,
    "reason": "Proactively reaching out to employers to address their workforce needs and identifying/matching clients with the required skills and/or work interests into specific job opportunities (based on different levels of service provision). Development could include facilitating informational meetings, job customization and carving, skills enhancement, and specific jobrelated training.",
  },
  {
    id: 22,
    "item": "Volunteering",
    "weight": 0.5,
    "reason": "Unpaid experiences to provide exposure to workplaces and jobs, with the intention of increasing a client's likelihood of transitioning to paid employment. Volunteering involves performing a service to obtain work experiences, learn new skills and contribute to the community. Volunteering is meant to be brief and experiential; it is not intended to replace paid work and cannot be used as part of job placements.",
  },
  {
    id: 23,
    "item": "Employer - Job trials",
    "weight": 0.5,
    "reason": "Short-duration paid, work exposure opportunities for individuals exploring career options and/or testing out potential jobs and for employers to consider potential employees. Refer to Employer Financial Supports, if applicable.",
  },
  {
    id: 24,
    "item": "Employer - Job placements",
    "weight": 0.5,
    "reason": "Paid on-the-job training and/or work experience opportunities, up to 6 months in length, with the primary purpose of experiential learning, as formally agreed to by the employment caseworker, employer and client as part of the EAP. Refer to Employer Financial Supports, if applicable.",
  },
  {
    id: 25,
    "item": "Employer coaching",
    "weight": 0.5,
    "reason": "Providing employers with information and resources about such areas as workplace health and safety (including accessibility accommodations), cultural competency training and workplace policy development.",
  },
  {
    id: 26,
    "item": "Job coaching",
    "weight": 0.5,
    "reason": "Supporting clients with the transition to work (before job start to settle into a job), including understanding employer expectations, workplace orientation, work capacity, etc. Job coaching may also be provided to employers to facilitate the onboarding of a client/new employee.",
  },
  {
    id: 27,
    "item": "Referral and/or case management for self-employment and entrepreneurship",
    "weight": 0.5,
    "reason": "Case management for clients who are referred to and participate in skills training for successful self-employment or entrepreneurship. This support could include one-on-one discussions and advice, guidance related to participation in related workshops and/or a structured referral to related community resources.",
  },
  {
    id: 28,
    "item": "Custom basic plan item",
    "weight": 0.5,
    "reason": "For exceptions when no other plan item fits the activity. Details must be provided in the accompanying comment/text field. The ministry may provide further guidance on use.",
  },
  {
    id: 29,
    "item": "Retention support planning",
    "weight": 0.5,
    "reason": "Discussion with the client and the employer to determine specific, ongoing service needs to be reflected and/or updated in the EAP and provided to help him/her/them retain or maintain a job and for career advancement, as appropriate.",
  },
  {
    id: 30,
    "item": "Accessible workplace consultation for clients with a disability",
    "weight": 0.5,
    "reason": "Building on services provided through job matching and/or placement, discussing with clients and employers, clients' accessibility needs, including any workplace-related accommodations, assistive devices or adaptive technology.",
  },
  {
    id: 31,
    "item": "Ongoing job coaching",
    "weight": 0.5,
    "reason": "Once a client has started work and for job maintenance purposes, ongoing and regular progress monitoring to facilitate supports which help clients adapt to their new jobs and workplaces (e.g., job performance and productivity, workplace integration). Job coaching services are also available to employers related to ongoing staff training and evaluation, workplace inclusion, etc.",
  },
  {
    id: 32,
    "item": "On-the-job training",
    "weight": 0.5,
    "reason": "Assistance to negotiate additional workplace supports and/or training/learning opportunities for career development and/or sustained employment. Not intended for transition to employment.",
  },
  {
    id: 33,
    "item": "Mentoring",
    "weight": 0.5,
    "reason": "Working with employers to help develop mentors/peer coaches to support clients, culturally appropriate when possible, and monitoring impact and facilitating adjustments, as required.",
  },
  {
    id: 34,
    "item": "Job retention crisis response",
    "weight": 0.5,
    "reason": "Assisting clients who are at immediate risk of losing their job and helping employers develop a plan/address issues (e.g., where a client is experiencing physical, mental or emotional distress related to work that requires a managerlevel response).",
  },
  {
    id: 35,
    "item": "Supporting changes in work activities and supervision",
    "weight": 0.5,
    "reason": "Support for changes in a client's employment situation (e.g., routine, tasks, schedules) and changes in supervision.",
  },
  {
    id: 36,
    "item": "Employer retention coaching",
    "weight": 0.5,
    "reason": "Providing employers with information or resources to facilitate the employee retention process through increased knowledge of a variety of workplace issues (e.g., cultural competency, health and safety, accessibility accommodations and workplace diversity).",
  },
  {
    id: 37,
    "item": "Additional services to find another job",
    "weight": 0.5,
    "reason": "Additional support for clients who are no longer receiving employment services for immediate reinstatement (e.g., additional services that will lead to finding another job).",
  },
  {
    id: 38,
    "item": "Custom basic plan item",
    "weight": 0.5,
    "reason": "For exceptions when no other plan item fits the activity. Details must be provided in the accompanying comment/text field. The ministry may provide further guidance on use.",
  },
  {
    id: 39,
    "item": "Employment services for people with disabilities",
    "weight": 0.5,
    "reason": "Targeted employment services for people with disabilities (as defined under the Ontario Human Right's Code and/or through the Common Assessment or supplementary assessment tools).",
  },
  {
    id: 40,
    "item": "Workplace consultation for clients with a disability(ies)",
    "weight": 0.5,
    "reason": "Discussing with clients and employers, clients' anticipated need for specialized employment services based on skills and strengths, while acknowledging barriers to employment, including those resulting from an individual's disability(ies) and the workforce and skills needs of employers, and strategies to address them (e.g., more intensive and longer duration services, assistive technology, specific on-the-job supports and personal assistance services)",
  },
  {
    id: 41,
    "item": "Employer job carving",
    "weight": 0.5,
    "reason": "Working with employers to create or modify a job to fit an individual's capability and the needs of the employer (i.e., involves the negotiation of a job description that is based on the tasks of a single job within a workplace and could contain one or more, but not all, of the tasks from the original job description).",
  },
  {
    id: 42,
    "item": "Employer education and training",
    "weight": 0.5,
    "reason": "Providing information and training to managers and staff on best practices for awareness, accommodating and working with employees with disabilities and creating a more supportive and inclusive work environment (e.g., information on various types of disabilities, resources, workplace tools and supports).",
  },
  {
    id: 43,
    "item": "Culturally-appropriate employment services for Indigenous peoples",
    "weight": 0.5,
    "reason": "Referral, access to and/or the delivery of employment services that are responsive to the unique employment needs of Indigenous clients. This may include, but is not limited to the provision of appropriate services in a culturally safe, aware, sensitive and equitable way that recognizes and respects the unique history and experiences of Indigenous peoples.",
  },
  {
    id: 44,
    "item": "Employment services for Francophones",
    "weight": 0.5,
    "reason": "Employment services in French for people whose mother tongue is French, and those whose mother tongue is neither French nor English, but who have a knowledge of French as an Official Language and use French at home, including many recent immigrants to Ontario.",
  },
  {
    id: 45,
    "item": "Employment services for youth with higher support needs",
    "weight": 0.5,
    "reason": "Targeted employment services for youth with higher support needs (as defined by a person who is between the age of 15 and 29 and has been segmented into Stream C by the Common Assessment tool).",
  },
  {
    id: 46,
    "item": "Employment services for newcomers",
    "weight": 0.5,
    "reason": "Targeted employment services for clients who have been residing in Canada less than five years.",
  },
  {
    id: 47,
    "item": "Custom basic plan item",
    "weight": 0.5,
    "reason": "For exceptions when no other plan item fits the activity. Details must be provided in the accompanying comment/text field. The ministry may provide further guidance on use",
  },
  {
    id: 48,
    "item": "Job seeker - Accommodation needs - assistive devices and adaptive technology",
    "weight": 0.5,
    "reason": "Assistive devices used to replace, compensate for, or improve the functional abilities of people with long-term physical disabilities (e.g., mobility and visual/hearing aids, orthotics/prosthetics, speech generating and respiratory devices). Adaptive technology modifies standard technical systems for use by people with disabilities (e.g., computers with access devices, adapted information systems and accessible communication networks).",
  },
  {
    id: 49,
    "item": "Job seeker - Accommodation needs - job-specific communication skills training",
    "weight": 0.5,
    "reason": "Skills training for oral and written communication to bring client functional communication skills to a level where they can perform essential job duties. (e.g., ASL, LSQ, Braille or remedial writing for people with learning disabilities).",
  },
  {
    id: 50.5,
    "item": "Job seeker - Accommodation needs - on-the-job supports",
    "weight": 0.5,
    "reason": "Supports to assist with job duties (e.g., sign language interpreter, real-time captioning and reader and note-taker services).",
  },
  {
    id: 51,
    "item": "Job seeker - Diagnostic assessment",
    "weight": 0.5,
    "reason": "Specialized diagnostic assessments to obtain critical insights about a client's employment capacities that cannot be achieved through other means (e.g., undiagnosed learning disability).",
  },
  {
    id: 52,
    "item": "Job seeker - Certification charges",
    "weight": 0.5,
    "reason": "Costs related to occupational certification required for a specific job opportunity (i.e., taking an exam and/or for the formal document attesting to a set of skills, knowledge and abilities gained through completion of a set of education/skills training requirements).",
  },
  {
    id: 53,
    "item": "Job seeker - Academic credential or professional accreditation assessment",
    "weight": 0.5,
    "reason": "Assessment of a job seeker's highest level of education or credentials (e.g. high school diploma, college diploma/certificate, university degree) or their regulated trade certificate or regulated professional accreditation from outside Canada for Canadian or provincial/territorial equivalency.",
  },
  {
    id: 54,
    "item": "Job seeker - Language skills assessment",
    "weight": 0.5,
    "reason": "Assessment, often based on the Canadian Language Benchmarks, to assess English and/or French proficiency for adult newcomers, immigrants and prospective immigrants, or to assess job seeker literacy levels. It includes a wide range of approaches, from informal procedures to formal standardized tests.",
  },
  {
    id: 55,
    "item": "Job seeker - Short-term skills training",
    "weight": 0.5,
    "reason": "A vocational training course or program that relates to the skills required to perform job duties (e.g., health and safety, single skill, upskilling for competency or entry-level positions).",
  },
  {
    id: 56,
    "item": "Job seeker - Emergency/infrequent child care",
    "weight": 0.5,
    "reason": "Incremental, upfront child care that is reasonably necessary to participate in employment services and/or transition to employment (e.g., attending group workshops, employer interviews, skills training).",
  },
  {
    id: 57,
    "item": "Job seeker - Employment-related transportation",
    "weight": 0.5,
    "reason": "Incremental, temporary daily commuting expenses, to and from, for participating in employment services and/or transitioning to employment (e.g., bus/subway/train costs or pass).",
  },
  {
    id: 58,
    "item": "Job seeker - Work clothing and/or grooming",
    "weight": 0.5,
    "reason": "Appropriate clothing and grooming for workplace environments required for interviews and/or workrelated appointments and/or transition to work.",
  },
  {
    id: 59,
    "item": "Job seeker - Employment-related special equipment and supplies",
    "weight": 0.5,
    "reason": "Special vocational or job-specific tools or equipment required for a job (e.g., footwear, safety equipment).",
  },
  {
    id: 60.5,
    "item": "Job seeker - Translation of international academic documents",
    "weight": 0.5,
    "reason": "Translation of academic documents for internationally trained clients required to demonstrate qualifications.",
  },
  {
    id: 61,
    "item": "Job seeker - Specialized hardware",
    "weight": 0.5,
    "reason": "Specific IT hardware required to perform job duties (e.g., mobile computing devices or other electronic equipment).",
  },
  {
    id: 62,
    "item": "Employer - Job trials with financial supports",
    "weight": 0.5,
    "reason": "Provided to employers for clients who participate in job trials. See related EAS plan item. Should not duplicate financial supports for job seekers.",
  },
  {
    id: 63,
    "item": "Employer - Job placements with financial supports",
    "weight": 0.5,
    "reason": "Provided to employers for clients who participate in job placements. See related EAS plan item. Should not duplicate financial supports for job seekers.",
  },
  {
    id: 64,
    "item": "Employer - Job accommodation",
    "weight": 0.5,
    "reason": "Provided to employers to raise awareness of workplace issues for people with disabilities (e.g., workshops and diversity training).",
  },
  {
    id: 65,
    "item": "Enhanced Referrals for Skills Development",
    "weight": 0.5,
    "reason": "Formalized planned, supported and co-ordinated arrangements to connect and support clients to MLTSD and other training and education programs, including case management (i.e., follow-up on progress, completion and need for complimentary or additional employment services that may be required to meet employment goals).",
  },
]

const ReturnToWorkPredictionFormSection = ({
  setShowWarning,
  acceptWarning,  
  setAcceptWarning
}) => {
  const [clientsName, setClientsName] = useState();
  const [clientID, setSelectedClientID] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState();
  const [override, setOverride] = useState(false);
  const [clientStream, setClientStream] = useState("Client's Stream");
  const [percentValue, setPercentValue] = useState(0);
  const [planItems, setPlanItems] = useState();

  const reset = () => {
    setClientStream("Client's Stream");
    setPercentValue(0);
    setResult();
    setAcceptWarning(false);
  }
  
  const handleBtnClick = (element) => {
    const selectedButton = document.getElementById(element[0]);
    if(selectedButton.classList.contains("btn-bg-alt")) {
      selectedButton.classList.remove("btn-bg-alt");
      setPercentValue(percentValue => (((percentValue * 100) + (parseFloat(element[1].weight) * 100)) / 100).toFixed(2));
    } else {
      selectedButton.classList.add("btn-bg-alt");
      setPercentValue(percentValue => (((percentValue * 100) - (parseFloat(element[1].weight) * 100)) / 100).toFixed(2));
    }
  }

  const downloadAndReadFile = async (fileURL, clientID) => {
    try {
      const response = await fetch(fileURL,
        {
          method: "GET",
          redirect: "follow"
        });
      const resultJSON = await response.json();
      // setResult(resultJSON);
      let prediction = resultJSON[clientID];
      setResult(prediction);
      let planItemsMap = new Map();
      for(let i = 1; i <= prediction.plan_items.length; i++) {
        let temp = prediction.plan_items[i - 1];
        temp = {...temp, "removable" : false};
        planItemsMap.set(i, temp);
      }
      setPlanItems(planItemsMap);
    } catch (error) {
      console.error('Error downloading JSON:', error);
    }
  }

  const downloadFile = async ({ data, fileName, fileType }, clientID) => {
    setIsLoading(true);
    const blob = new Blob([data], { type: fileType });


    const formData = new FormData();
    formData.append("inputFile", blob, fileName);
    formData.append("resultType", "json");
    try {
      const res = await fetch(process.env.REACT_APP_FORM_ENDPOINT, {
        method: "POST",
        body: formData
      });
      const responseData = await res.json();
      const fileURL = responseData.json.results;
      await downloadAndReadFile(fileURL, clientID);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
    setIsLoading(false);
  }

  const updateClientDetails = (selected) => {
    setSelectedClientID(selected.value);
    // Create CSV
    let headers = ['Client_ID,Birth Date,Age,City,Province,Preferred_Language,Birth_Country,Year_of_Arrival_to_Canada,Years_in_Canada,Status_In_Canada,Immigration_Category,Marital_Status,Number_of_Dependents,Gender_Identity,LGBTQ,Francophone,Indigenous,Race,Education_Level,Education_Completion_Year,Education_Location,Language_Skills_Help,Writing_Skills,Verbal_Skills,Numerical_Skills,Computer_Skills,Support_Connections,Internet_Access,Transportation_Access,Caregiver,Housing_Situation,Housing_help,Food_Insecurity,Immediate_Assistance_Need,Safety_Concerns,Mental_Health_Support,Substance_Use_Support,Source_of_Income,Visual_or_hearing_Disability,Physical_disability,Learning_Disability,Intellectual_Disability,Mental_Health_Condition,Long_Term_Health_Condition,Cronic_pain,Crime_Conviction,Current_Student,Currently_Working,months_since_last_job,Work_duration,Hours_worked_last_job,Hourly_wage_last_job,Canadian_work_experience,Ability_to_work,Hours_available_to_work,Desired_Hourly_Wage'];
    // Convert users data to a csv
    let usersCsv = clients2.reduce((acc, user) => {
      const { Client_ID, BirthDate, Age, City, Province, Preferred_Language, Birth_Country, Year_of_Arrival_to_Canada, Years_in_Canada, Status_In_Canada, Immigration_Category, Marital_Status, Number_of_Dependents, Gender_Identity, LGBTQ, Francophone, Indigenous, Race, Education_Level, Education_Completion_Year, Education_Location, Language_Skills_Help, Writing_Skills, Verbal_Skills, Numerical_Skills, Computer_Skills, Support_Connections, Internet_Access, Transportation_Access, Caregiver, Housing_Situation, Housing_help, Food_Insecurity, Immediate_Assistance_Need, Safety_Concerns, Mental_Health_Support, Substance_Use_Support, Source_of_Income, Visual_or_hearing_Disability, Physical_disability, Learning_Disability, Intellectual_Disability, Mental_Health_Condition, Long_Term_Health_Condition, Cronic_pain, Crime_Conviction, Current_Student, Currently_Working, months_since_last_job, Work_duration, Hours_worked_last_job, Hourly_wage_last_job, Canadian_work_experience, Ability_to_work, Hours_available_to_work, Desired_Hourly_Wage } = user;
      if(Client_ID === selected.value) acc.push([Client_ID, BirthDate, Age, City, Province, Preferred_Language, Birth_Country, Year_of_Arrival_to_Canada, Years_in_Canada, Status_In_Canada, Immigration_Category, Marital_Status, Number_of_Dependents, Gender_Identity, LGBTQ, Francophone, Indigenous, Race, Education_Level, Education_Completion_Year, Education_Location, Language_Skills_Help, Writing_Skills, Verbal_Skills, Numerical_Skills, Computer_Skills, Support_Connections, Internet_Access, Transportation_Access, Caregiver, Housing_Situation, Housing_help, Food_Insecurity, Immediate_Assistance_Need, Safety_Concerns, Mental_Health_Support, Substance_Use_Support, Source_of_Income, Visual_or_hearing_Disability, Physical_disability, Learning_Disability, Intellectual_Disability, Mental_Health_Condition, Long_Term_Health_Condition, Cronic_pain, Crime_Conviction, Current_Student, Currently_Working, months_since_last_job, Work_duration, Hours_worked_last_job, Hourly_wage_last_job, Canadian_work_experience, Ability_to_work, Hours_available_to_work, Desired_Hourly_Wage].join(','));
      return acc;
    }, []);
    let timeStamp = new Date();
    downloadFile({
      data: [...headers, ...usersCsv].join('\n'),
      fileName: `client_${(timeStamp.getUTCFullYear())}-${(timeStamp.getUTCMonth() + 1).toString().padStart(2,"0")}-${(timeStamp.getUTCDate()).toString().padStart(2,"0")}-${(timeStamp.getUTCHours()).toString().padStart(2,"0")}${(timeStamp.getUTCMinutes()).toString().padStart(2,"0")}${(timeStamp.getUTCSeconds()).toString().padStart(2,"0")}.csv`,
      fileType: 'text/csv',
    }, selected.value);
  }
  
  const tempFnShowPrediction = (selected) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setSelectedClientID(selected.value);
      
      let prediction = items[selected.value];
      setResult(prediction);
      let planItemsMap = new Map();
      for(let i = 1; i <= prediction.plan_items.length; i++) {
        let temp = prediction.plan_items[i - 1];
        temp = {...temp, "removable" : false};
        planItemsMap.set(i, temp);
      }
      setPlanItems(planItemsMap);
      // console.log(temp);
      // let filteredArr = plan_items.filter(function(itemOfAll) {
      //   return recommendedPlanItems.every((itemOfFilter) => {
      //     return itemOfFilter.item !== itemOfAll.item
      //   });
      // });
      // updatePlanItems();
      // let recommendedPlanItems = [...planItemsMap.entries()].filter((planItem) => { if(planItem[1].recommended === true) { return planItem.splice(0, 1) } return null}).map(arr => ({ ...arr[0] }));
      // let filteredArr = [...planItemsMap.entries()].filter((planItem) => { if(planItem[1].recommended !== true) { return planItem.splice(0, 1) } return null}).map(arr => ({ ...arr[0] }));
      // setSelectedClientItems([...recommendedPlanItems]);
      // setFilteredPlanItems([...filteredArr]);
    }, 200);
  }

  const handleClientNameSelect = (selected) => {
    reset();
    selected && updateClientDetails(selected);
    // selected && tempFnShowPrediction(selected);
  }

  const handlePlanItemClick = (element) => {
    setPlanItems(new Map(planItems).set(element[0], {...planItems.get(element[0]), "recommended": true}));
  }

  const handleRemoveItem = (element) => {
    let el = document.getElementById(element[0]);
    if(!el.classList.contains("btn-bg-alt")) {
      el.click();
    }
    setPlanItems(new Map(planItems).set(element[0], {...planItems.get(element[0]), "recommended" : false}));
  }

  const handleEditMode = (element) => {
    // let tempArr = selectedClientItems.map((item) => {
    //   if(item.id === element.id) {
    //     return { ...item, editMode: true };
    //   } else {
    //     return item;
    //   }
    // });
    // setSelectedClientItems([...tempArr]);
  }

  const handleChangeWeight = (e, id) => {
    // let tempArr = selectedClientItems.map((item) => {
    //   if(item.id === id) {
    //     return { ...item, weight: parseInt(e.target.value) };
    //   } else {
    //     return item;
    //   }
    // });
    // setSelectedClientItems([...tempArr]);
  }

  const handleDisableEditMode = (id) => {
    // let tempArr = selectedClientItems.map((item) => {
    //   if(item.id === id) {
    //     return { ...item, editMode: false };
    //   } else {
    //     return item;
    //   }
    // });
    // setSelectedClientItems([...tempArr]);
  }

  useEffect(() => {
    let tempArr = [];
    clients2.forEach((client) => {
      tempArr.push({"value": client.Client_ID, "label" : client.clientName + " (" + client.Client_ID + ")"});
    });  
    setClientsName(tempArr);
  }, [])

  useEffect(() => {
    if(clientID && result) {
      setClientStream(result.stream);
      setPercentValue(parseFloat(result.employability_percentage).toFixed(2));
      // let tempArr = plan_items.filter(function(itemOfAll) {
      //   return result.plan_items.some((itemOfFilter) => {
      //     if(itemOfFilter.item === itemOfAll.item) {
      //       itemOfAll.weight = itemOfFilter.weight;
      //       itemOfAll.reason = itemOfFilter.reason;
      //     }
      //     return itemOfFilter.item === itemOfAll.item;
      //   });
      // });
      // setSelectedClientItems([...tempArr]);
    }
  }, [result, clientID])

  useEffect(() => {
    let temp;
    if(acceptWarning) {
      temp = true;
    } else {
      temp = false;
    }
    setOverride(temp);
  }, [acceptWarning])
  
  useEffect(() => {
    if(override) {
      setPlanItems(planItems => {
        let tempMap = new Map(planItems);
        tempMap.forEach((planItem) => {return (planItem["removable"] = override)});
        return tempMap;
      });
    }
  }, [override])

  // useEffect(() => {
  //   if(result) {
  //     for(let i = 1; i < 4; i++) {
  //       document.getElementById(i).click();
  //     }
  //   }
  // }, [result])
  
  return (
    <>
      <Element
        name="aiform" 
        className="edu-about-area about-style-1 edu-section-gap"
      >
        <div className="container inner about-feature-list">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <ReturnToWorkPredictionSectionTitle
                classes = "text-center"
                // slogan = "Eligibility"
                title = "Return To Work Prediction"
              />
            </div>
            {/* <div className="col-12 col-md-6 col-lg-4 discover-ai-form-container mt--80">
              <ReturnToWorkPredictionForm setOutputData={setOutputData} />
              <div className="form-output mt--40">
                {outputData && <ReturnToWorkPredictionDownloadButton outputData={outputData} />}
              </div>
            </div>
            <div className="return-to-work-prediction-section-divider"></div> */}
            <div className="col-12 row client-details-container mt--40">
              <div className="col-12 d-flex flex-column align-items-center justify-content-center">
                <h4>Client Details</h4>
                <div className="select-client-container">
                  <h6>Name</h6>
                  <Select
                    className="basic-single client-detail-select-container"
                    placeholder="Select or search by name..."
                    isClearable="true"
                    name="clientName"
                    onChange={handleClientNameSelect}
                    options={clientsName}
                  />
                </div>
                {isLoading && <div className="mt-4">Processing data...<br></br><img src={`${process.env.PUBLIC_URL}/images/loading.gif`} alt="illustration" /></div>}
              </div>
              {result && <><div className="col-12 col-lg-5 flex-grow-1 client-detail-wrapper mt--40">
                {/* <h6 className="mt--30">Age</h6>
                <p className="client-detail-container">{clientAge}</p>
                <h6>Nationality</h6>
                <p className="client-detail-container">{clientNationality}</p>
                <h6>Years in Canada</h6>
                <p className="client-detail-container">{clientYearsInCanada}</p> */}
                <h6>Stream</h6>
                <p className="client-detail-container">{clientStream}</p>
                <div className="line-divider"></div>
                {planItems.size > 0 && <div className="plan-items-list-container mt--110">
                  {!override && <><button className="edu-btn btn-secondary btn-small" onClick={() => setShowWarning(true)}>Override Recommendations</button><br></br>
                  <span className="caution">Caution: Use this to override model recommendations</span></>}
                  {override && <><h6 className="mt--30">Plan items</h6>
                  <span className="caution">Caution: Use this to override model recommendations</span>
                  <ul className="plan-items-list">
                    {Array.from(planItems.entries()).filter(([key, value]) => value.recommended !== true).map((el, idx) => <li key={el[0]} id={el[0]} onClick={()=> handlePlanItemClick(el)}><span className="plan_item-item">{el[1].item}</span><span className="plan_item-reason"> &nbsp;&#40;{ el[1].weight}&#41;</span></li>)}
                  </ul></>}
                </div>}
              </div>
              <div className="section-divider-vertical d-none d-md-block mt--40"></div>
              <div className="col-12 col-md-5 flex-grow-1 client-predictions-container mt--40">
                <h4>Predictions</h4>
                <h6>Return to work (RTW)</h6>
                <div className="d-flex flex-row justify-content-evenly align-items-center mt--40">
                  <Pie percentage={percentValue} />
                  <h2>{percentValue > 100 ? 100 : percentValue}%</h2>
                </div>
                <h6 className="mt--40">Recommended Plan items to increase RTW</h6>
                <div className="recommended-plan-items-container mt--20">
                  {Array.from(planItems.entries()).filter(([key, value]) => value.recommended === true).map((element, idx) => (
                    <div className="button-wrapper d-flex flex-row align-items-center" key={element[0]}>
                      {element[1].reason ? <><button className="recommended-plan-item edu-btn btn-bg-alt w-100" data-tooltip-id={element[0].toString()} data-tooltip-content={element[1].reason} data-tooltip-delay-show={500} id={element[0].toString()} value={element[1].weight} onClick={() => handleBtnClick(element)} disabled={element[1].editMode ? true : false}>{element[1].item}&nbsp;&#40;{element[1].weight}&#41;</button><Tooltip id={element[0].toString()} /><br></br></> : <button className="recommended-plan-item edu-btn btn-bg-alt w-100" id={element[0].toString()} value={element[1].weight} onClick={() => handleBtnClick(element)} disabled={element[1].editMode ? true : false}>{element[1].item}&nbsp;&#40;{element[1].weight}&#41;</button>}
                      {element[1].removable && ( !element[1].editMode ? <>
                        {/* &nbsp;<button className="cursor-pointer edu-btn btn-bg-alt px-2" onClick={() => {setShowEdit(true); setElement(element)}}>✏️</button> */}
                        {/* &nbsp;<button className="cursor-pointer edu-btn btn-bg-alt px-2" onClick={() => handleEditMode(element)}>✏️</button> */}
                        &nbsp;<button className="cursor-pointer edu-btn btn-bg-alt px-2" onClick={() => handleRemoveItem(element)}>🗑️</button>
                      </> : <>
                        &nbsp;<input type="number" name="weight" style={{maxWidth:"68px"}} value={element.weight} onChange={(e) => handleChangeWeight(e, element.item)} />
                        &nbsp;<button className="cursor-pointer edu-btn btn-bg-alt px-2" onClick={() => { handleDisableEditMode(element.item)}}>✔️</button>
                        {/* &nbsp;<button className="cursor-pointer edu-btn btn-bg-alt px-2" onClick={() => handleDisableEditMode(element.id)}>❌</button> */}
                      </>)}
                    </div>
                  ))}
                </div>
              </div></>}
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default ReturnToWorkPredictionFormSection;