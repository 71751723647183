import React, { useEffect } from 'react';
import SEO from '../../common/SEO';
import JobsContent from '../../components/Jobs/JobsContent';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const Jobs = () => {
  useEffect(() => {
    window.location.href="https://jobs.m2mtechconnect.com"
  }, []);
  return null;
	// return (
	// 	<>
	// 		<SEO
  //       title="Jobs"
  //       description="Unlock your potential in research or work opportunities with M2M Tech!"
  //     />
  //     <Layout>
  //       <BreadcrumbOne 
  //         title="Jobs"
  //         rootUrl="/"
  //         parentUrl="Home"
  //         currentUrl="Jobs"
  //       />
  //       <div className="eduvibe-contact-us edu-contact-us-area edu-section-gap bg-color-white">
  //         <div className="container eduvibe-animated-shape">
  //           <JobsContent />
  //         </div>
  //       </div>
  //     </Layout>
	// 	</>
  // )
}
export default Jobs;