export const DataTalentBlogData = [
    {
        "id": 1,
        "title": "Exploring Lending Club Loans",
        "image": "data-talent/article_real-world_analytics_v2.webp",
        "date": "7 January, 2024",
        "shortDate": "7 Jan",
        "author": ["Claude"],
        "readingTime": "3 Min Read",
        "categories": ["UMcG"],
        "tags": ["STEM", "Students", "Technology", "STEM Education"],
        "details": `Claude de Villeres researched and wrote about the financial technology sector for his Capstone Project. His article “Real-world Analytics - Exploring Lending Club Loans” provides a comprehensive analysis of loans within the Lending Club.
        The Lending Club is an online loan marketplace which provides users with easier access to reduced interest rates across various loan types. De Villeres refers to the 2007 to 2011 dataset from the Lending Club which examines details about credit scores, finance inquiries, collections and more with approximately 39,000 loans recorded.`,
        "link": "https://medium.com/@jvilleres3/real-world-analytics-exploring-lending-club-loans-c84a84fe0379",
    },
    {
        "id": 2,
        "title": "Analytics of Sleep Disorders",
        "image": "data-talent/article_analytics-of-sleep-disorder.webp",
        "date": "11 Mar, 2024",
        "shortDate": "11 Mar",
        "author": ["Penelope"],
        "readingTime": "6 Min Read",
        "categories": [""],
        "tags": ["Data Analytics"],
        "details": `Penelope researched and wrote about the health data sector for her Capstone Project. Her article "Analystics of Sleep Disorders" provides a comprehensive analysis of trends in sleep data and sleep disorders of 374 subjects.
        The analysis was divided into two parts. The first part was an Exploratory Data Analysis (EDA) of the dataset to find patterns and potential errors in the data using Jupyter Notebook in Google Colab. The second part was a data visualization analysis using Power BI.`,
        "link": "https://medium.com/@penelopeharo2/analytics-of-sleep-disorders-5f136001e85a#id_token=eyJhbGciOiJSUzI1NiIsImtpZ[%E2%80%A6]SgEJl4pZn0pQAMG3A6aV28mHkcNQOyd17OA",
    },
    {
        "id": 3,
        "title": "Python Programming for Data Science",
        "image": "data-talent/blog6_3_v3.webp",
        "date": "12 Mar, 2024",
        "shortDate": "12 Mar",
        "author": ["Lucas Ho"],
        "readingTime": "6 Min Read",
        "categories": [""],
        "tags": ["Python", "Machine Learning"],
        "details": `Lucas researched and wrote about the credit score classification for his Capstone Project. His article "Python Programming for Data Science" provides an algorithm capable of accurately segregating individuals into credit score brackets.
        Through the utilization of advanced algorithms and predictive modeling, the algorithm endeavors to analyze the complex interplay of financial and personal attributes to predict credit scores effectively.`,
        "link": "https://lucashomil.github.io/datascience/blog-6.html",
    },
    {
        "id": 4,
        "title": "Analyzing Stock Performance: A Data-driven Approach",
        "image": "data-talent/1_1Ga0NJ1YWRk4PNSI3qGBjw_v2.webp",
        "date": "23 Mar, 2024",
        "shortDate": "23 Mar",
        "author": ["Farzad Khazaee"],
        "readingTime": "5 Min Read",
        "categories": [""],
        "tags": ["Data Analysis", "Data Visualization", "Data Science", "Stock Prediction"],
        "details": `Farzad Khazaee researched and wrote about the stock market for his Capstone Project. His article "Analyzing Stock Performance: A Data-driven Approach" explores the stock performance of Apple Inc. (AAPL) using Python and data visualization libraries.
        From feature engineering to identifying peaks and creating interactive plots, these analyses provide a comprehensive view of Apple's stock behavior over time.`,
        "link": "https://medium.com/@farzadkhazaee72/analyzing-stock-performance-a-data-driven-approach-cd7d940f4741",
    },
    {
        "id": 5,
        "title": "Unveiling User Sentiments: A Deep Dive into Spotify App Reviews",
        "image": "data-talent/1_lhhMHbwDIUJS4OO1q_wldQ_v2.webp",
        "date": "23 Mar, 2024",
        "shortDate": "23 Mar",
        "author": ["Farzad Khazaee"],
        "readingTime": "5 Min Read",
        "categories": [""],
        "tags": ["Artificial Intelligence", "NLP", "Data Science", "Sentiment Analysis", "Recurrent Neural Network"],
        "details": `Farzad Khazaee researched and wrote about the stock market for his Capstone Project. His article "Unveiling User Sentiments: A Deep Dive into Spotify App Reviews" embarks on a journey through the realm of sentiment analysis, delving into the trove of Spotify app reviews from the Google Play Store.
        He built and examine his DL model by increasing level of complexity, changing algorithm, performing fine-tuning with different optimizers and random search to achieve higher accuracy score.`,
        "link": "https://medium.com/@farzadkhazaee72/unveiling-user-sentiments-a-deep-dive-into-spotify-app-reviews-69426a423128",
    },
    {
        "id": 6,
        "title": "Global Energy Availability + Consumption 2000 - 2020",
        "image": "data-talent/global_energy_availabliity_v2.webp",
        "date": "17 Sep, 2024",
        "shortDate": "17 Sep",
        "author": ["Melanie Wilson"],
        "readingTime": "5 Min Read",
        "categories": [""],
        "tags": ["Data Analysis", "Energy Consumption", "Energy Generation", "Data Modeling"],
        "details": `Melanie's article showcases a project using a global energy dataset from Kaggle. She adapted code to create heat maps, linear regression visuals, histograms, and cluster models, comparing PCA (Principal Component Analysis) and non-PCA approaches. The project includes two Google Colab notebooks: one with PCA modeling and one without.`,
        "link": "https://medium.com/@mlysons65/global-energy-availability-consumption-2000-2020-83aed3ac37ec",
    },
]
