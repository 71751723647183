import React, { useEffect } from 'react';
import SEO from '../../common/SEO';
// import RegisterProgramForm from '../../components/form/register-program-form/RegisterProgramForm';

import HeaderTwo from '../../common/header/HeaderTwo';
import FooterOne from '../../common/footer/FooterOne';
import SkillsboostBanner from '../../components/skillsboost/SkillsboostBanner';
import SkillsboostDescription from '../../components/skillsboost/SkillsboostDescription';
// import SkillsboostFAQs from '../../components/skillsboost/SkillsboostFAQs';
import SkillsboostWhyChooseUs from '../../components/skillsboost/SkillsboostWhyChooseUs';
import SkillsboostSignUpForm from '../../components/form/skillsboost-form/SkillsboostSignUpForm';
import SkillsboostProgramFeatures from '../../components/skillsboost/SkillsboostProgramFeatures';
import SkillsboostHowToGetStarted from '../../components/skillsboost/SkillsboostHowToGetStarted';
import SkillsboostMedia from '../../components/skillsboost/SkillsboostMedia';
import { scrollIntoViewWithOffset } from '../../utils/scrollToViewWithOffset';

const Skillsboost = ({
	showSkillsboostSignUpForm,
	setShowSkillsboostSignUpForm
	}) => {

		useEffect(() => {
			const anchor = window.location.hash.split('#')[1];
			if(anchor) {
				scrollIntoViewWithOffset(anchor);
			}
		}, [])

	return (
		<>
			<SEO
				title="Skillsboost"
				description="Fast-track your Data Science career! Obtain industry-aligned certifications, learn from Certified Experts, and elevate your skills for success in the fast-changing data science landscape. Open doors to exciting opportunities with us!"
			/>
			<HeaderTwo styles="header-transparent header-style-2" searchDisable />
			<SkillsboostBanner setShowSkillsboostSignUpForm={setShowSkillsboostSignUpForm} />
			<SkillsboostDescription />
			<SkillsboostMedia />
			<SkillsboostProgramFeatures />
			<SkillsboostWhyChooseUs setShowSkillsboostSignUpForm={setShowSkillsboostSignUpForm} />
			<SkillsboostHowToGetStarted />
			{/* <SkillsboostFAQs /> */}
			<FooterOne />
			{showSkillsboostSignUpForm && <SkillsboostSignUpForm setShowSkillsboostSignUpForm={setShowSkillsboostSignUpForm} />}
		</>
    )
}
export default Skillsboost;