import React, { useState } from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ReturnToWorkPredictionFormSectionTest from '../../components/return-to-work-prediction/ReturnToWorkPredictionFormSectionTest';
import ModalWarningOverride from '../../components/return-to-work-prediction/ModalWarningOverride';

const ReturnToWorkPredictionTest = () => {
	const [showWarning, setShowWarning] = useState(false);
	const [acceptWarning, setAcceptWarning] = useState(false);

	return (
		<>
			<SEO
				title="Return To Work Prediction"
				description=""
			/>
			<Layout>
				<BreadcrumbOne
					title="Return To Work Prediction"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Return To Work Prediction"
				/>
				<ReturnToWorkPredictionFormSectionTest acceptWarning={acceptWarning} setShowWarning={setShowWarning} setAcceptWarning={setAcceptWarning} />
			</Layout>
			{showWarning && <ModalWarningOverride setShowWarning={setShowWarning} setAcceptWarning={setAcceptWarning} />}
		</>
    )
}
export default ReturnToWorkPredictionTest;