import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import JobseekerFormSectionTest from '../../components/jobseeker/JobseekerFormSectionTest';

const JobseekerTest = () => {

	return (
		<>
			<SEO
				title="Jobseeker Test"
				description=""
			/>
			<Layout>
				<BreadcrumbOne
					title="Jobseeker Test"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Jobseeker Test"
				/>
				<JobseekerFormSectionTest />
			</Layout>
		</>
    )
}
export default JobseekerTest;