import React from 'react';

const RepeatOffenderRateInfo = ({hideRepeatOffenderRateinfo}) => {

  return (
    <div className="risk-classification-container hidePopup" onClick={hideRepeatOffenderRateinfo} id="RepeatOffenderRatePopup">
      <div className="risk-classification-content">
        <p>Repeat Offender Rate = (Total number of Infraction Severity entities / Total number of entities) X 100.</p>
      </div>
    </div>
  )
}

export default RepeatOffenderRateInfo;