import React from 'react';

const SentimentAnalysisPopup = ({hideSentimentAnalysisPopup}) => {

  return (
    <div className="risk-classification-container hidePopup" onClick={hideSentimentAnalysisPopup} id="SentimentAnalysisPopup">
      <div className="risk-classification-content">
        <p>This stacked bar graph shows the flagged and unflagged entities with pass, fail or none inspection result.</p>
      </div>
    </div>
  )
}

export default SentimentAnalysisPopup;