import React from 'react';
import Slider from 'react-slick';
import { TestimonialOneParams } from '../../utils/script';

let data = [
    {
        name: "Pushpa Rachamadugu",
        details: "All the videos were so well-detailed. All the employer WIL projects give a good scope of work in real-time. All the industry sessions are so great for meeting different company recruiter inputs and thoughts in today's market. Ashok and Nimit are the best to keep the team engaged and focused on the training. I would like to engage more in online tutor training so that the students can engage more in discussion-based learning. Also, employer WIL projects if the employer is a little more lenient in providing their masked or their test data that we would have confidence in our developed models and work more fine-tuned models. Also, I would appreciate it if the team would have 6 months rather than 16 weeks as for working people meeting all project deadlines while learning and also working full time is stressful and can skip in some parts of learning objectives which could make us less confident to approach for the new role.",
    },
    {
        name: "Pichayoot Ouppaphan",
        details: "Coursework and Work Integrated Learning are the most valuable part of the program.",
    },
    {
        name: "Sriharsha Mushnoori",
        details: "The course material was pretty good and covered a very diverse range of topics. Gave me a good starting point to explore some of the topics in more depth on my own. The AI mastery live streams were quite helpful as a continuation of the course material. However, I wish the recordings were made available for review. Overall it was a decent experience, and I hope it translates to getting more interviews down the line.",
    },
    {
        name: "Ashley Shi",
        details: "Discussion and practice is very important. Besides, all the people are ready to help me:)",
    },
    {
        name: "Penelope Haro Aranda",
        details: "The collaboration with other participants is invaluable as I get to learn from them. The opportunity to work on a real project is the best component of this program, it gives exposure to how it is to work as a Data Analyst/Scientist and it allows me to work on something that I can add to my resume as my first work experience, which is a huge support for my career change.",
    },
    {
        name: "Sola Agagu",
        details: "My favorite part of the program is learning with like-minded individuals and watching them share the projects they worked on. The opportunity to work on real-life projects was a great learning experience to gain hands-on experience and collaborate as a team.",
    },
    {
        name: "Dami Salami",
        details: "The program's technical training is top tier and getting to work with so many motivated Data Scientists is a huge advantage. The most valuable part for me has been getting to interact with industry leaders and get their perspectives on what they look for when making hiring decisions.",
    },
    {
        name: "Farzad Khazaee",
        details: "Sessions with industry leaders was the best part for me.",
    },
    {
        name: "Lucas Ho",
        details: "The DataTalent Program has offered a multifaceted learning experience that has been invaluable in my journey toward mastering data science and analytics. One of the most valuable aspects has been the hands-on project work, where I've been able to apply theoretical knowledge to real-world problems. These projects have not only deepened my understanding of data analysis techniques but have also honed my problem-solving skills and taught me how to work effectively within a team. Additionally, the mentorship provided by seasoned professionals in the field has been instrumental in guiding me through complex concepts and providing valuable career advice. The program's emphasis on continuous learning through workshops, seminars, and access to cutting-edge tools and technologies has kept me updated with the latest trends and advancements in the field. Overall, the DataTalent Program has provided me with a comprehensive and immersive learning environment that has significantly accelerated my growth as a data professional.",
    },
    {
        name: "Claude de Villeres",
        details: "I had an exceptional experience with M2M Tech Connect's programs. As someone diving into the world of data science and AI, I found their offerings to be comprehensive and enriching. The integration of employer-led learning projects not only provided valuable hands-on experience but also connected me with like-minded passionate individuals. Throughout the program, I witnessed a significant enhancement in my confidence, particularly in analytical thinking and effectively communicating solutions to complex business problems. The supportive environment fostered by the team further facilitated my learning journey.",
    },
    {
        name: "Megha Katiyar",
        details: "The employer project in this cohort is incredibly valuable, providing a hands-on experience similar to real-world situations. Additionally, working from scratch on the employer project exposes participants to a multitude of tasks, facilitating learning across various domains and adding immense value to their profiles. This experience not only sharpens technical skills but also offers a platform to understand business use cases and comprehend the value of one's work firsthand. Moreover, the project-based course material allows participants to apply what they've learned, enhancing their understanding and setting a strong foundation for concepts. This approach goes beyond theoretical learning, preparing participants for the market with some great hands-on projects.",
    },
    {
        name: "Clement Girod",
        details: "I found the most interesting and valuable part of the program was participation in the WIL projects. As the program did not involve much \"interactive\" training related to the lectures, I found these projects were a great way to get to work on actual business problems using up to date tools and techniques. The team was also super friendly, knowledgeable, available, and helpful, which made the whole experience extremely enjoyable.",
    },
    {
        name: "Abdoul Latif Sanfo",
        details: "I have found the course curriculum very satisfying. I can use the course content to succeed in my career journey. During this program, we have gotten all the help that we needed to succeed through weekly meetings and an interactive chat forum where everyone can share their thoughts and ask for any advice necessary to succeed in the program.",
    },
    {
        name: "Damla Peker",
        details: "Even though I have experience, as a newcomer in Canada, I have faced the difficulty of finding a job. However, learning new skills, working on capstone projects, presenting them, getting involved in real company projects, and networking has been a wonderful experience. For all of these reasons, I am really grateful to the Data Talent Program",
    },
    {
        name: "Jayalakshmi Madhiolli",
        details: "It's a great program to begin with. It's been a wonderful start up after my career gap and had an opportunity to keep myself updated in the job search. The course they offered gave me immense knowledge in Data analyst as a beginner. The staff was so friendly and guided me all along my program. Double thumbs-up for their dedication.",
    },
    {
        name: "Nidhi",
        details: "The most valuable aspects of the DataTalent Program so far have been the hands-on projects, personalized mentorship, and access to industry experts, which have significantly enhanced my practical skills and professional network.",
    },
    {
        name: "Sonia Najafi",
        details: "I deeply respect and appreciate the effort of the M2M team in organizing the DataTalent Program. It has been an enriching experience for me because of the curriculum, course content, and the opportunity to work on real-world projects which has been one of the most impactful aspects of the program. The program also equipped me with soft skills that are crucial for professional success.",
    },
    {
        name: "Moyin Ajayi",
        details: "The training videos were very detailed and explanatory. The weekly meetings helped to keep track of progress. Meeting with industry experts and learning from them was great. Working on real industry projects helped me to collaborate and work with amazing teams.There was a lot of support I received which helped my interview preparation and also helped to get my desired job. I also got a chance to build my personal portfolio over time and work on personal projects that I could talk about during interviews.",
    },
];

const DataTalentTestimonial = ( props ) => {
    const classes = `testimonial-activation testimonial-style-1 edu-slick-button slick-button-left ${props.className ? props.className : '' }`;
    data = props.item ? data.slice( 0, props.item ) : data;
    return (
        <Slider 
            className={ classes }
            { ...TestimonialOneParams }
        >
            { data.map( ( item, i ) => (
                <div className="single-testimonial" key={ i }>
                    <div className="inner">
                        {/* { props.quoteIcon !== 'disable' && 
                            <div className="quote-sign">
                                <img src={`${process.env.PUBLIC_URL}/images/testimonial/testimonial-01/quote.png`} alt="Quote Images" />
                            </div>
                        } */}
                        <p className="description">“{ item.details }”</p>
                        <div className="client-information">
                            {/* <div className="thumb">
                                <img src={`${process.env.PUBLIC_URL}/images/testimonial/testimonial-01/${item.image}`} alt="Client Thumb" />
                            </div> */}
                            <div className="info">
                                <h5 className="title">{ item.name }</h5>
                                {/* <span className="designation">{ item.designation }</span> */}
                            </div>
                        </div>
                    </div>
                </div>
            ) ) }
        </Slider>
    );
}
export default DataTalentTestimonial;