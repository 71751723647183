import React from 'react';
import ChatBot from 'react-chatbotify';

const RegulatoryChatbotify = () => {
  const flow = {
    start: {
      message: "How may I help you?",
      path: "end"
    },
    end: {
      message: (params) => `Hi ${params.userInput}!`,
      chatDisabled: true
    }
  }

  // let Button = document.createElement("button");
  const settings = {
    general: {
      embedded: false
    },
    chatButton: {
      icon: "Chat",
    },
    header: {
      title: (
        <div style={{cursor: "pointer", margin: 0, fontSize: 20, fontWeight: "bold"}} onClick={
          () => window.open("https://m2mtechconnect.com/solutions/regulatory-compliance-dashboard")
        }>
          Regulatory Assistant
        </div>
      ),
      showAvatar: true,
      avatar: `${process.env.PUBLIC_URL}/M2M_Logo_v2.png`,
      // buttons: [Button.NOTIFICATION_BUTTON, Button.AUDIO_BUTTON, Button.CLOSE_CHAT_BUTTON],
    },
    chatHistory: {
      storageKey: "conversations_summary"
    }
  }

  return(
    <>
      <div className="regulatory-chatbotify">
        <ChatBot flow={flow} settings={settings} />
        {/* <ChatBot /> */}
      </div>
    </>
  )
}

export default RegulatoryChatbotify;