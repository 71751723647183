import React from 'react';
import SEO from '../../../common/SEO';
import Layout from '../../../common/Layout';
import BreadcrumbOne from '../../../common/breadcrumb/BreadcrumbOne';
import RegisterProgramForm from '../../../components/form/register-program-form/RegisterProgramForm';
// import { Link } from 'react-router-dom';

const EventData = [
	{
		"id": 1,
		"title": "Convert Chat Threads into a Vector Database",
		"image": "AIChat_db.webp",
		"website": "https://studio.differentmatters.com/",
		"industry": "Education, It & computing",
		"rating": "4.8 Google Rating",
		"duration": "12 Weeks",
		"category": "Data Science, ML & AI",
		"location": "https://studio.differentmatters.com/",
		"going": "140+",
		"company": "DifferentMatters"
	}
]

const ConvertChatThreadsIntoVectorDB = ({
	showRegisterForm,
	setShowRegisterForm,
	company
	}) => {
	const eventItem = EventData[0];
	return (
		<>
			<SEO
				title={ eventItem.title }
				description=""
			/>
			<Layout>
				<BreadcrumbOne 
					title="Convert Chat Threads into a Vector Database"
					rootUrl="/programs/work-integrated-learning"
					arentUrl="Industry Partner Work Integrated Learning"
					currentUrl="Convert Chat Threads into a Vector Database"
				/>
				<div className="edu-event-details-area edu-event-details edu-section-gap bg-color-white">
					<div className="container">
						<div className="row g-5">
							<div className="col-lg-12">
								<div className="thumbnail">
									<img src={`${process.env.PUBLIC_URL}/images/project-details/${ eventItem.image }`} alt="Event Thumb" />
								</div>
							</div>
						</div>
						<div className="row g-5">
							<div className="col-lg-7">
								<div className="content">
									<h3 className="title">{ eventItem.title }</h3>
									<p>
										<strong>What is the main goal for this project?</strong><br></br>
										The goal of this project is to apply the latest artificial intelligence (AI) and machine learning (ML) techniques to an existing dataset to create an AI/ML model that supports development of more content and user access with the platform's evolution.
									</p>
									<p>
										DifferentMatters is developing the latest methodology and application to help everyday people find a sense of belonging. In order to accelerate our pace to market, we need to create content specific to our IP so that our application then becomes a portal to unique experiences for each individual.
									</p>
									<p>
										Students will develop an AI / ML model that will create a vector database to support our development of content, and eventually lead to user-content access as our platform evolves.
									</p>
									<p>
										This will involve several different steps for the students, including:
										<ul>
											<li>
												Conducting background research on the simplest way to create our database for usability by non-technical users.
											</li>
											<li>
												Analyzing our current dataset.
											</li>
											<li>
												Researching the latest AI / ML techniques and how they could be applied to our data.
											</li>
											<li>
												Developing an AI / ML model that provides unique outcomes or insights into our data.
											</li>
											<li>
												Providing multiple solutions that can be applied to solve the same problem.
											</li>
										</ul>
									</p>
									<p>
										<strong>What tasks will learners need to complete to achieve the project goal?</strong><br></br>
										By the end of the project, students should demonstrate:
										<ul>
											<li>
												Understanding of the available dataset.
											</li>
											<li>
												Understanding of the latest AI / ML techniques.
											</li>
											<li>
												Identification of ways in which AI / ML can be applied to our company.
											</li>
										</ul>
										Final deliverables should include:
										<ul>
											<li>
												Vector database ready to use with chat threads converted to embeddings.
											</li>
											<li>
												A final report on the dataset, the problem solved, methodologies and approaches, outcomes and results, and recommended next steps.
											</li>
											<li>
												Source materials such as code and workbooks including user manual.
											</li>
										</ul>
									</p>
									<p>
										<strong>How will you support learners in completing the project?</strong><br></br>
										Students will connect directly on a weekly basis with us for mentorship throughout the project. We will be able to provide answers to questions such as:
										<ul>
											<li>
												Our current products and applications of AI / ML.
											</li>
											<li>
												The current data set and guidance in navigating it.
											</li>
											<li>
												How our business intends to evolve using our Vector Database.
											</li>
											<li>
												Input on choices, problems or anything else the students might encounter.
											</li>
										</ul>
									</p>
								</div>
							</div>
							<div className="col-lg-5">
								<div className="eduvibe-sidebar">
									<div className="eduvibe-widget eduvibe-widget-details">
										<h5 className="title">ABOUT INDUSTRY PARTNER</h5>
										<div className="widget-content">
											<ul>
												{/* <li><span><i className="icon-Campus"></i> { eventItem.company }</span></li> */}
												<li><span><i className="icon-Campus"></i> <a href={eventItem.location}>{ eventItem.company }</a></span></li>
												<li><span><i className="icon-presentation"></i> { eventItem.category }</span></li>
												{/* <li><span><i className="icon-map-pin-line"></i> {company ? <a href={eventItem.location}>Website</a> : eventItem.location}</span></li> */}
												<li>
													Blending human behaviour and psychology within their model, DifferentMatters strives to ensure people have the space to take care of their well-being within their work environments. DifferentMatters uses instructional training, executive coaching, accessible technology, and various tools to reduce stress-encouraging the freedom to connect with others and build confidence.
												</li>
											</ul>
											{!company && <div className="read-more-btn mt--45">
												<a className="edu-btn btn-secondary text-center" href="https://form.jotform.com/m2mtech/wil-programs-sign-up">Register Now<i className="icon-arrow-right-line-right"></i></a>
											</div>}
										</div>
									</div>
								</div>
							</div>
							{!company && <div className="col-lg-12">
								<div className="read-more-btn text-center">
									<a className="edu-btn btn-secondary text-center" href="https://form.jotform.com/m2mtech/wil-programs-sign-up">Register Now<i className="icon-arrow-right-line-right"></i></a>
								</div>
							</div>}
						</div>
					</div>
				</div>
			</Layout>
			{showRegisterForm && <RegisterProgramForm setShowRegisterForm={setShowRegisterForm} />}
		</>
	)
}
export default ConvertChatThreadsIntoVectorDB;