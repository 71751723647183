import React, { useEffect, useState } from 'react';
import { Element } from 'react-scroll';
import RegulatoryDashboardSectionTitle from './RegulatoryDashboardSectionTitle';
// import { Bar as BarChart2 } from "react-chartjs-2";
import { Chart as ChartJS, 
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend } from "chart.js";
import { DataTable } from 'primereact/datatable';
import { Chart } from 'primereact/chart';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { ProductServices } from './ProductServices';
import { FilterMatchMode } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
// import Select from 'react-select';
import { toast } from 'react-toastify';
// import { scrollIntoViewWithOffset } from '../../utils/scrollToViewWithOffset';
import Checkbox from '../../common/Checkbox';
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as TooltipRecharts,
  Legend as LegendRecharts,
  BarChart,
  LabelList,
  ResponsiveContainer,
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis
} from 'recharts';

import RiskCalculation from './RiskCalculation';
import Top5HighRiskTable from './Top5HighRiskTable';
import ComplianceRateInfo from './ComplianceRateInfo';
import RepeatOffenderRateInfo from './RepeatOffenderRateInfo';
import InspectionFailureRateInfo from './InspectionFailureRateInfo';
import RiskTrendInfo from './RiskTrendInfo';
import FeatureImportanceInfo from './FeatureImportanceInfo';
import SentimentAnalysisPopup from './SentimentAnalysisPopup';
import Select from 'react-select';

// const radarChart = [
//   {
//     subject: 'Infraction Type',
//     A: 1,
//     B: 1,
//     fullMark: 18,
//   },
//   {
//     subject: 'Annual Clients',
//     A: 9,
//     B: 1,
//     fullMark: 18,
//   },
//   {
//     subject: 'Inspection Result',
//     A: 6,
//     B: 5,
//     fullMark: 18,
//   },
//   {
//     subject: 'Sentiment Analysis',
//     A: 3,
//     B: 3,
//     fullMark: 18,
//   },
//   {
//     subject: 'Public Complaints',
//     A: 5,
//     B: 9,
//     fullMark: 18,
//   },
//   {
//     subject: 'Infraction Timeline',
//     A: 5,
//     B: 5,
//     fullMark: 18,
//   },
// ];

ChartJS.register(CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend);

const RegulatoryDashboard = ({
  setShowAddEntityForm,
  setShowAddReviewToEntityForm,
  setEntityName,
  formSubmittedSuccessfully,
  setFormSubmittedSuccessfully,
  addReviewToEntityFormSubmittedSuccessfully,
  setAddReviewToEntityFormSubmittedSuccessfully,
}) => {
  const [totalEntities, setTotalEntities] = useState();
  const [highRiskEntities, setHighRiskEntities] = useState();
  const [mediumRiskEntities, setMediumRiskEntities] = useState();
  const [lowRiskEntities, setLowRiskEntities] = useState();
  const [allEntities, setAllEntities] = useState([]);
  const [filteredEntities, setFilteredEntities] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const [topHighRiskEntities, setTopHighRiskEntities] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    score: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [filterCount, setFilterCount] = useState();
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [complianceRate, setComplianceRate] = useState();
  const [repeatOffenderCount, setRepeatOffenderCount] = useState();
  const [inspectionFailureRate, setInspectionFailureRate] = useState();
  const [riskTrendImg, setRiskTrendImg] = useState("All");
  const [loadingFeatureImportance, setLoadingFeatureImportance] = useState(false);
  const [featureImportance, setFeatureImportance] = useState([]);
  const [stackedBarData, setStackedBarData] = useState([]);
  const [annualClients, setAnnualClients] = useState();
  const [infractionSeverity, setInfractionSeverity] = useState();
  const [quarterComplaintSeverity, setQuarterComplaintSeverity] = useState();
  const [quarterSentimentAnalysis, setQuarterSentimentAnalysis] = useState();
  const [inspectionResult, setInspectionResult] = useState();
  const [showRiskAnalyzerForm, setShowRiskAnalyzerForm] = useState(true);
  const [showRiskAnalyzerResult, setShowRiskAnalyzerResult] = useState(false);
  const [radarOptions, setRadarOptions] = useState([]);
  const [radarOptionsSelected, setRadarOptionsSelected] = useState(0);
  const [defaultRadarOptions, setDefaultRadarOptions] = useState([]);
  const [radarChart, setRadarChart] = useState([
    {
      subject: 'Infraction Type',
      fullMark: 3,
    },
    {
      subject: 'Annual Clients',
      fullMark: 3,
    },
    {
      subject: 'Inspection Result',
      fullMark: 3,
    },
    {
      subject: 'Sentiment Analysis',
      fullMark: 3,
    },
    {
      subject: 'Public Complaints',
      fullMark: 3,
    },
    {
      subject: 'Infraction Timeline',
      fullMark: 3,
    },
  ]);

  const infractionSeverityOptions = [
    {
      label: "none",
      value: "none"
    },
    {
      label: "minor",
      value: "minor"
    },
    {
      label: "major",
      value: "major"
    },
  ]

  const quarterComplaintSeverityOptions = [
    {
      label: "none",
      value: "none"
    },
    {
      label: "minor",
      value: "minor"
    },
    {
      label: "major",
      value: "major"
    },
  ]

  const quarterSentimentAnalysisOptions = [
    {
      label: "none",
      value: "none"
    },
    {
      label: "flagged",
      value: "flagged"
    },
  ]

  const inspectionResultOptions = [
    {
      label: "none",
      value: "none"
    },
    {
      label: "pass",
      value: "pass"
    },
    {
      label: "fail",
      value: "fail"
    },
  ]

  const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

  const [applyFilters, setApplyFilters] = useState({
    riskClassification: new Set(),
    infractionSeverity: new Set(),
    complaintSeverity: new Set(),
    sentimentAnalysis: new Set(),
    inspectionResult: new Set(),
  })

  const attributes = {
    riskClassification: ["High", "Medium", "Low"],
    infractionSeverity: ["major", "minor", "none"],
    complaintSeverity: ["major", "minor", "none"],
    sentimentAnalysis: ["flagged", "none"],
    inspectionResult: ["fail", "pass", "none"]
  }

  const [loading, setLoading] = useState(false);
  const [expandedRows, setExpandedRows] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const getEntitiesAPI = process.env.REACT_APP_GET_ENTITIES;
  const getEntityAPI = process.env.REACT_APP_GET_ENTITY;
  const getFeatureImportanceAPI = process.env.REACT_APP_GET_FEATURE_IMPORTANCE;

  const [classifications] = useState(['High', 'Medium', 'Low']);

  const handleCheckboxChange = (attribute, value) => {
    if(value !== "Total") {
      setApplyFilters(prevFilters => {
        const updatedCategory = new Set(prevFilters[attribute]);
        if (updatedCategory.has(value)) {
          updatedCategory.delete(value);
        } else {
          updatedCategory.add(value);
        }
        return {
          ...prevFilters,
          [attribute]: updatedCategory
        };
      });
    }
    
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      scales: {
        x: {
          grid: {
            display: false,
            drawBorder: false
          }
        }
      }
    };
    
    let data;
    if(value === "High") {
      document.getElementById("highRiskEntitiesDistribution").classList.add("distribution-entities-active");
      document.getElementById("mediumRiskEntitiesDistribution").classList.remove("distribution-entities-active");
      document.getElementById("lowRiskEntitiesDistribution").classList.remove("distribution-entities-active");
      setRiskTrendImg("High");
      data = {
        labels: [''],
        datasets: [
          {
            label: 'High',
            backgroundColor: "#FF0003",
            borderColor: "#FF0003",
            data: [parseInt(highRiskEntities)]
          },
          {
            label: 'Medium',
            backgroundColor: "#FFDDC5",
            borderColor: "#FFDDC5",
            data: [parseInt(mediumRiskEntities)]
          },
          {
            label: 'Low',
            backgroundColor: "#C5E9CF",
            borderColor: "#C5E9CF",
            data: [parseInt(lowRiskEntities)]
          }
        ]
      };
    } else if(value === "Medium") {
      document.getElementById("highRiskEntitiesDistribution").classList.remove("distribution-entities-active");
      document.getElementById("mediumRiskEntitiesDistribution").classList.add("distribution-entities-active");
      document.getElementById("lowRiskEntitiesDistribution").classList.remove("distribution-entities-active");
      setRiskTrendImg("Medium");
      data = {
        labels: [''],
        datasets: [
          {
            label: 'High',
            backgroundColor: "#FFB3B3",
            borderColor: "#FFB3B3",
            data: [parseInt(highRiskEntities)]
          },
          {
            label: 'Medium',
            backgroundColor: "#FF8F3C",
            borderColor: "#FF8F3C",
            data: [parseInt(mediumRiskEntities)]
          },
          {
            label: 'Low',
            backgroundColor: "#C5E9CF",
            borderColor: "#C5E9CF",
            data: [parseInt(lowRiskEntities)]
          }
        ]
      };
    } else if(value === "Low") {
      document.getElementById("highRiskEntitiesDistribution").classList.remove("distribution-entities-active");
      document.getElementById("mediumRiskEntitiesDistribution").classList.remove("distribution-entities-active");
      document.getElementById("lowRiskEntitiesDistribution").classList.add("distribution-entities-active");
      setRiskTrendImg("Low");
      data = {
        labels: [''],
        datasets: [
          {
            label: 'High',
            backgroundColor: "#FFB3B3",
            borderColor: "#FFB3B3",
            data: [parseInt(highRiskEntities)]
          },
          {
            label: 'Medium',
            backgroundColor: "#FFDDC5",
            borderColor: "#FFDDC5",
            data: [parseInt(mediumRiskEntities)]
          },
          {
            label: 'Low',
            backgroundColor: "#3EB75E",
            borderColor: "#3EB75E",
            data: [parseInt(lowRiskEntities)]
          }
        ]
      };
    } else {      
      document.getElementById("highRiskEntitiesDistribution").classList.remove("distribution-entities-active");
      document.getElementById("mediumRiskEntitiesDistribution").classList.remove("distribution-entities-active");
      document.getElementById("lowRiskEntitiesDistribution").classList.remove("distribution-entities-active");
      setRiskTrendImg("All");
      data = {
        labels: [''],
        datasets: [
          {
            label: 'High',
            backgroundColor: "#FF0003",
            borderColor: "#FF0003",
            data: [parseInt(highRiskEntities)]
          },
          {
            label: 'Medium',
            backgroundColor: "#FF8F3C",
            borderColor: "#FF8F3C",
            data: [parseInt(mediumRiskEntities)]
          },
          {
            label: 'Low',
            backgroundColor: "#3EB75E",
            borderColor: "#3EB75E",
            data: [parseInt(lowRiskEntities)]
          }
        ]
      };
    }
    setChartData(data);
    setChartOptions(options);
  }

  const resetFilters = () => {
    setApplyFilters({
      riskClassification: new Set(),
      infractionSeverity: new Set(),
      complaintSeverity: new Set(),
      sentimentAnalysis: new Set(),
      inspectionResult: new Set(),
    });
  }

  const filterAppliedEntities = allEntities.filter(item => {
    const review = item.risk.recent_review;
    return (
      (applyFilters.riskClassification.size === 0 || applyFilters.riskClassification.has(item.risk.riskClassification)) &&
      (applyFilters.infractionSeverity.size === 0 || applyFilters.infractionSeverity.has(review?.infraction_severity)) &&
      (applyFilters.complaintSeverity.size === 0 || applyFilters.complaintSeverity.has(review?.quarter_complaint_severity)) &&
      (applyFilters.sentimentAnalysis.size === 0 || applyFilters.sentimentAnalysis.has(review?.quarter_sentiment_analysis)) &&
      (applyFilters.inspectionResult.size === 0 || applyFilters.inspectionResult.has(review?.inspection_result))
    );
  });

  const handleFilterEntityFormSubmit = (e) => {
    e.preventDefault();
    if(filterAppliedEntities.length === allEntities.length) {
      setFilterApplied(false);
      document.getElementById("FilterCounter").classList.add("hidePopup");
    } else {
      setFilterApplied(true);
      setFilteredEntities(filterAppliedEntities);
      document.getElementById("FilterCounter").classList.remove("hidePopup");
      // console.log(applyFilters);
      setFilterCount(applyFilters.complaintSeverity.size + applyFilters.infractionSeverity.size + applyFilters.inspectionResult.size + applyFilters.riskClassification.size + applyFilters.sentimentAnalysis.size);
    }
  }

  const clearFilters = () => {
    resetFilters();
    setFilterApplied(false);
    handleCheckboxChange("riskClassification", "Total");
    document.getElementById("FilterCounter").classList.add("hidePopup");
  }

  // const labels = ['High', 'Medium', 'Low'];

  // const options = {
  //   indexAxis: 'x',
  //   scales: {
  //     y: {
  //       beginAtZero: true
  //     }
  //   },
  //   elements: {
  //     bar: {
  //       borderWidth: 0,
  //     },
  //   },
  //   responsive: true,
  //   plugins: {
  //     title: {
  //       display: true,
  //       text: 'Risk Distribution'
  //     },
  //   },
  // };

  // const data = {
  //   labels: labels,
  //   datasets: [
  //     {
  //       label: "High Risk",
  //       barPercentage: 3,
  //       barThickenss: 90,
  //       backgroundColor: ["#FF0003"],
  //       borderColor: ["#FF0003"],
  //       data: [highRiskEntities, 0, 0],
  //     },
  //     {
  //       label: "Medium Risk",
  //       barPercentage: 2.5,
  //       barThickenss: 30,
  //       backgroundColor: ["#FF8F3C"],
  //       borderColor: ["#FF8F3C"],
  //       data: [0, mediumRiskEntities, 0],
  //     },
  //     {
  //       label: "Low Risk",
  //       barPercentage: 3,
  //       barThickenss: 30,
  //       backgroundColor: ["#3EB75E"],
  //       borderColor: ["#3EB75E"],
  //       data: [0, 0, lowRiskEntities],
  //     }
  //   ]
  // };

  const handleRefreshRiskScoreClick = (entityName, entityID) => {
    const requestOptions = {
      method: "GET"
    }
    setRefresh(true);
    fetch(`${getEntityAPI}/${entityName}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (!filterApplied) {
          const updatedEntities = allEntities.map(entity => {
            if (entity.id === entityID) {
              return result;
            } else {
              return entity;
            }
          });
          // console.log("updated Entities", updatedEntities);
          setAllEntities([...updatedEntities]);
        } else {
          const updatedEntities = allEntities.map(entity => {
            if (entity.id === entityID) {
              return result;
            } else {
              return entity;
            }
          });
          // console.log("updated Entities", updatedEntities);
          setAllEntities([...updatedEntities]);
          const updatedFilteredEntities = filteredEntities.map(entity => {
            if (entity.id === entityID) {
              return result;
            } else {
              return entity;
            }
          });
          // console.log("updated Entities", updatedEntities);
          setFilteredEntities([...updatedFilteredEntities]);
        }
        // setTimeout(() => {
          setRefresh(false);
        // }, 3000);
        toast.success("Risk score refreshed!");
      })
      .catch((error) => {
        // setTimeout(() => {
          setRefresh(false);
        // }, 3000);
        toast.error("Failed to refresh Risk score. Try again!");
        return console.error(error);
      });
    
  }

  const allowExpansion = (rowData) => {
    return 1;
  };

  const rowExpansionTemplate = (data) => {
    // console.log(data);
    if(!refresh) {
      return (
        <div className="entity-row-expansion row g-5 p-3 mt-0">
          <div className="col-lg-12 mt-0">
            <h5>Details</h5>
          </div>
          <div className="col-lg-6 mt-1">
            <p><strong>Name:</strong>&nbsp;&nbsp;{data.name}</p>
            <p><strong>Entity ID:</strong>&nbsp;&nbsp;{data.id}</p>
            <p><strong>Infraction History:</strong>&nbsp;&nbsp;{data.risk.infraction_history.replace("to", " to ")}</p>
          </div>
          <div className="col-lg-6 mt-1">
            <p><strong>Risk Score:</strong>&nbsp;&nbsp;{data.risk.score}</p>
            <div>
              <p className="d-inline-block"><strong>Risk Category:</strong> </p>&nbsp;&nbsp;&nbsp;
              {(data.risk.score > 13 && data.risk.score <= 18) ?
                <div className="risk-classification-wrapper d-inline-block" value="High" severity="High">
                  <p className="risk-classification-high">High</p>
                </div>
              : (data.risk.score >= 7 && data.risk.score <= 13) ?
                <div className="risk-classification-wrapper d-inline-block" value="Medium" severity="Medium">
                  <p className="risk-classification-medium">Medium</p>
                </div>
              : (data.risk.score < 7 && data.risk.score >= 0) ?
                <div className="risk-classification-wrapper d-inline-block" value="Low" severity="Low">
                  <p className="risk-classification-low">Low</p>
                </div>
              : <div className="risk-classification-wrapper d-inline-block" value="NA" severity="NA">
                  <p className="risk-classification-NA">N/A</p>
                </div>
              }
            </div>
          </div>
          <div className="col-lg-12 mt--20">
            <h5>Recent Review</h5>
          </div>
          {data.risk.recent_review ? <>
            <div className="col-lg-6 mt-1">
              <p><strong>Review Date:</strong>&nbsp;&nbsp;{new Date(data.risk.recent_review.date).toLocaleDateString('en-us', {
                weekday: "short",
                year: "numeric",
                month: "short",
                day: "numeric"})}</p>
              <p><strong>Inspection Result:</strong>&nbsp;&nbsp;{data.risk.recent_review.inspection_result.charAt(0).toUpperCase() + data.risk.recent_review.inspection_result.slice(1)}</p>
              <p><strong>Infraction Severity:</strong>&nbsp;&nbsp;{data.risk.recent_review.infraction_severity.charAt(0).toUpperCase() + data.risk.recent_review.infraction_severity.slice(1)}</p>
            </div>
            <div className="col-lg-6 mt-1">
              <p><strong>Quarter Complaint Severity:</strong>&nbsp;&nbsp;{data.risk.recent_review.quarter_complaint_severity.charAt(0).toUpperCase() + data.risk.recent_review.quarter_complaint_severity.slice(1)}</p>
              <p><strong>Quarter Sentiment Analysis:</strong>&nbsp;&nbsp;{data.risk.recent_review.quarter_sentiment_analysis.charAt(0).toUpperCase() + data.risk.recent_review.quarter_sentiment_analysis.slice(1)}</p>
              <p><strong>Annual Clients:</strong>&nbsp;&nbsp;{data.risk.recent_review.annual_clients}</p>
            </div>
          </> : <p className="mt-0">No recent review.</p>}
          <div className="col-lg-12 mt--20">
            <h5>Review History</h5>
          </div>
          <div className="col-lg-6 mt-1">
            {data.risk.reviews.length > 0 ? <ol className="mb-0">
              {data.risk.reviews.map((review, idx) => <li key={idx}><strong>{new Date(review.date).toLocaleDateString('en-us', {
              year: "numeric",
              month: "short",
              day: "numeric"})}</strong></li>)}
            </ol> : <p className="mt-0">No review.</p>}
          </div>
          <div className="col-lg-6 mt-1">
            <button style={{fontSize: "14px"}} className="edu-btn btn-small btn-bg-alt lh-1" onClick={() => handleRefreshRiskScoreClick(data.name, data.id)}>🔄 Refresh Risk Score</button><br></br>
            <button style={{fontSize: "14px"}} className="edu-btn btn-small btn-secondary lh-1 mt-3" onClick={() => {setShowAddReviewToEntityForm(true); setEntityName(data.name);}}>+ Add New Report</button>
          </div>
        </div>
      )
    } else {
      return <div className="mt-4">Refreshing data...<br></br><img src={`${process.env.PUBLIC_URL}/images/loading.gif`} alt="illustration" /></div>;
    }
  };
  
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  
  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-end">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" style={{fontFamily: "primeicons"}} />
          <InputText style={{paddingLeft: "2.5rem"}} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
        </IconField>
      </div>
    );
  };

  const header = renderHeader();

  const formatDate = (value) => {
    return new Date(value).toLocaleDateString('en-us', {
      year: "numeric",
      month: "short",
      day: "numeric"});
  };

  const riskClassificationTemplate = (rowData) => {
    if(rowData.risk.score > 13 && rowData.risk.score <= 18) {
      return (
        <div className="risk-classification-wrapper" value="High" severity="High">
          <p className="risk-classification-high">High</p>
        </div>
      )
    } else if(rowData.risk.score >= 7 && rowData.risk.score <= 13) {
      return (
        <div className="risk-classification-wrapper" value="Medium" severity="Medium">
          <p className="risk-classification-medium">Medium</p>
        </div>
      )
    } else if(rowData.risk.score < 7 && rowData.risk.score >= 0) {
      return (
        <div className="risk-classification-wrapper" value="Low" severity="Low">
          <p className="risk-classification-low">Low</p>
        </div>
      )
    } else {
      return (
        <div className="risk-classification-wrapper" value="NA" severity="NA">
          <p className="risk-classification-NA">N/A</p>
        </div>
      )
    };
  };
  
  const dateBodyTemplate = (rowData) => {
    return rowData.risk.recent_review ? formatDate(rowData.risk.recent_review.date) : "";
  };

  const riskClassificationItemTemplate = (option) => {
    if(option === "High") {
      return (
        <div className="risk-classification-wrapper" value="High" severity="high">
          <p className="risk-classification-high">High</p>
        </div>
      )
    } else if(option === "Medium") {
      return (
        <div className="risk-classification-wrapper" value="Medium" severity="medium">
          <p className="risk-classification-medium">Medium</p>
        </div>
      )
    } else if(option === "Low") {
      return (
        <div className="risk-classification-wrapper" value="low" severity="low">
          <p className="risk-classification-low">Low</p>
        </div>
      )
    } else {
      return (
        <div className="risk-classification-wrapper" value="NA" severity="NA">
          <p className="risk-classification-NA">N/A</p>
        </div>
      )
    };
  };

  const infractionHistoryTemplate = (rowData) => {
    // console.log(rowData);
    return (
      <p className="mb-0">{rowData.risk.infraction_history.replace("to", " to ").toString()}</p>
    )
  }

  const riskClassificationFilterTemplate = (options) => {
    // console.log(options);
    return (
      <Dropdown value={options.value} options={classifications} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={riskClassificationItemTemplate} placeholder="Select One" className="p-column-filter" showClear />
    );
  };

  // const handleRiskClassification = (obj) => {
  //   // handleChangeClassification(obj);
  //   scrollIntoViewWithOffset("RegulatoryComplianceTable");
  // };

  const onRowSelect = (e) => {
    // console.log("Selected: ", e.data);
    setFilteredEntities(filteredEntities => [e.data]);
    setFilterApplied(filterApplied => true);
    const anchorEl = document.getElementById("RegulatedEntities");
    anchorEl.scrollIntoView({behavior: 'smooth'});
  }

  const showComplianceRateInfo = () => {
    document.getElementById("ComplianceRatePopup").classList.remove("hidePopup");
  }

  const hideComplianceRateInfo = () => {
    document.getElementById("ComplianceRatePopup").classList.add("hidePopup");
  }

  const showRepeatOffenderRateInfo = () => {
    document.getElementById("RepeatOffenderRatePopup").classList.remove("hidePopup");
  }

  const hideRepeatOffenderRateinfo = () => {
    document.getElementById("RepeatOffenderRatePopup").classList.add("hidePopup");
  }
  
  const showInspectionFailureRateInfo = () => {
    document.getElementById("InspectionFailureRatePopup").classList.remove("hidePopup");
  }

  const hideInspectionFailureRateinfo = () => {
    document.getElementById("InspectionFailureRatePopup").classList.add("hidePopup");
  }

  const showRiskCalculationPopup = () => {
    document.getElementById("RiskCalculationPopup").classList.remove("hidePopup");
  }

  const hideRiskCalculationInfo = () => {
    document.getElementById("RiskCalculationPopup").classList.add("hidePopup");
  }

  const showTop5HighPopup = () => {
    document.getElementById("Top5HighRiskPopup").classList.remove("hidePopup");
  }
  
  const hideTop5HighPopup = () => {
    document.getElementById("Top5HighRiskPopup").classList.add("hidePopup");
  }

  const showRiskTrendPopup = () => {
    document.getElementById("RiskTrendPopup").classList.remove("hidePopup");
  }
  
  const hideRiskTrendPopup = () => {
    document.getElementById("RiskTrendPopup").classList.add("hidePopup");
  }

  const showFeatureImportancePopup = () => {
    document.getElementById("FeatureImportancePopup").classList.remove("hidePopup");
  }
  
  const hideFeatureImportancePopup = () => {
    document.getElementById("FeatureImportancePopup").classList.add("hidePopup");
  }

  const showSentimentAnalysisPopup = () => {
    document.getElementById("SentimentAnalysisPopup").classList.remove("hidePopup");
  }
  
  const hideSentimentAnalysisPopup = () => {
    document.getElementById("SentimentAnalysisPopup").classList.add("hidePopup");
  }

  const handleRiskAnalyzerForm = () => {
    setShowRiskAnalyzerForm(false);
    setShowRiskAnalyzerResult(true);
  }

  const handleAnnualClientChange = () => {

  }

  const handleInfractionSeveritySelect = () => {

  }

  const handleQuarterComplaintSeveritySelect = () => {

  }

  const handleQuarterSentimentAnalysisSelect = () => {

  }

  const handleInspectionResultSelect = () => {

  }

  const handleRadarOptionsSelect = (selected) => {
    let A = Array.from(Array(selected.length), (x, i) => { return `Entity${i + 1}`});
    let template = [
      {
        subject: 'Infraction Type',
        fullMark: 3,
      },
      {
        subject: 'Annual Clients',
        fullMark: 3,
      },
      {
        subject: 'Inspection Result',
        fullMark: 3,
      },
      {
        subject: 'Sentiment Analysis',
        fullMark: 3,
      },
      {
        subject: 'Public Complaints',
        fullMark: 3,
      },
      {
        subject: 'Infraction Timeline',
        fullMark: 3,
      },
    ];

    for(let i = 0; i < selected.length; i++) {
      template[0][A[i]] = selected[i].value.risk.recent_review?.infraction_severity === "none" ? 1 : selected[i].value.risk.recent_review?.infraction_severity === "minor" ? 2 : 3;
      template[1][A[i]] = selected[i].value.risk.recent_review?.annual_clients < 200 ? 1 : selected[i].value.risk.recent_review?.annual_clients >= 500 ? 3 : 2;
      template[2][A[i]] = selected[i].value.risk.recent_review?.inspection_result === "pass" ? 1 : selected[i].value.risk.recent_review?.inspection_result === "fail" ? 2 : 0;
      template[3][A[i]] = selected[i].value.risk.recent_review?.quarter_sentiment_analysis === "flagged" ? 2 : 1;
      template[4][A[i]] = selected[i].value.risk.recent_review?.quarter_complaint_severity === "none" ? 0 : selected[i].value.risk.recent_review?.quarter_complaint_severity === "minor" ? 1 : 2;
      template[5][A[i]] = selected[i].value.risk.infraction_history === "1to3yr" ? 3 : selected[i].value.risk.infraction_history === "1yr" ? 2 : 0;
    }
    // console.log(template);
    setRadarOptionsSelected(selected.length);
    setRadarChart(template);
  }

  useEffect(() => {
    // ProductServices.getProducts().then(data => {
    //   const temp = data.entities.map((entity) => {
    //     if(entity.risk.score < 7) {
    //       return ({ ...entity, risk: { ...entity.risk, riskClassification : "Low" }});
    //     } else if(entity.risk.score >= 7 && entity.risk.score <= 12) {
    //       return ({ ...entity, risk: { ...entity.risk, riskClassification : "Medium" }});
    //     } else if(entity.risk.score > 12) {
    //       return ({ ...entity, risk: { ...entity.risk, riskClassification : "High" }});
    //     } else {
    //       return ({ ...entity, risk: { ...entity.risk, riskClassification : "N/A" }});
    //     }
    //   });
    //   setAllEntities(temp);
    //   let radarGraphValues = temp.map((entity) => {return {value : {...entity}, label : entity.name}});
    //   setRadarOptions(radarGraphValues);
    //   setTotalEntities(data.size);
    //   let highRiskEntities = temp;
    //   highRiskEntities.sort((a, b) => a.risk.score - b.risk.score);
    //   setTopHighRiskEntities(highRiskEntities.slice(Math.max(highRiskEntities.length - 5, 0)).reverse());

    //   let inspectionFailedFlagged = 0;
    //   let inspectionNoneFlagged = 0;
    //   let inspectionPassFlagged = 0;
    //   let inspectionFailedNone = 0;
    //   let inspectionNoneNone = 0;
    //   let inspectionPassNone = 0;

    //   // round((df_selection[df_selection["Inspection Results"] == 'Pass'].shape[0] / df_selection["Entity ID"].count() * 100), 2)
    //   let inspectionPassEntities = 0;
    //   temp.forEach((entity) => {if(entity.risk.recent_review?.inspection_result === "pass") inspectionPassEntities += 1});
    //   let compliance_Rate = ((inspectionPassEntities / data.size) * 100).toFixed(2);
    //   setComplianceRate(compliance_Rate);

    //   // round(((df_selection["Infraction Type"] != 'None').sum() / total_entities) * 100, 2)
    //   let repeatOffenderCount = 0;
    //   temp.forEach((entity) => {if(entity.risk.recent_review?.infraction_severity !== "none") repeatOffenderCount += 1});
    //   let repeatOffender_rate = ((repeatOffenderCount / data.size) * 100).toFixed(2);
    //   setRepeatOffenderCount(repeatOffender_rate);

    //   // round(((df_selection['Inspection Results'] == 'Fail').sum() / total_count) * 100, 2)
    //   let failureCount = 0;
    //   temp.forEach((entity) => {if(entity.risk.recent_review?.inspection_result === "fail") failureCount += 1});
    //   let inspectionFailure_rate = ((failureCount / data.size) * 100).toFixed(2);
    //   setInspectionFailureRate(inspectionFailure_rate);

    //   temp.forEach((entity) => {
    //     if(entity.risk.recent_review?.quarter_sentiment_analysis === "flagged") {
    //       inspectionFailedFlagged = entity.risk.recent_review?.inspection_result === "fail" && inspectionFailedFlagged + 1;
    //       inspectionNoneFlagged = entity.risk.recent_review?.inspection_result === "none" && inspectionNoneFlagged + 1;
    //       inspectionPassFlagged = entity.risk.recent_review?.inspection_result === "pass" && inspectionPassFlagged + 1;
    //     } else if(entity.risk.recent_review?.quarter_sentiment_analysis === "none") {
    //       inspectionFailedNone = entity.risk.recent_review?.inspection_result === "fail" && inspectionFailedNone + 1;
    //       inspectionNoneNone = entity.risk.recent_review?.inspection_result === "none" && inspectionNoneNone + 1;
    //       inspectionPassNone = entity.risk.recent_review?.inspection_result === "pass" && inspectionPassNone + 1;
    //     }
    //   });
    //   setStackedBarData([
    //     {
    //       name: 'Flagged',
    //       Fail: inspectionFailedFlagged,
    //       None: inspectionNoneFlagged,
    //       Pass: inspectionPassFlagged,
    //     },
    //     {
    //       name: 'None',
    //       Fail: inspectionFailedNone,
    //       None: inspectionNoneNone,
    //       Pass: inspectionPassNone,
    //     },
    //   ]);
    //   // Radar Chart values
    //   // Complaint Severity = None : 0
    //   // or Complaint Severity = Minor or Inspection Result = Pass or Sentiment Analysis = None or Infraction severity = None : 1
    //   // Complaint Severity = Major or Inspection Result = Fail or Infraction History = 1yr or Sentiment Analysis = Flagged or Infraction severity = Minor : 2
    //   // Infraction History = 1to3yr or Infraction Severity = Major or Complaint Severity = Major : 3
    //   // Annual Clients < 200 : 1
    //   // Annual Clients >= 200 && <= 500 : 2
    //   // Annual Clients >= 500 : 3
    // });
    setLoading(true);
    fetch(getEntitiesAPI, {
      method: "GET",      
    }).then((res) => {
      return res.json();
    }).then((data) => {
      const temp = data.entities.map((entity) => {
        if(entity.risk.score < 7) {
          return ({ ...entity, risk: { ...entity.risk, riskClassification : "Low" }});
        } else if(entity.risk.score >= 7 && entity.risk.score <= 12) {
          return ({ ...entity, risk: { ...entity.risk, riskClassification : "Medium" }});
        } else if(entity.risk.score > 12) {
          return ({ ...entity, risk: { ...entity.risk, riskClassification : "High" }});
        } else {
          return ({ ...entity, risk: { ...entity.risk, riskClassification : "N/A" }});
        }
      })
      console.log(temp);
      setAllEntities(temp);
      let radarGraphValues = temp.map((entity) => {return {value : {...entity}, label : entity.name}});
      setRadarOptions(radarGraphValues);
      setTotalEntities(data.size);
      setLoading(false);
      let highRiskEntities = temp;
      highRiskEntities.sort((a, b) => a.risk.score - b.risk.score);
      setTopHighRiskEntities(highRiskEntities.slice(Math.max(highRiskEntities.length - 5, 0)).reverse());

      // (df_selection[df_selection["Inspection Results"] == 'Pass'].shape[0] / df_selection["Entity ID"].count() * 100)
      let inspectionPassEntities = 0;
      temp.forEach((entity) => {if(entity.risk.recent_review?.inspection_result === "pass") inspectionPassEntities += 1});
      let compliance_Rate = ((inspectionPassEntities / data.size) * 100).toFixed(2);
      setComplianceRate(compliance_Rate);

      // round(((df_selection["Infraction Type"] != 'None').sum() / total_entities) * 100, 2)
      let repeatOffenderCount = 0;
      temp.forEach((entity) => {if(entity.risk.recent_review?.infraction_severity !== "none") repeatOffenderCount += 1});
      let repeatOffender_rate = ((repeatOffenderCount / data.size) * 100).toFixed(2);
      setRepeatOffenderCount(repeatOffender_rate);

      // round(((df_selection['Inspection Results'] == 'Fail').sum() / total_count) * 100, 2)
      let failureCount = 0;
      temp.forEach((entity) => {if(entity.risk.recent_review?.inspection_result === "fail") failureCount += 1});
      let inspectionFailure_rate = ((failureCount / data.size) * 100).toFixed(2);
      setInspectionFailureRate(inspectionFailure_rate);

      let inspectionFailedFlagged = 0;
      let inspectionNoneFlagged = 0;
      let inspectionPassFlagged = 0;
      let inspectionFailedNone = 0;
      let inspectionNoneNone = 0;
      let inspectionPassNone = 0;
      temp.forEach((entity) => {
        if(entity.risk.recent_review?.quarter_sentiment_analysis === "flagged") {
          inspectionFailedFlagged = entity.risk.recent_review?.inspection_result === "fail" && inspectionFailedFlagged + 1;
          inspectionNoneFlagged = entity.risk.recent_review?.inspection_result === "none" && inspectionNoneFlagged + 1;
          inspectionPassFlagged = entity.risk.recent_review?.inspection_result === "pass" && inspectionPassFlagged + 1;
        } else if(entity.risk.recent_review?.quarter_sentiment_analysis === "none") {
          inspectionFailedNone = entity.risk.recent_review?.inspection_result === "fail" && inspectionFailedNone + 1;
          inspectionNoneNone = entity.risk.recent_review?.inspection_result === "none" && inspectionNoneNone + 1;
          inspectionPassNone = entity.risk.recent_review?.inspection_result === "pass" && inspectionPassNone + 1;
        }
      });
      setStackedBarData([
        {
          name: 'Flagged',
          Fail: inspectionFailedFlagged,
          None: inspectionNoneFlagged,
          Pass: inspectionPassFlagged,
        },
        {
          name: 'None',
          Fail: inspectionFailedNone,
          None: inspectionNoneNone,
          Pass: inspectionPassNone,
        },
      ]);
    })
  }, [getEntitiesAPI]);

  useEffect(() => {
    // let getFeatureImportanceAPIResponse = {
    //   "feature_importance": {
    //     "annual_clients": 0.03570491035026803,
    //     "infraction_history_1to3yr": 0.0,
    //     "infraction_severity_major": 0.08891232505488711,
    //     "infraction_severity_minor": 0.08130919718191147,
    //     "infraction_severity_none": 0.38273590353726145,
    //     "inspection_result_fail": 0.09201611419046961,
    //     "inspection_result_pass": 0.09145215416160808,
    //     "quarter_complaint_severity_major": 0.03470955136878451,
    //     "quarter_complaint_severity_minor": 0.006494466550264547,
    //     "quarter_complaint_severity_none": 0.00781810973767943,
    //     "quarter_sentiment_analysis_flagged": 0.08501942358380381,
    //     "quarter_sentiment_analysis_none": 0.0938278442830619
    //   },
    //   "id": "66ec8927b9043ac8d5cfced7",
    //   "image_size": 469733,
    //   "training_date": "2024-09-19 20:27:15",
    //   "training_result": true,
    //   "training_size": 200
    // }

    let getFeatureImportanceAPIResponse;
    setLoadingFeatureImportance(true);
    fetch(getFeatureImportanceAPI, {
      method: "POST",      
    }).then((res) => {
      return res.json();
    }).then((data) => {
      console.log(data);
      getFeatureImportanceAPIResponse = data;

      let annualClients = (Math.round((getFeatureImportanceAPIResponse.feature_importance.annual_clients * 100 + Number.EPSILON) * 100) / 100);
      let infractionSeverityMajor = (Math.round((getFeatureImportanceAPIResponse.feature_importance.infraction_severity_major * 100 + Number.EPSILON) * 100) / 100);
      let infractionSeverityMinor = (Math.round((getFeatureImportanceAPIResponse.feature_importance.infraction_severity_minor * 100 + Number.EPSILON) * 100) / 100);
      let infractionSeverityNone = (Math.round((getFeatureImportanceAPIResponse.feature_importance.infraction_severity_none * 100 + Number.EPSILON) * 100) / 100);
      // let InfractionHistory = (Math.round((getFeatureImportanceAPIResponse.feature_importance.infraction_history_1to3yr * 100 + Number.EPSILON) * 100) / 100);
      let complaintSeverityMajor = (Math.round((getFeatureImportanceAPIResponse.feature_importance.quarter_complaint_severity_major * 100 + Number.EPSILON) * 100) / 100);
      let complaintSeverityMinor = (Math.round((getFeatureImportanceAPIResponse.feature_importance.quarter_complaint_severity_minor * 100 + Number.EPSILON) * 100) / 100);
      let complaintSeverityNone = (Math.round((getFeatureImportanceAPIResponse.feature_importance.quarter_complaint_severity_none * 100 + Number.EPSILON) * 100) / 100);
      let sentimentAnalysisFlagged = (Math.round((getFeatureImportanceAPIResponse.feature_importance.quarter_sentiment_analysis_flagged * 100 + Number.EPSILON) * 100) / 100);
      let sentimentAnalysisNone = (Math.round((getFeatureImportanceAPIResponse.feature_importance.quarter_sentiment_analysis_none * 100 + Number.EPSILON) * 100) / 100);
      let InspectionResultFail = (Math.round((getFeatureImportanceAPIResponse.feature_importance.inspection_result_fail * 100 + Number.EPSILON) * 100) / 100);
      let InspectionResultPass = (Math.round((getFeatureImportanceAPIResponse.feature_importance.inspection_result_pass * 100 + Number.EPSILON) * 100) / 100);
      let TotalImportance = 100;

      const waterfallData = [
        {
          "feature": "Annual Clients",
          "Importance": [
            0,
            annualClients
          ],
          "barLabel": annualClients
        },
        {
          "feature": "Infraction Major",
          "Importance": [
            annualClients,
            (annualClients+infractionSeverityMajor).toFixed(2)
          ],
          "barLabel": infractionSeverityMajor
        },
        {
          "feature": "Infraction Minor",
          "Importance": [
            (annualClients+infractionSeverityMajor).toFixed(2),
            (annualClients+infractionSeverityMajor+infractionSeverityMinor).toFixed(2)
          ],
          "barLabel": infractionSeverityMinor
        },
        {
          "feature": "Infraction None",
          "Importance": [
            (annualClients+infractionSeverityMajor+infractionSeverityMinor).toFixed(2),
            (annualClients+infractionSeverityMajor+infractionSeverityMinor+infractionSeverityNone).toFixed(2)
          ],
          "barLabel": infractionSeverityNone
        },
        {
          "feature": "Complaint Major",
          "Importance": [
            (annualClients+infractionSeverityMajor+infractionSeverityMinor+infractionSeverityNone).toFixed(2),
            (annualClients+infractionSeverityMajor+infractionSeverityMinor+infractionSeverityNone+complaintSeverityMajor).toFixed(2)
          ],
          "barLabel": complaintSeverityMajor
        },
        {
          "feature": "Complaint Minor",
          "Importance": [
            (annualClients+infractionSeverityMajor+infractionSeverityMinor+infractionSeverityNone+complaintSeverityMajor).toFixed(2),
            (annualClients+infractionSeverityMajor+infractionSeverityMinor+infractionSeverityNone+complaintSeverityMajor+complaintSeverityMinor).toFixed(2)
          ],
          "barLabel": complaintSeverityMinor
        },
        {
          "feature": "Complaint None",
          "Importance": [
            (annualClients+infractionSeverityMajor+infractionSeverityMinor+infractionSeverityNone+complaintSeverityMajor+complaintSeverityMinor).toFixed(2),
            (annualClients+infractionSeverityMajor+infractionSeverityMinor+infractionSeverityNone+complaintSeverityMajor+complaintSeverityMinor+complaintSeverityNone).toFixed(2)
          ],
          "barLabel": complaintSeverityNone
        },
        {
          "feature": "Sentiment Analysis Flagged",
          "Importance": [
            (annualClients+infractionSeverityMajor+infractionSeverityMinor+infractionSeverityNone+complaintSeverityMajor+complaintSeverityMinor+complaintSeverityNone).toFixed(2),
            (annualClients+infractionSeverityMajor+infractionSeverityMinor+infractionSeverityNone+complaintSeverityMajor+complaintSeverityMinor+complaintSeverityNone+sentimentAnalysisFlagged).toFixed(2)
          ],
          "barLabel": sentimentAnalysisFlagged
        },
        {
          "feature": "Sentiment Analysis None",
          "Importance": [
            (annualClients+infractionSeverityMajor+infractionSeverityMinor+infractionSeverityNone+complaintSeverityMajor+complaintSeverityMinor+complaintSeverityNone+sentimentAnalysisFlagged).toFixed(2),
            (annualClients+infractionSeverityMajor+infractionSeverityMinor+infractionSeverityNone+complaintSeverityMajor+complaintSeverityMinor+complaintSeverityNone+sentimentAnalysisFlagged+sentimentAnalysisNone).toFixed(2)
          ],
          "barLabel": sentimentAnalysisNone
        },
        {
          "feature": "Inspection Result Pass",
          "Importance": [
            (annualClients+infractionSeverityMajor+infractionSeverityMinor+infractionSeverityNone+complaintSeverityMajor+complaintSeverityMinor+complaintSeverityNone+sentimentAnalysisFlagged+sentimentAnalysisNone).toFixed(2),
            (annualClients+infractionSeverityMajor+infractionSeverityMinor+infractionSeverityNone+complaintSeverityMajor+complaintSeverityMinor+complaintSeverityNone+sentimentAnalysisFlagged+sentimentAnalysisNone+InspectionResultPass).toFixed(2)
          ],
          "barLabel": InspectionResultPass
        },
        {
          "feature": "Inspection Result Fail",
          "Importance": [
            (annualClients+infractionSeverityMajor+infractionSeverityMinor+infractionSeverityNone+complaintSeverityMajor+complaintSeverityMinor+complaintSeverityNone+sentimentAnalysisFlagged+sentimentAnalysisNone+InspectionResultPass).toFixed(2),
            // (annualClients+infractionSeverityMajor+infractionSeverityMinor+infractionSeverityNone+complaintSeverityMajor+complaintSeverityMinor+complaintSeverityNone+sentimentAnalysisFlagged+sentimentAnalysisNone+InspectionResultPass+InspectionResultFail).toFixed(2)
            TotalImportance
          ],
          "barLabel": InspectionResultFail
        },
        {
          "feature": "Total",
          "Importance": [
            0,
            TotalImportance
          ],
          "barLabel": 100
        },
      ]

      // console.log(waterfallData);
      setFeatureImportance(waterfallData);

      setLoadingFeatureImportance(false);
    });
  }, [getFeatureImportanceAPI]);

  useEffect(() => {
    if(radarOptions.length > 0) {
      let temp = [];
      for(let i = 0; i < 5; i ++) {
        // console.log(radarOptions[i]);
        temp.push(radarOptions[i]);
        // temp.pop();
      }
      console.log(temp);
      setDefaultRadarOptions(temp);
      handleRadarOptionsSelect(temp);
    }
  }, [radarOptions])

  useEffect(() => {
    if(allEntities) {
      let high = 0;
      let medium = 0;
      let low = 0;
      allEntities.forEach((entity) => {
        if(entity.risk.score >= 13) {
          high += 1;
        } else if(entity.risk.score >= 7 && entity.risk.score <= 12) {
          medium += 1;
        } else {
          low += 1;
        }
        setHighRiskEntities(high);
        setMediumRiskEntities(medium);
        setLowRiskEntities(low);
      });
      const data = {
        labels: [''],
        datasets: [
          {
            label: 'High',
            backgroundColor: "#FF0003",
            borderColor: "#FF0003",
            data: [parseInt(high)]
          },
          {
            label: 'Medium',
            backgroundColor: "#FF8F3C",
            borderColor: "#FF8F3C",
            data: [parseInt(medium)]
          },
          {
            label: 'Low',
            backgroundColor: "#3EB75E",
            borderColor: "#3EB75E",
            data: [parseInt(low)]
          }
        ]
      };
      const options = {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false
            }
          }
        }
      };
  
      setChartData(data);
      setChartOptions(options);
    }
  }, [allEntities])

  useEffect(() => {
    if(formSubmittedSuccessfully) {
      setLoading(true);
      fetch(getEntitiesAPI, {
        method: "GET",      
      }).then((res) => {
        return res.json();
      }).then((data) => {
        setAllEntities(data.entities);
        setTotalEntities(data.size);
        setLoading(false);
      })
      setFormSubmittedSuccessfully(false);
    }
  }, [getEntitiesAPI, formSubmittedSuccessfully, setFormSubmittedSuccessfully])

  useEffect(() => {
    if(addReviewToEntityFormSubmittedSuccessfully) {
      // setLoading(true);
      fetch(getEntitiesAPI, {
        method: "GET",      
      }).then((res) => {
        return res.json();
      }).then((data) => {
        setAllEntities(data.entities);
        setTotalEntities(data.size);
        // setLoading(false);
      })
      setAddReviewToEntityFormSubmittedSuccessfully(false);
    }
  }, [getEntitiesAPI, addReviewToEntityFormSubmittedSuccessfully, setAddReviewToEntityFormSubmittedSuccessfully]);

  return (
    <>
      <Element
        name="healthcareInsights" 
        className="edu-about-area about-style-1 edu-section-gap"
      >
        <div className="container inner about-feature-list">
          <div className="row g-5 justify-content-center">
            <div className="col-lg-12">
              <RegulatoryDashboardSectionTitle
                classes = "text-left"
                // slogan = "Eligibility"
                title = "Regulatory Compliance Dashboard"
              />
            </div>
            {loading ? <div className="mt-4">Loading data...<br></br><img src={`${process.env.PUBLIC_URL}/images/loading.gif`} alt="illustration" /></div> : <>
              <h3>Month of {month[new Date().getMonth()]}</h3>
              <div className="col-lg-9">
                <div className="risk-category-distribution border-8 p-4 position-relative">
                  <img className="info-button cursor-pointer position-absolute" src={`${process.env.PUBLIC_URL}/images/icons/info-solid.svg`} width="20px" alt="icon" onClick={() => showRiskCalculationPopup()}></img>
                  <div className="row">
                    <div className="col-lg-6 d-flex flex-column gap-4">
                      <h5 className="mb-0">Risk Category Distribution</h5>
                      <p
                        className="distribution-entities total-entities mb-0"
                      >
                        Total Entities: <span>{totalEntities}</span>
                        &nbsp;&nbsp;{filterApplied && <span onClick={() => {
                            // scrollIntoViewWithOffset("RegulatoryComplianceTable");
                            // resetFilters();
                            // handleCheckboxChange("riskClassification", "Total");
                            clearFilters();
                            setShowFilters(false);
                            // setTimeout(() => {document.getElementById("ApplyFiltersBtn")?.click();}, 200)
                          }}>Clear Filters</span>}
                      </p>
                      <div className="entities-button-container d-flex flex-column align-items-center">
                        <p
                          className="distribution-entities"
                          id="highRiskEntitiesDistribution"
                          onClick={() => {
                            // scrollIntoViewWithOffset("RegulatoryComplianceTable");
                            resetFilters();
                            handleCheckboxChange("riskClassification", "High");
                            setShowFilters(true);
                            setTimeout(() => {document.getElementById("ApplyFiltersBtn")?.click();}, 200)
                          }}
                        >
                          <span>{highRiskEntities}</span>&nbsp;&nbsp; High Risk Entities
                        </p>
                        <p
                          className="distribution-entities"
                          id="mediumRiskEntitiesDistribution"
                          onClick={() => {
                            // scrollIntoViewWithOffset("RegulatoryComplianceTable");
                            resetFilters();
                            handleCheckboxChange("riskClassification", "Medium");
                            setShowFilters(true);
                            setTimeout(() => {document.getElementById("ApplyFiltersBtn")?.click();}, 200)
                          }}
                        >
                          <span>{mediumRiskEntities}</span>&nbsp;&nbsp; Medium Risk Entities
                        </p>
                        <p
                          className="distribution-entities"
                          id="lowRiskEntitiesDistribution"
                          onClick={() => {
                            // scrollIntoViewWithOffset("RegulatoryComplianceTable");
                            resetFilters();
                            handleCheckboxChange("riskClassification", "Low");
                            setShowFilters(true);
                            setTimeout(() => {document.getElementById("ApplyFiltersBtn")?.click();}, 200)
                          }}
                        >
                          <span>{lowRiskEntities}</span>&nbsp;&nbsp; Low Risk Entities
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="risk-entities-bar-graph">
                        <Chart type="bar" data={chartData} options={chartOptions} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 ps-lg-0">
                <div className="d-flex flex-column gap-5 gap-lg-4">
                  <div className="dashboard-change-card border-8 p-4 position-relative">
                    <img className="info-button cursor-pointer position-absolute" src={`${process.env.PUBLIC_URL}/images/icons/info-solid.svg`} width="20px" alt="icon" onClick={() => showComplianceRateInfo()}></img>
                    <p className="mb-3">Compliance Rate</p>
                    <div className="percentage-rate-and-change d-flex justify-content-between mb-3">
                      <h5 className="mb-0">{complianceRate} %</h5>
                      <p className="percentage-change mb-0">
                        <img src={`${process.env.PUBLIC_URL}/images/icons/green-arrow-up.svg`} alt="arrow" />
                        &nbsp;&nbsp;10 %
                      </p>
                    </div>
                    <p className="dashboard-change mb-1">from {(complianceRate - 10).toFixed(2)}% last month</p>
                  </div>
                  <div className="dashboard-change-card border-8 p-4 position-relative">
                    <img className="info-button cursor-pointer position-absolute" src={`${process.env.PUBLIC_URL}/images/icons/info-solid.svg`} width="20px" alt="icon" onClick={() => showRepeatOffenderRateInfo()}></img>
                    <p className="mb-3">Repeat Offender Rate</p>
                    <div className="percentage-rate-and-change d-flex justify-content-between mb-3">
                      <h5 className="mb-0">{repeatOffenderCount} %</h5>
                      <p className="percentage-change mb-0">
                        <img src={`${process.env.PUBLIC_URL}/images/icons/red-arrow-down.svg`} alt="arrow" />
                        &nbsp;&nbsp;-8 %
                      </p>
                    </div>
                    <p className="dashboard-change mb-1">from {(parseFloat(repeatOffenderCount) + 8).toFixed(2)}% last month</p>
                  </div>
                  <div className="dashboard-change-card border-8 p-4 position-relative">
                    <img className="info-button cursor-pointer position-absolute" src={`${process.env.PUBLIC_URL}/images/icons/info-solid.svg`} width="20px" alt="icon" onClick={() => showInspectionFailureRateInfo()}></img>
                    <p className="mb-3">Inspection Failure Rate</p>
                    <div className="percentage-rate-and-change d-flex justify-content-between mb-3">
                      <h5 className="mb-0">{inspectionFailureRate} %</h5>
                      <p className="percentage-change mb-0">
                        <img src={`${process.env.PUBLIC_URL}/images/icons/red-arrow-down.svg`} alt="arrow" />
                        &nbsp;&nbsp;-9 %
                      </p>
                    </div>
                    <p className="dashboard-change mb-1">from {(parseFloat(inspectionFailureRate) + 9).toFixed(2)}% last month</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="border-8 p-4 position-relative top-5-high-risk">
                  <img className="info-button cursor-pointer position-absolute" src={`${process.env.PUBLIC_URL}/images/icons/info-solid.svg`} width="20px" alt="icon" onClick={() => showTop5HighPopup()}></img>
                  <h5 className="mb-0">Top 5 High Risk Entities</h5>
                  <DataTable
                    className="mt-1"
                    value={topHighRiskEntities}
                    // paginator
                    sortMode="multiple"
                    removableSort
                    rows={5}
                    // rowsPerPageOptions={[5, 10, 25, 50]}
                    // tableStyle={{ minWidth: '50rem' }}
                    dataKey="id"
                    // header={header}
                    filters={filters}
                    // filterDisplay="row"
                    // loading={loading}
                    globalFilterFields={['name', 'risk.score', 'risk.recent_review.annual_clients', 'risk.recent_review.date', 'risk.infraction_history']}
                    emptyMessage="No entities found."
                    // expandedRows={expandedRows}
                    // onRowToggle={(e) => setExpandedRows(e.data)}
                    // rowExpansionTemplate={rowExpansionTemplate}
                    selectionMode="single"
                    onRowSelect={onRowSelect}
                    style={{fontSize: "16px"}}
                  >
                    <Column field="name" sortable header="Entity Name"></Column>
                    <Column field="risk.score" sortable header="Risk Score"></Column>
                    <Column field="risk.recent_review.infraction_severity" header="Infraction Severity"></Column>
                    {/* <Column field="goDetails"></Column> */}
                  </DataTable>
                </div>
              </div>
              <div className="col-lg-5 ps-lg-0">
                <div className="risk-trend-container border-8 p-4 position-relative">
                  <img className="info-button cursor-pointer position-absolute" src={`${process.env.PUBLIC_URL}/images/icons/info-solid.svg`} width="20px" alt="icon" onClick={() => showRiskTrendPopup()}></img>
                  <h5 className="mb-0">Risk Trend</h5>
                  <div className="risk-trend-img-container">
                    <img src={`${process.env.PUBLIC_URL}/images/${riskTrendImg}_Risk_Entities_Trend.png`} alt="risk trend" />
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="feature-importance-section border-8 p-4 position-relative">
                  <img className="info-button cursor-pointer position-absolute" src={`${process.env.PUBLIC_URL}/images/icons/info-solid.svg`} width="20px" alt="icon" onClick={() => showFeatureImportancePopup()}></img>
                  <h5 className="mb-0">Feature Importance</h5>
                  {loadingFeatureImportance ? <div className="mt-4">Loading data...<br></br><img src={`${process.env.PUBLIC_URL}/images/loading.gif`} alt="illustration" /></div> : <ResponsiveContainer width="100%" height={400}>
                    <BarChart width={700} height={250} data={featureImportance} margin={{top: 20, right: 20, bottom: 20, left: 20}} >
                      <XAxis dataKey="feature" />
                      <YAxis />
                      <TooltipRecharts />
                      <Bar dataKey="Importance" fill="#7152E9" >
                        <LabelList dataKey="barLabel" position="top" />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>}
                </div>
              </div>
              <div className="col-lg-5 ps-0">
                <div className="sentiment-analysis-container border-8 p-4 position-relative">
                  <img className="info-button cursor-pointer position-absolute" src={`${process.env.PUBLIC_URL}/images/icons/info-solid.svg`} width="20px" alt="icon" onClick={() => showSentimentAnalysisPopup()}></img>
                  <h5 className="mb-0">Distribution of Sentiment Analysis with Inspection Results</h5>
                  <ResponsiveContainer width="100%" height={400}>
                    <BarChart width={600} height={400} data={stackedBarData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <TooltipRecharts />
                      <LegendRecharts />
                      <Bar dataKey="Fail" stackId="a" fill="#ff6384" />
                      <Bar dataKey="None" stackId="a" fill="#36a2eb" />
                      <Bar dataKey="Pass" stackId="a" fill="#4bc0c0" />
                    </BarChart>
                  </ResponsiveContainer>
                </div> 
              </div>
              <div className="col-lg-7">
                <div className="entity-score-values border-8 p-4 position-relative">
                  <img className="info-button cursor-pointer position-absolute" src={`${process.env.PUBLIC_URL}/images/icons/info-solid.svg`} width="20px" alt="icon" onClick={() => showSentimentAnalysisPopup()}></img>
                  <h5 className="mb-0">Entity Score Values</h5>
                  {defaultRadarOptions.length > 0 && <Select
                    className="basic-multi-select mt-3"
                    isMulti
                    name="entities"
                    onChange={handleRadarOptionsSelect}
                    options={radarOptions}
                    defaultValue={defaultRadarOptions}
                    placeholder="Select Entity..."
                  ></Select>}
                  <ResponsiveContainer width="100%" height={450}>
                    <RadarChart cx="50%" cy="50%" outerRadius="80%" data={radarChart}>
                      <PolarGrid />
                      <PolarAngleAxis dataKey="subject" />
                      <PolarRadiusAxis angle={0} domain={[0, 3]} />
                      {radarOptionsSelected >= 1 && <Radar dataKey="Entity1" stroke="#003f5c" fill="#003f5c" fillOpacity={0.6} />}
                      {radarOptionsSelected >= 2 && <Radar dataKey="Entity2" stroke="#58508d" fill="#58508d" fillOpacity={0.6} />}
                      {radarOptionsSelected >= 3 && <Radar dataKey="Entity3" stroke="#8a508f" fill="#8a508f" fillOpacity={0.6} />}
                      {radarOptionsSelected >= 4 && <Radar dataKey="Entity4" stroke="#bc5090" fill="#bc5090" fillOpacity={0.6} />}
                      {radarOptionsSelected >= 5 && <Radar dataKey="Entity5" stroke="#de5a79" fill="#de5a79" fillOpacity={0.6} />}
                      {radarOptionsSelected >= 6 && <Radar dataKey="Entity6" stroke="#ff6361" fill="#ff6361" fillOpacity={0.6} />}
                      {radarOptionsSelected >= 7 && <Radar dataKey="Entity7" stroke="#ff8531" fill="#ff8531" fillOpacity={0.6} />}
                      {radarOptionsSelected >= 8 && <Radar dataKey="Entity8" stroke="#ffa600" fill="#ffa600" fillOpacity={0.6} />}
                      <LegendRecharts />
                    </RadarChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div className="col-lg-5 ps-0">
                <div className="risk-analyzer border-8 p-4 position-relative">
                  <img className="info-button cursor-pointer position-absolute" src={`${process.env.PUBLIC_URL}/images/icons/info-solid.svg`} width="20px" alt="icon" onClick={() => showSentimentAnalysisPopup()}></img>
                  <h5 className="mb-0">Risk Analyzer</h5>
                  {showRiskAnalyzerForm && <form onSubmit={handleRiskAnalyzerForm} className="mt-2 d-flex flex-column justify-content-center">
                    <label className="mt-5">
                      Number of clients served annually<br></br>
                      <input
                        className="add-entity-select"
                        type="number"
                        min="0"
                        value={annualClients}
                        onChange={handleAnnualClientChange}
                      />
                    </label>
                    <label className="mt-4">
                      Infraction Severity
                      <Select
                        className="basic-single add-entity-select"
                        placeholder="Select or search by typing..."
                        isClearable="true"
                        name="clientName"
                        options={infractionSeverityOptions}
                        onChange={handleInfractionSeveritySelect}
                      />
                    </label>
                    <label className="mt-4">
                      Quarter Complaint Severity
                      <Select
                        className="basic-single add-entity-select"
                        placeholder="Select or search by typing..."
                        isClearable="true"
                        name="clientName"
                        options={quarterComplaintSeverityOptions}
                        onChange={handleQuarterComplaintSeveritySelect}
                      />
                    </label>
                    <label className="mt-4">
                      Quarter Sentiment Analysis
                      <Select
                        className="basic-single add-entity-select"
                        placeholder="Select or search by typing..."
                        isClearable="true"
                        name="clientName"
                        options={quarterSentimentAnalysisOptions}
                        onChange={handleQuarterSentimentAnalysisSelect}
                      />
                    </label>
                    <label className="mt-4">
                      Inspection Result
                      <Select
                        className="basic-single add-entity-select"
                        placeholder="Select or search by typing..."
                        isClearable="true"
                        name="clientName"
                        options={inspectionResultOptions}
                        onChange={handleInspectionResultSelect}
                      />
                    </label>
                    <div className="mt-3">
                      <button className='edu-btn btn-secondary mt-4' type="submit">Submit</button>
                    </div>
                  </form>}
                  {showRiskAnalyzerResult && <div className="mt--40">
                    <div>
                      <p className="mb-0"><strong>Entity Details:</strong></p>
                      <p className="mb-1">Annual Clients: <strong>40</strong></p>
                      <p className="mb-1">Infraction type: <strong>Minor</strong></p>
                      <p className="mb-1">Infraction timeline: <strong>Within past year</strong></p>
                      <p className="mb-1">Public complaints: <strong>Major</strong></p>
                      <p className="mb-1">Sentiment analysis: <strong>Flagged</strong></p>
                      <p className="mb-4">Inspection Results: <strong>Pass</strong></p>
                    </div>
                    <table className="mt--40" style={{"tableLayout": "fixed", "width": "100%"}}>
                      <colgroup>
                        <col style={{"width": "25%"}} />
                        <col style={{"width": "25%"}} />
                        <col style={{"width": "25%"}} />
                        <col style={{"width": "25%"}} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th colSpan={4}>
                            Risk Prediction & Probability
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>High :</td>
                          <td>Medium :</td>
                          <td>Low :</td>
                          <td>Prediction :</td>
                        </tr>
                        <tr>
                          <td>33.3%</td>
                          <td>66.7%</td>
                          <td>0%</td>
                          <td>Medium</td>
                        </tr>
                      </tbody>
                    </table>
                    <button className="edu-btn btn-primary mt--40" onClick={() => {setShowRiskAnalyzerResult(false); setShowRiskAnalyzerForm(true);}}>Predict another</button>
                  </div>}
                </div>
              </div>
              <div className="col-lg-12" id="RegulatedEntities">
                <div className="table-header d-flex flex-row justify-content-between align-items-center">
                  <h5 className="m-0">Regulated Entities</h5>
                  <div className="entities-table-buttons d-flex flex-row align-items-center gap-4">
                    <button className="edu-btn btn-secondary lh-1 btn-small" onClick={() => setShowAddEntityForm(true)}>+ Add Entity</button>
                    <button className="edu-btn btn-bg-alt lh-1 btn-small position-relative" onClick={() => setShowFilters(!showFilters)}><img src={`${process.env.PUBLIC_URL}/images/icons/filter-solid.svg`} className="cursor-pointer" alt="filters" />
                      <span className="filter-counter hidePopup" id="FilterCounter">{filterCount}</span>
                    </button>
                  </div>
                </div>
                <form className={showFilters ? "filters-container mt-4 " : "filters-container mt-4 d-none"} onSubmit={handleFilterEntityFormSubmit}>
                  <div className="entity-filters-body row justify-content-center">
                    {Object.keys(attributes).map(category => (
                      <div key={category} className="row">
                        <p className="mt-4 mb-0"><strong>{(category.charAt(0).toUpperCase() + category.slice(1)).replace(/([A-Z])/g, ' $1')}</strong></p>
                        {attributes[category].map(value => (
                          <div key={value} className="col-12 col-lg-4 entity-filter-label">
                            <Checkbox
                              label={value.charAt(0).toUpperCase() + value.slice(1)}
                              value={applyFilters[category].has(value)}
                              onChange={() => handleCheckboxChange(category, value)}
                            />
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                  <div className="entity-filters-footer d-flex flex-row justify-content-between mt-4">
                    <button className="edu-btn btn-bg-alt lh-1 btn-medium" onClick={() => clearFilters()}>Clear Filters</button>
                    <button className="edu-btn btn-primary btn-medium" type="submit" id="ApplyFiltersBtn">Apply Filters</button>
                  </div>
                  {/* <p className="mt-4 mb-0">Note: If no filter is selected it will reset the table and show all rows.</p> */}
                </form>
                <div className="card mt-4" id="RegulatoryComplianceTable">
                  <DataTable
                    value={filterApplied ? filteredEntities : allEntities}
                    paginator
                    sortMode="multiple"
                    removableSort
                    rows={25}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    tableStyle={{ minWidth: '50rem' }}
                    dataKey="id"
                    header={header}
                    filters={filters}
                    // filterDisplay="row"
                    // loading={loading}
                    scrollable
                    globalFilterFields={['name', 'risk.score', 'risk.recent_review.annual_clients', 'risk.recent_review.date', 'risk.infraction_history']}
                    emptyMessage="No entities found."                  
                    expandedRows={expandedRows}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    rowExpansionTemplate={rowExpansionTemplate}
                    style={{fontSize: "16px"}}
                  >
                    {window.screen.width < 700 && <Column frozen expander={allowExpansion} style={{ width: '5rem' }} />}
                    <Column field="name" sortable header="Entity Name"></Column>
                    <Column field="risk.score" body={riskClassificationTemplate} showFilterMenu={false} header="Risk Classification" filter filterElement={riskClassificationFilterTemplate}></Column>
                    <Column field="risk.score" sortable header="Risk Score"></Column>
                    <Column field="risk.recent_review.annual_clients" sortable header="Annual Clients"></Column>
                    <Column field="risk.recent_review.date" body={dateBodyTemplate} header="Recent Review"></Column>
                    <Column field="risk.infraction_history" header="Infraction History" body={infractionHistoryTemplate}></Column>
                    {window.screen.width >= 700 && <Column frozen expander={allowExpansion} style={{ width: '5rem' }} />}
                  </DataTable>
                </div>
              </div>
            </>}
          </div>
        </div>
        <RiskCalculation hideRiskCalculationInfo={hideRiskCalculationInfo} />
        <Top5HighRiskTable hideTop5HighPopup={hideTop5HighPopup} />
        <ComplianceRateInfo hideComplianceRateInfo={hideComplianceRateInfo} />
        <RepeatOffenderRateInfo hideRepeatOffenderRateinfo={hideRepeatOffenderRateinfo} />
        <InspectionFailureRateInfo hideInspectionFailureRateinfo={hideInspectionFailureRateinfo} />
        <RiskTrendInfo hideRiskTrendPopup={hideRiskTrendPopup} />
        <FeatureImportanceInfo hideFeatureImportancePopup={hideFeatureImportancePopup} />
        <SentimentAnalysisPopup hideSentimentAnalysisPopup={hideSentimentAnalysisPopup} />
      </Element>
    </>
  )
}

export default RegulatoryDashboard;