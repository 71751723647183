import React, { useEffect, useState } from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import RegulatoryDashboard from '../../components/regulatory-dashboard2/RegulatoryDashboard';
import AddEntityForm from '../../components/form/regulatory-dashboard-forms/AddEntityForm';
import AddReviewToEntityForm from '../../components/form/regulatory-dashboard-forms/AddReviewToEntityForm';
import RegulatoryChatbot from '../../components/regulatory-dashboard2/RegulatoryChatbot';
import RegulatoryChatbotify from '../../components/regulatory-dashboard2/RegulatoryChatbotify';

const RegulatoryDashboardPage2 = () => {
	const [showAddEntityForm, setShowAddEntityForm] = useState(false);
	const [showAddReviewToEntityForm, setShowAddReviewToEntityForm] = useState(false);
	const [entityName, setEntityName] = useState("");
  const [formSubmittedSuccessfully, setFormSubmittedSuccessfully] = useState(false);
	const [addReviewToEntityFormSubmittedSuccessfully, setAddReviewToEntityFormSubmittedSuccessfully] = useState(false);
	const [valid, setValid] = useState(false);
	
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const paramValue = urlParams.get('valid');
		// console.log(paramValue);
		setValid(paramValue === "true" ? true : false);

		const script = document.createElement('script');
		
		script.src = "https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js";
		script.async = true;
		
		document.body.appendChild(script);
		
		return () => {
			document.body.removeChild(script);
		}
    // <script src='https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js'></script>
    // <script>window.jotformEmbedHandler("iframe[id='JotFormIFrame-240715321891252'", "https://form.jotform.com/")</script>
    
	}, [])

	return (
		<>
			<SEO
				title="Regulatory Compliance Dashboard"
				description=""
			/>
			<Layout>
				{!valid ? <><RegulatoryDashboard
					setShowAddEntityForm={setShowAddEntityForm}
					setShowAddReviewToEntityForm={setShowAddReviewToEntityForm}
					setEntityName={setEntityName}
					formSubmittedSuccessfully={formSubmittedSuccessfully}
					setFormSubmittedSuccessfully={setFormSubmittedSuccessfully}
					addReviewToEntityFormSubmittedSuccessfully={addReviewToEntityFormSubmittedSuccessfully}
					setAddReviewToEntityFormSubmittedSuccessfully={setAddReviewToEntityFormSubmittedSuccessfully}
				/>
				{/* <RegulatoryChatbot /> */}
				<RegulatoryChatbotify />
				</> : <iframe
					id="JotFormIFrame-241659289230260"
					title="Regulatory Compliance Dashboard"
					onLoad={window.parent.scrollTo(0,0)}
					allowtransparency="true"
					allow="geolocation; microphone; camera; fullscreen"
					src="https://form.jotform.com/241659289230260"
					frameBorder="0"
					style={{"minWidth":"100%", "maxWidth": "100%", "height": "539px", "border": "none"}}
					scrolling="no"
				>
				</iframe>}
			</Layout>
			{showAddEntityForm && <AddEntityForm
				setShowAddEntityForm={setShowAddEntityForm}
				setFormSubmittedSuccessfully={setFormSubmittedSuccessfully}
			/>}
			{showAddReviewToEntityForm && <AddReviewToEntityForm
				setShowAddReviewToEntityForm={setShowAddReviewToEntityForm}
				entityName={entityName}
				setAddReviewToEntityFormSubmittedSuccessfully={setAddReviewToEntityFormSubmittedSuccessfully}
			/>}
		</>
	)
}
export default RegulatoryDashboardPage2;