import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import JobseekerFormSection from '../../components/jobseeker/JobseekerFormSection';
import JobseekerFormSectionN from '../../components/jobseeker/JobseekerFormSectionN';

const Jobseeker = () => {

	return (
		<>
			<SEO
				title="Jobseeker"
				description=""
			/>
			<Layout>
				{/* <BreadcrumbOne
					title="Jobseeker"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Jobseeker"
				/> */}
				{/* <JobseekerFormSection /> */}
				<JobseekerFormSectionN />
			</Layout>
		</>
    )
}
export default Jobseeker;