import React, { useEffect, useState } from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ReturnToWorkPredictionFormSection from '../../components/return-to-work-prediction/ReturnToWorkPredictionFormSection';
import ModalWarningOverride from '../../components/return-to-work-prediction/ModalWarningOverride';

const ReturnToWorkPrediction = () => {
	const [showWarning, setShowWarning] = useState(false);
	const [acceptWarning, setAcceptWarning] = useState(false);
	const [valid, setValid] = useState(false);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const paramValue = urlParams.get('valid');
		// console.log(paramValue);
		setValid(paramValue === "true" ? true : false);

    const script = document.createElement('script');

		script.src = "https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js";
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		}
    // <script src='https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js'></script>
    // <script>window.jotformEmbedHandler("iframe[id='JotFormIFrame-240715321891252'", "https://form.jotform.com/")</script>
    
	}, [])

	return (
		<>
			<SEO
				title="Return To Work Prediction"
				description=""
			/>
			<Layout>
				<BreadcrumbOne
					title="Return To Work Prediction"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Return To Work Prediction"
				/>
				{valid ? <ReturnToWorkPredictionFormSection acceptWarning={acceptWarning} setShowWarning={setShowWarning} setAcceptWarning={setAcceptWarning} /> : <iframe
					id="JotFormIFrame-240715321891252"
					title="Return to Work Prediction"
					onLoad={window.parent.scrollTo(0,0)}
					allowtransparency="true"
					allow="geolocation; microphone; camera; fullscreen"
					src="https://form.jotform.com/m2mtech/return-to-work-prediction"
					frameBorder="0"
					style={{"minWidth":"100%", "maxWidth": "100%", "height": "539px", "border": "none"}}
					scrolling="no"
				>
				</iframe>}
			</Layout>
			{showWarning && <ModalWarningOverride setShowWarning={setShowWarning} setAcceptWarning={setAcceptWarning} />}
		</>
    )
}
export default ReturnToWorkPrediction;