import React, { useEffect, useState } from 'react';
import useJobseekerForm from '../../../customhooks/UseJobseekerForm';
import Select from 'react-select';

const sampleResponse = {
  "employability_percentage": 80,
  "plan_items": [
    {
        "item": "Job search",
        "reason": "The client is a Canadian citizen with programming and database experience, which are in-demand skills.",
        "recommended": true,
        "weight": 4
    },
    {
        "item": "Career exploration, planning and management",
        "reason": "The client is interested in multiple roles and may need guidance to focus their job search.",
        "recommended": true,
        "weight": 3
    },
    {
        "item": "Job matching and development",
        "reason": "The client's skills and interests need to be matched with the right job opportunities.",
        "recommended": true,
        "weight": 3
    },
    {
        "item": "Employability skills training",
        "reason": "The client is a recent graduate and may benefit from additional training on employability skills.",
        "recommended": false,
        "weight": 2
    },
    {
        "item": "Job seeker - Short-term skills training",
        "reason": "The client may need to update or expand their skills to increase employability.",
        "recommended": false,
        "weight": 2
    },
    {
        "item": "Job retention crisis response",
        "reason": "The client may need support to navigate any challenges that arise once they are employed to help them retain their job.",
        "recommended": false,
        "weight": 2
    },
    {
        "item": "Retention support planning",
        "reason": "The client may need ongoing support to help them succeed in their job once they are employed.",
        "recommended": false,
        "weight": 2
    },
    {
        "item": "Job seeker - Employment-related transportation",
        "reason": "The client may need assistance with transportation for job interviews or for the first few weeks of employment.",
        "recommended": false,
        "weight": 1
    },
    {
        "item": "Job seeker - Work clothing and/or grooming",
        "reason": "The client may need assistance with appropriate work attire for job interviews or the workplace.",
        "recommended": false,
        "weight": 1
    },
    {
        "item": "Additional services to find another job",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Basic Needs - Crisis resolution",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Basic Needs - Financial support",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Basic Needs - Food security",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Basic Needs - Housing",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Basic Needs - Transportation",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Client counselling",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Community Supports - Cultural transition",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Community Supports - Dependent care",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Community Supports - Justice and legal support",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Community Supports - Newcomer services",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Employer - Job placements with financial supports",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Employer - Job trials with financial supports",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Employer coaching",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Employment services for newcomers",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Health Supports - Mental health and addictions",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Health Supports - Primary care and ongoing medical concerns",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Job coaching",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Job seeker - Academic credential or professional accreditation assessment",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Job seeker - Accommodation needs - job-specific communication skills training",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Job seeker - Accommodation needs - on-the-job supports",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Job seeker - Certification charges",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Job seeker - Emergency/infrequent child care",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Job seeker - Employment-related special equipment and supplies",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Job seeker - Specialized hardware",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Job seeker - Translation of international academic documents",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Mentoring",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Provision of SSM In-Service Self Employment Services",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Referral to Academic Upgrading (College Program)",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Referral to Apprenticeship Development Benefit",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Referral to Better Jobs Ontario Training",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Referral to English as a Second Language Training",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Referral to External Self-employment/Entrepreneurship Services",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Referral to Government Services - Federal",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Referral to Government Services - Municipal",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Referral to Literacy and Basic Skills",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Referral to Micro-credentials",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Referral to Occupational Skills training",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Referral to Other Ministry Delivered or Funded Programs",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Referral to Post Secondary Education",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Referral to Secondary Education",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Resource and Information Services - Digital Services",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Resource and Information Services - General",
        "recommended": false,
        "weight": 0.5
    },
    {
        "item": "Supporting changes in work activities and supervision",
        "recommended": false,
        "weight": 0.5
    }
  ],
  "stream": "Stream A: (Rapid Employment)"
}

const JobseekerFormTest = ({ clientsName, setOutputData, selectedClient, setSelectedClient }) => {
  // const [FORM_ENDPOINT ,setFORM_ENDPOINT] = useState();
  const [file, setFile] = useState();

  const { handleSubmit, status, message } = useJobseekerForm();

  const reset = () => {
    setSelectedClient();
  }

  const handleClientNameSelect = (selected) => {
    selected ? setSelectedClient(JSON.stringify(selected.value)) : reset();
    // selected ? setSelectedClient("") : reset();
  }

  const handleFileInputChange = (e) => {
    setFile(e.target.files[0]);
  }
  
  //Testing function response
  const testFn = (e) => {
    e.preventDefault();
    setOutputData(sampleResponse);
  }

  const handleJobseekerFormSubmit = (e) => {
    // handleSubmit(e, selectedClient, file);
    testFn(e);
  }

  useEffect(() => {
    if (status === "success") {
      // console.log("success");
      setOutputData(message);
    }
  }, [status, message, setOutputData])
  
  useEffect(() => {
    if (status === "error") {
      setOutputData("Something bad happened \n" + message);
    }
  }, [status, message, setOutputData])

  return (
    <>
    <form
      action={process.env.REACT_APP_JOBSEEKER_API}
      onSubmit={handleJobseekerFormSubmit}
      method="POST"
      className="d-flex flex-column justify-content-center"
    >
      <div className="pt-0 mb-3">
        <label htmlFor="clientData">Select client</label>
        <Select
          className="basic-single client-detail-select-container"
          placeholder="Select or search by name..."
          isClearable="true"
          name="clientName"
          onChange={handleClientNameSelect}
          options={clientsName}
          required
        />
      </div>
      <div className="pt-0 mb-3">
        <label htmlFor="resumeFile" className="mt-3">Resume</label>
          <input
            className="mt-2 p-0"
            accept=".pdf, .doc, .docx"
            onChange={handleFileInputChange}
            type="file"
            name="resumeFile"
            required
          />
      </div>
      {status !== "loading" ? (
        <div className="pt-0 mt-4 mb-3">
          <button className="edu-btn btn-secondary" type="submit">
            Submit
          </button>
        </div>
       ) : <div className="mt-4">Processing data...<br></br><img src={`${process.env.PUBLIC_URL}/images/loading.gif`} alt="illustration" /></div>}
    </form>
    </>
  )
}

export default JobseekerFormTest;