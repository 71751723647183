import React from 'react';

const InspectionFailureRateInfo = ({hideInspectionFailureRateinfo}) => {

  return (
    <div className="risk-classification-container hidePopup" onClick={hideInspectionFailureRateinfo} id="InspectionFailureRatePopup">
      <div className="risk-classification-content">
        <p>Inspection Failure Rate = (Total number of Failed Inspection entities / Total number of entities) X 100.</p>
      </div>
    </div>
  )
}

export default InspectionFailureRateInfo;