import React from 'react';
import Slider from 'react-slick';
import { useState } from 'react';

let data = [
    {
        name: "Damla P.",
        // name: "Damla Peker",
        details: "Even though I have experience, as a newcomer in Canada, I have faced the difficulty of finding a job. However, learning new skills, working on capstone projects, presenting them, getting involved in real company projects, and networking has been a wonderful experience. For all of these reasons, I am really grateful to the Data Talent Program.",
    },
    {
        name: "Moyin A.",
        // name: "Moyin Ajayi",
        details: "The training videos were very detailed and explanatory. The weekly meetings helped to keep track of progress. Meeting with industry experts and learning from them was great. Working on real industry projects helped me to collaborate and work within amazing teams.There was a lot of support I received which helped my interview preparation and also helped to get my desired job. I also got a chance to build my personal portfolio over time and work on personal projects that I could talk about during interviews.",
        image: "/data-talent/testimonials/Moyin-A.jpg"
    },
    {
        name: "Megha K.",
        // name: "Megha Katiyar",
        details: "The employer project in this cohort is incredibly valuable, providing a hands-on experience similar to real-world situations. Additionally, working from scratch on the employer project exposes participants to a multitude of tasks, facilitating learning across various domains and adding immense value to their profiles. This experience not only sharpens technical skills but also offers a platform to understand business use cases and comprehend the value of one's work firsthand. Moreover, the project-based course material allows participants to apply what they've learned, enhancing their understanding and setting a strong foundation for concepts. This approach goes beyond theoretical learning, preparing participants for the market with some great hands-on projects.",
        image: "/data-talent/testimonials/Megha-K_v2.png"
    },
    {
        name: "Pushpa R.",
        // name: "Pushpa Rachamadugu",
        details: "All the videos were so well detailed. All the employer WIL projects gives good scope of work in real time. All the industry sessions are so great to meet different company recruiter inputs and thoughts in today's market. Ashok and Nimit are so the best to keep team engaged and focused on the training. I would like engage more in online tutor trainings so that students can engage more in discussion based learning. Also employer WIL projects if employer is little more lenient in providing their masked or their test data so that we would have confidence in our developed models and work more fine tuned models. Also I would appreciate if the team would have for 6 months rather than 16 weeks as for working people meeting all project deadlines while learning and also working full time is stressful and can skip in some parts of learning objectives which could make us less confidence to approach for the new role.",
    },
    {
        name: "Vandana K.",
        // name: "Vandana Kala",
        details: "I found the access to help and free communication in slack with all the cohort members helpful as everyone had different experiences and subject knowledge. There was focus on a combination of technical knowledge, Job posting support (by M2M) and Job search deliverables (by Diwo).",
    },
    {
        name: "Christopher F.",
        // name: "Christopher Fevrier",
        details: "Unfortunately I was not able to follow the program as intently as I intended to due to unforeseen external factors but the program does a good job at introducing the relevant fundamental data analyst skills in addition to facilitating various networking opportunities with industry components.",
    },
    {
        name: "Pichayoot O.",
        // name: "Pichayoot Ouppaphan",
        details: "Coursework and Work Integrated Learning are the most valuable part of the program.",
    },
    {
        name: "Sriharsha M.",
        // name: "Sriharsha Mushnoori",
        details: "The course material was pretty good and covered a very diverse range of topics. Gave me a good starting point to explore some of the topics in more depth on my own. The AI mastery livestreams were quite helpful as a continuation of the course material. However I wish the recordings were made available for review. Overall it was a decent experience, and I hope it translates to getting more interviews down the line.",
    },
    {
        name: "Ashley S.",
        // name: "Ashley Shi",
        details: "Discussion and practice is very important. Besides, all the people are ready to help me:)",
    },
    {
        name: "Penelope A.",
        // name: "Penelope Haro Aranda",
        details: "The collaboration with other participants is invaluable as I get to learn from them. The opportunity to work on a real project is the best component of this program, it gives an exposure of how it is to work as Data Analyst/Scientist and it gave me the opportunity to work on something that I can add to my resume as my first work experience, which is a huge support for my career change.",
    },
    {
        name: "Sola A.",
        // name: "Sola Agagu",
        details: "My favourite part of the program is learning with like-minded individuals and watching them share the projects they worked on. The opportunity to work on real life projects was a great learning experience to gain hands on experience and collaborate as team.",
    },
    {
        name: "Dami S.",
        // name: "Dami Salami",
        details: "The program's technical training is top tier and getting to work with so many motivated Data Scientists is a huge advantage. The most valuable part for me has been getting to interact with industry leaders and get their perspectives on what they look for when making hiring decisions.",
    },
    {
        name: "Farzad K.",
        // name: "Farzad Khazaee",
        details: "Session with industry leaders was the best part for me.",
    },
    {
        name: "Lucas H.",
        // name: "Lucas Ho",
        details: "The DataTalent Program has offered a multifaceted learning experience that has been invaluable in my journey toward mastering data science and analytics. One of the most valuable aspects has been the hands-on project work, where I've been able to apply theoretical knowledge to real-world problems. These projects have not only deepened my understanding of data analysis techniques but have also honed my problem-solving skills and taught me how to work effectively within a team. Additionally, the mentorship provided by seasoned professionals in the field has been instrumental in guiding me through complex concepts and providing valuable career advice. The program's emphasis on continuous learning through workshops, seminars, and access to cutting-edge tools and technologies has kept me updated with the latest trends and advancements in the field. Overall, the DataTalent Program has provided me with a comprehensive and immersive learning environment that has significantly accelerated my growth as a data professional.",
    },
    {
        name: "Claude V.",
        // name: "Claude de Villeres",
        details: "I had an exceptional experience with M2M Tech Connect's programs. As someone diving into the world of data science and AI, I found their offerings to be comprehensive and enriching. The integration of employer-led learning projects not only provided valuable hands-on experience but also connected me with like-minded passionate individuals. Throughout the program, I witnessed a significant enhancement in my confidence, particularly in analytical thinking and effectively communicating solutions to complex business problems. The supportive environment fostered by the team further facilitated my learning journey.",
    },
    {
        name: "Mahsa H.",
        // name: "Mahsa Hasheminejad",
        details: "Making connections and working on real-world projects are the best parts. Just the instructions about how to follow-up on the projects are somehow ambiguous.",
    },
];

const DataTalentTestimonial = ( props ) => {
    const [seeMore, setSeeMore] = useState();

    const classes = `testimonial-activation testimonial-style-1 edu-slick-button slick-button-left ${props.className ? props.className : '' }`;
    data = props.item ? data.slice( 0, props.item ) : data;

    const handleSeeMoreclick = (testimonialId) => {
        setSeeMore(testimonialId);
    }
    
    const TestimonialOneParams = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        afterChange: () => setSeeMore("")
    };

    return (
        <Slider
            className={ classes }
            { ...TestimonialOneParams }
        >
            { data.map( ( item, i ) => (
                <div className="single-testimonial" key={ i }>
                    <div className="inner">
                        {item.details.length > 160 ? (seeMore === i) ? <p className="description datatalent-testimonials">“{ item.details }”</p> : <><p className="description datatalent-testimonials">“{ item.details.substring(0, 160) + "..." }<button onClick={() => handleSeeMoreclick(i)} className="datatalent-testimonial-see-more">see more</button>”</p></>
                            : <p className="description datatalent-testimonials">“{ item.details }”</p>}
                        <div className="client-information">
                            {item.image && <div className="thumb">
                                <img src={`${process.env.PUBLIC_URL}/images/${item.image}`} alt="Client Thumb" />
                            </div>}
                            <div className="info">
                                <h6 className="title">{ item.name }</h6>
                                {/* <span className="designation">{ item.designation }</span> */}
                            </div>
                        </div>
                    </div>
                </div>
            ) ) }
        </Slider>
    );
}
export default DataTalentTestimonial;