import React from 'react';
import { Element } from 'react-scroll';
import AppliedAISolutionsSectionTitle from './AppliedAISolutionsSectionTitle';
// import { Link } from 'react-router-dom';

const AppliedAISolutionsDescription = () => {
  return (
    <>
      <Element
        name="introduction"
        className="data-talent-description-content service-wrapper-1 style-variation3 blog-main-content justify-content-center edu-section-gap" id="AppliedAISolutionsIntroduction"
      >
        <div className="container eduvibe-animated-shape">
          <div className="row g-5">
            <div className="col-lg-12">
              <AppliedAISolutionsSectionTitle
                classes = "text-center"
                title = "Transform your Business offerings with our AI Solutions"
              />
            </div>
            <div className="col-lg-5 d-flex align-items-center justify-content-center">
              <img src={`${process.env.PUBLIC_URL}/images/applied-ai-solutions/applied_ai_solutions_v2.webp`} alt="Riipen and M2M logo" />
            </div>
            <div className="col-lg-7 mt--40 data-talent-description">
              <p>
                At M2M Tech, we are dedicated to revolutionizing industries through the power of Artificial Intelligence. Our mission is to harness the latest advancements in AI technology to provide innovative solutions that drive growth, efficiency, and success for businesses of all sizes. With a team of expert Data Scientists, Machine Learning Engineers, and AI specialists, we are committed to delivering cutting-edge AI solutions tailored to meet the unique needs and challenges of each client.
              </p>
              <p>
                Our passion for innovation and our relentless pursuit of excellence fuel everything we do. We believe in the transformative power of AI to unlock new opportunities, streamline processes, and unlock insights that drive informed decision-making. Whether you're looking to optimize operations, enhance customer experiences, or unlock new revenue streams, we're here to help you leverage the full potential of AI to achieve your goals.
              </p>
              <p>
                From Data Analysis and Predictive Analytics to Natural Language Processing and Computer Vision, we offer a comprehensive suite of AI services designed to empower your business to thrive in today's digital landscape. Partner with us, and let's embark on a journey of innovation together.
              </p>
            </div>
          </div>
          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-01.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-2">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-08.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-4">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-02.png`} alt="Shape Thumb" loading="lazy" />
            </div>
          </div>
        </div>
        
      </Element>
    </>
  )
}

export default AppliedAISolutionsDescription;