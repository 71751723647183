import React, { useEffect } from 'react';
import { Element } from 'react-scroll';
import SectionTitle from '../sectionTitle/SectionTitle';

const AboutUsContactUs = () => {

  useEffect(() => {
    const script = document.createElement('script');
  
    script.src = "https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js";
    script.async = true;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <>
      <Element
        name="partnerships-form"
        className="eduvibe-contact-us edu-contact-us-area edu-section-gap home-one-cat" id="PartnershipsForm"
      >
        <div className="container eduvibe-animated-shape">
          <div className="row g-5">
            <div className="col-12">
              <SectionTitle
                classes="text-center"
                title="Get In Touch With Us"
                slogan="Partnership Opportunities"
              />
            </div>
            {/* <div className="col-lg-6">
              <div className="contact-info pr--70 pr_lg--0 pr_md--0 pr_sm--0">
                <div className="row g-5">
                  <div className="col-lg-12 col-md-6 col-sm-6 col-12">
                    <div className="contact-address-card-1 phone">
                      <div className="inner">
                        <div className="icon">
                          <i className="icon-Headphone"></i>
                        </div>
                        <div className="content">
                          <h6 className="title">Call Us On</h6>
                          <p><a href="tel: +1-604-351-4512">+1-604-351-4512</a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-6 col-sm-6 col-12">
                    <div className="contact-address-card-1 email">
                      <div className="inner">
                        <div className="icon">
                          <i className="icon-mail-open-line"></i>
                        </div>
                        <div className="content">
                          <h6 className="title">Email Us</h6>
                          <p><a href="mailto:contact@m2mtechconnect.com">contact@m2mtechconnect.com</a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-12 mt--40">
              <iframe
                id="JotFormIFrame-241496246898070"
                title="Partner With Us"
                onLoad={window.parent.scrollTo(0,0)}
                allowtransparency="true"
                allow="geolocation; microphone; camera; fullscreen"
                src="https://form.jotform.com/241496246898070"
                frameBorder="0"
                className="partnerships-form-frame"
                scrolling="yes"
              >
              </iframe>
            </div>
          </div>

          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src={process.env.PUBLIC_URL+"/images/shapes/shape-04-01.png"} alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-2">
              <img src={process.env.PUBLIC_URL+"/images/shapes/shape-02-08.png"} alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-3">
              <img src={process.env.PUBLIC_URL+"/images/shapes/shape-15.png"} alt="Shape Thumb" />
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default AboutUsContactUs;