import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';

const AddReviewToEntityForm = ({
  setShowAddReviewToEntityForm,
  entityName,
  setAddReviewToEntityFormSubmittedSuccessfully
}) => {

  const [annualClients, setAnnualClients] = useState();
  const [infractionSeverity, setInfractionSeverity] = useState("");
  const [quarterComplaintSeverity, setQuarterComplaintSeverity] = useState("");
  const [quarterSentimentAnalysis, setQuarterSentimentAnalysis] = useState("");
  const [inspectionResult, setInspectionResult] = useState("");
  const [reviewDate, setReviewDate] = useState("");

  const createEntityAPI = process.env.REACT_APP_CREATE_ENTITY;

  const infractionSeverityOptions = [
    {
      label: "none",
      value: "none"
    },
    {
      label: "minor",
      value: "minor"
    },
    {
      label: "major",
      value: "major"
    },
  ]

  const quarterComplaintSeverityOptions = [
    {
      label: "none",
      value: "none"
    },
    {
      label: "minor",
      value: "minor"
    },
    {
      label: "major",
      value: "major"
    },
  ]

  const quarterSentimentAnalysisOptions = [
    {
      label: "none",
      value: "none"
    },
    {
      label: "flagged",
      value: "flagged"
    },
  ]

  const inspectionResultOptions = [
    {
      label: "none",
      value: "none"
    },
    {
      label: "pass",
      value: "pass"
    },
    {
      label: "fail",
      value: "fail"
    },
  ]
  
  const resetAddReviewToEntityForm = () => {
    setAnnualClients();
    setInfractionSeverity("");
    setQuarterComplaintSeverity("");
    setQuarterSentimentAnalysis("");
    setInspectionResult("");
    setReviewDate("");
  }

  const handleAnnualClientChange = (e) => {
    setAnnualClients(e.target.value);
  }

  const handleInfractionSeveritySelect = (selected) => {
    setInfractionSeverity(selected.value);
  }
  
  const handleQuarterComplaintSeveritySelect = (selected) => {
    setQuarterComplaintSeverity(selected.value);
  }
  
  const handleQuarterSentimentAnalysisSelect = (selected) => {
    setQuarterSentimentAnalysis(selected.value);
  }
  
  const handleInspectionResultSelect = (selected) => {
    setInspectionResult(selected.value);
  }

  const handleDateChange = (e) => {
    setReviewDate(e.target.value);
  }

  const handleAddReviewToEntityFormSubmit = (e) => {
    e.preventDefault();
    let fields = {};
    let apiURL = createEntityAPI;

    fields.annualClients = annualClients;
    fields.infractionSeverity = infractionSeverity;
    fields.quarterComplaintSeverity = quarterComplaintSeverity;
    fields.quarterSentimentAnalysis = quarterSentimentAnalysis;
    fields.inspectionResult = inspectionResult;
    fields.reviewDate = reviewDate;
    apiURL = apiURL + `/${entityName}/review/create`;

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(fields)
    };
    // console.log("API and request options are : ", apiURL, requestOptions);
    fetch(apiURL, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        toast.success("Form submitted successfully!");
        console.log(result.message);
        setAddReviewToEntityFormSubmittedSuccessfully(true);
      })
      .catch((error) => {
        toast.error("Form submission failed. Try again!");
        console.error(error.message);
      });
      setShowAddReviewToEntityForm(false);
  }

  // useEffect(() => {
  //   console.log("Annual Clients entered is: ", annualClients);
  // }, [annualClients])
  // useEffect(() => {
  //   console.log("Infraction severity selected is: ", infractionSeverity);
  // }, [infractionSeverity])
  // useEffect(() => {
  //   console.log("quarter Complaint Severity selected is: ", quarterComplaintSeverity);
  // }, [quarterComplaintSeverity])
  // useEffect(() => {
  //   console.log("quarter Sentiment Analysis selected is: ", quarterSentimentAnalysis);
  // }, [quarterSentimentAnalysis])
  // useEffect(() => {
  //   console.log("inspection Result selected is: ", inspectionResult);
  // }, [inspectionResult])
  // useEffect(() => {
  //   console.log("Review Date selected is: ", reviewDate);
  // }, [reviewDate])

  useEffect(() => {
    const currentDate = new Date().toISOString().split('T')[0];
    setReviewDate(currentDate);
  }, []);

  return (
    <form onSubmit={handleAddReviewToEntityFormSubmit}>
      <div className="add-entity-form enrollment-form-container">
        <div className="enrollment-form-wrapper">
          <h3 className="enrollment-form-head">Add Review To Entity Form<span className="enrollment-form-close-btn" onClick={() => {setShowAddReviewToEntityForm(false); resetAddReviewToEntityForm();}}>&#x2715;</span></h3>
          <div className="enrollment-form-body d-flex flex-column align-items-center gap-0">
            <label>
              Entity Name<br></br>
              <input className="add-entity-select" type="text" value={entityName} disabled></input>
            </label>            
            <h5 className="mt-5 mb-0">Review Details</h5>
            <label>
              Annual Clients<br></br>
              <input
                className="add-entity-select"
                type="number"
                min="0"
                value={annualClients}
                onChange={handleAnnualClientChange}
                required
              />
            </label>
            <label className="mt-4">
              Infraction Severity
              <Select
                className="basic-single add-entity-select"
                placeholder="Select or search by typing..."
                isClearable="true"
                name="clientName"
                onChange={handleInfractionSeveritySelect}
                options={infractionSeverityOptions}
                required
              />
            </label>
            <label className="mt-4">
              Quarter Complaint Severity
              <Select
                className="basic-single add-entity-select"
                placeholder="Select or search by typing..."
                isClearable="true"
                name="clientName"
                onChange={handleQuarterComplaintSeveritySelect}
                options={quarterComplaintSeverityOptions}
                required
              />
            </label>
            <label className="mt-4">
              Quarter Sentiment Analysis
              <Select
                className="basic-single add-entity-select"
                placeholder="Select or search by typing..."
                isClearable="true"
                name="clientName"
                onChange={handleQuarterSentimentAnalysisSelect}
                options={quarterSentimentAnalysisOptions}
                required
              />
            </label>
            <label className="mt-4">
              Inspection Result
              <Select
                className="basic-single add-entity-select"
                placeholder="Select or search by typing..."
                isClearable="true"
                name="clientName"
                onChange={handleInspectionResultSelect}
                options={inspectionResultOptions}
                required
              />
            </label>
            <label className="mt-4 mb-5">
              Date<br></br>
              <input type="date" className="add-entity-select" name="date" onChange={handleDateChange} value={reviewDate}></input>
            </label>
          </div>
          <div className="enrollment-form-footer">
            <button className='edu-btn btn-bg-alt' onClick={() => {setShowAddReviewToEntityForm(false); resetAddReviewToEntityForm()}}>Cancel</button>
            <button className='edu-btn btn-secondary' type="submit">Submit</button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default AddReviewToEntityForm;