import React, { useEffect, useState } from 'react';
import { Element } from 'react-scroll';
import JobseekerSectionTitle from './JobseekerSectionTitle';
// import Select from 'react-select';
import JobseekerFormTest from '../form/jobseeker-form/JobseekerFormTest';
import Pie from '../../utils/pie';
import { Tooltip } from 'react-tooltip';

const clients_data = [
  {
    "name" : "Ishwari Upadhyay",
    "workAuthorization" : "Canadian Work Permit",
    "threePlusYearsExperience" : true,
    "programmingLanguages" : "Python",
    "dataBaseExperience" : "SQL, PostgreSQL",
    "currentlyEnrolledInCanadianUniversity" : true,
    "rolesInterestedIn" : "Software Developer/Automation Developer, ML Engineer/AI Engineer",
    "linkedin" : "",
    "github" : "",
    "temporarilyDepost500": "Yes"
  },
  {
    "name" : "Hammad Qazi",
    "workAuthorization" : "Canadian Citizen",
    "threePlusYearsExperience" : false,
    "programmingLanguages" : "Python, C/C++",
    "dataBaseExperience" : "SQL",
    "currentlyEnrolledInCanadianUniversity" : true,
    "rolesInterestedIn" : "Data Analyst/Data Scientist, ML Engineer/AI Engineer, Business Analyst",
    "linkedin" : "",
    "github" : "https://github.com/Hammad-Q123",
    "temporarilyDepost500": "Yes"
  },
  {
    "name" : "Adam Elmongy",
    "workAuthorization" : "Canadian Citizen",
    "threePlusYearsExperience" : false,
    "programmingLanguages" : "Python, Java, C/C++",
    "dataBaseExperience" : "SQL,",
    "currentlyEnrolledInCanadianUniversity" : true,
    "rolesInterestedIn" : "Software Developer/Automation Developer, Data Analyst/Data Scientist, ML Engineer/AI Engineer",
    "linkedin" : "",
    "github" : "https://www.github.com/adamchamp",
    "temporarilyDepost500": "Yes"
  },
  {
    "name" : "Karly Dore",
    "workAuthorization" : "Canadian Citizen",
    "threePlusYearsExperience" : true,
    "programmingLanguages" : "",
    "dataBaseExperience" : "",
    "currentlyEnrolledInCanadianUniversity" : false,
    "rolesInterestedIn" : "Data Analyst/Data Scientist, Business Analyst",
    "linkedin" : "",
    "github" : "",
    "temporarilyDepost500": "Yes"
  },
  {
    "name" : "Tulika Jain",
    "workAuthorization" : "Canadian Permanent Resident",
    "threePlusYearsExperience" : true,
    "programmingLanguages" : "Python",
    "dataBaseExperience" : "SQL",
    "currentlyEnrolledInCanadianUniversity" : false,
    "rolesInterestedIn" : "Business Analyst",
    "linkedin" : "",
    "github" : "",
    "temporarilyDepost500": "Yes"
  },
]

const JobseekerFormSectionTest = () => {

  const [clientsName, setClientsName] = useState();
  const [selectedClient, setSelectedClient] = useState();
  const [outputData, setOutputData] = useState();
  const [percentValue, setPercentValue] = useState();

  // const reset = () => {
  //   setSelectedClient();
  // }

  // const handleClientNameSelect = (selected) => {
  //   selected ? setSelectedClient(selected.value) : reset();
  // }

  // const handleFileInputChange = () => {

  // }

  // const handleJobseekerFormSubmit = (e) => {
  //   e.preventDefault();

  // }

  const handleBtnClick = (element, id) => {
    const selectedButton = document.getElementById(id);
    if(selectedButton.classList.contains("btn-bg-alt")) {
      selectedButton.classList.remove("btn-bg-alt");
      setPercentValue(percentValue => (((percentValue * 100) + (parseFloat(element.weight) * 100)) / 100).toFixed(2));
    } else {
      selectedButton.classList.add("btn-bg-alt");
      setPercentValue(percentValue => (((percentValue * 100) - (parseFloat(element.weight) * 100)) / 100).toFixed(2));
    }
  }

  const handleRemoveItem = () => {

  }

  const handleChangeWeight = () => {

  }

  const handleDisableEditMode = () => {

  }
  

  useEffect(() => {
    let temp = [];
    for(let i = 0; i < clients_data.length; i++) {
      const { name, workAuthorization, threePlusYearsExperience, programmingLanguages, dataBaseExperience, currentlyEnrolledInCanadianUniversity, rolesInterestedIn, linkedin, github, temporarilyDepost500 } = clients_data[i];
      temp.push({
        "value": {
          name: name,
          workAuthorization : workAuthorization,
          threePlusYearsExperience : threePlusYearsExperience,
          programmingLanguages : programmingLanguages,
          dataBaseExperience : dataBaseExperience,
          currentlyEnrolledInCanadianUniversity : currentlyEnrolledInCanadianUniversity,
          rolesInterestedIn : rolesInterestedIn,
          linkedin : linkedin,
          github : github,
          temporarilyDepost500 : temporarilyDepost500
        },
        "label" : clients_data[i].name
      });
    }
    setClientsName([...temp]);
  }, [])

  useEffect(() => {
    if(outputData) {
      console.log(outputData);
      setPercentValue(outputData.employability_percentage.toFixed(2));
    }
  }, [outputData])

  // useEffect(() => {

  // })

  return (
    <>
      <Element
        name="aiform" 
        className="edu-about-area about-style-1 edu-section-gap"
      >
        <div className="container inner about-feature-list">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <JobseekerSectionTitle
                classes = "text-center"
                title = "Jobseeker"
              />
            </div>
            <div className="col-12 row client-details-container mt--40">
              <div className="col-12 d-flex flex-column align-items-center justify-content-center">
                <JobseekerFormTest clientsName={clientsName} setOutputData={setOutputData} selectedClient={selectedClient} setSelectedClient={setSelectedClient} />
              </div>
              {/* {selectedClient && <div className="col-12 mt--80">
                <h4>Selected client's details</h4>

                <p><b>Name</b> : {JSON.parse(selectedClient).name}</p> */}
                {/* <p><b>Work authorization</b> : {selectedClient.workAuthorization}</p>
                <p><b>3+ years of experience</b> : {selectedClient.threePlusYearsExperience ? "Yes" : "No"}</p>
                <p><b>Programming languages</b> : {selectedClient.programmingLanguages}</p>
                <p><b>Database experience</b> : {selectedClient.dataBaseExperience}</p>
                <p><b>Currently enrolled in any Canadian University</b> : {selectedClient.currentlyEnrolledInCanadianUniversity ? "Yes" : "No"}</p>
                <p><b>Roles interested in</b> : {selectedClient.rolesInterestedIn}</p> */}
              {/* </div>} */}
              {outputData && <div className="col-12 mt--80">
                <h6>Jobseeker prediction</h6>
                <div className="d-flex flex-row justify-content-evenly align-items-center mt--40">
                  <Pie percentage={percentValue} />
                  <h2>{percentValue > 100 ? 100 : percentValue}%</h2>
                </div>
                <h6 className="mt--40">Recommended Plan items to increase chances to find a Job</h6>
                <div className="recommended-plan-items-container row mt--20">
                  {outputData.plan_items.map((element, idx) => (
                    <div className="button-wrapper col-12 col-md-6 d-flex flex-row align-items-center mt-3" key={idx}>
                      {element.reason ? <><button className="recommended-plan-item edu-btn btn-bg-alt w-100 h-100" data-tooltip-id={parseInt(idx) + 1} data-tooltip-content={element.reason} data-tooltip-delay-show={500} id={parseInt(idx) + 1} value={element.weight} onClick={() => handleBtnClick(element, parseInt(idx) + 1)} disabled={element.editMode ? true : false}>{element.item}&nbsp;&#40;{element.weight}&#41;</button><Tooltip id={parseInt(idx) + 1} /></> : <button className="recommended-plan-item edu-btn btn-bg-alt w-100 h-100" id={parseInt(idx) + 1} value={element.weight} onClick={() => handleBtnClick(element, parseInt(idx) + 1)} disabled={element.editMode ? true : false}>{element.item}&nbsp;&#40;{element.weight}&#41;</button>}
                      {element.removable && ( !element.editMode ? <>
                        {/* &nbsp;<button className="cursor-pointer edu-btn btn-bg-alt px-2" onClick={() => {setShowEdit(true); setElement(element)}}>✏️</button> */}
                        {/* &nbsp;<button className="cursor-pointer edu-btn btn-bg-alt px-2" onClick={() => handleEditMode(element)}>✏️</button> */}
                        &nbsp;<button className="cursor-pointer edu-btn btn-bg-alt px-2" onClick={() => handleRemoveItem(element)}>🗑️</button>
                      </> : <>
                        &nbsp;<input type="number" name="weight" style={{maxWidth:"68px"}} value={element.weight} onChange={(e) => handleChangeWeight(e, element.item)} />
                        &nbsp;<button className="cursor-pointer edu-btn btn-bg-alt px-2" onClick={() => { handleDisableEditMode(element.item)}}>✔️</button>
                        {/* &nbsp;<button className="cursor-pointer edu-btn btn-bg-alt px-2" onClick={() => handleDisableEditMode(element.id)}>❌</button> */}
                      </>)}
                    </div>
                  ))}
                </div>
              </div>}
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default JobseekerFormSectionTest;