import React from 'react';

const ComplianceRateInfo = ({hideComplianceRateInfo}) => {

  return (
    <div className="risk-classification-container hidePopup" onClick={hideComplianceRateInfo} id="ComplianceRatePopup">
      <div className="risk-classification-content">
        <p>Compliance Rate = (Total number of Passed Inspection entities / Total number of entities) X 100.</p>
      </div>
    </div>
  )
}

export default ComplianceRateInfo;