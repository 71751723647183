import React from 'react';
import { Element } from 'react-scroll';
import SkillsboostSectionTitle from './SkillsboostSectionTitle';

const SkillsboostDescription = () => {
  return (
    <>
      <Element
        name="introduction"
        className="data-talent-description-content service-wrapper-1 style-variation3 blog-main-content justify-content-center edu-section-gap" id="SkillsboostIntroduction"
      >
        <div className="container eduvibe-animated-shape">
          <div className="row g-5">
            <div className="col-12">
              <SkillsboostSectionTitle
                classes = "text-center"
                title = "Overview"
              />
            </div>
            <blockquote className="col-12 skillsboost-description blockquote-style-1 mt--30">“ Our upskilling programs focuses on gaining hands-on Emerging Tech skills in Data Science (Data Analysis, Data Visualization, Machine Learning, Deep Learning, Artificial Intelligence) which is sought after by Employers in Digital Technology sectors, CleanTech, Advanced Manufacturing, Business Intelligence, Finance and Healthcare sectors. ”
              <img className="quote-image" src="/images/icons/quote-2.png" alt="Quote Images" />
            </blockquote>
          </div>
          
          <div className="row g-5 mt--40">
            <div className="col-lg-12">
              <SkillsboostSectionTitle
                classes = "text-center"
                title = "Who Should Take This?"
              />
            </div>
            <div className="col-12 col-lg-6">
              {/* <p className="mt--20"><strong>Imp Note</strong>: All our current Programs are focused on Mid-career workers (<strong>minimum 3 years of Work Experience</strong>) who currently live and work in Canada</p> */}
              <ul className=" skillsboost-who-should-take-this">
                <li>Data Enthusiasts</li>
                <li>Aspiring Data Analysts/Scientists, Business Analysts</li>
                <li>IT and Tech Professionals</li>
                <li>Career Changers</li>
                <li>Higher secondary school students</li>
                <li>University students</li>
                <li>Recent graduates</li>
              </ul>
            </div>
              <div className="col-12 col-lg-6">
                <img src={`${process.env.PUBLIC_URL}/images/skillsboost/undraw_proud_coder_re_exuy.svg`} alt="illustration" />
              </div>
          </div>
          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-01.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-2">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-08.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-3">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-04-01.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-4">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-02.png`} alt="Shape Thumb" loading="lazy" />
            </div>
          </div>
        </div>
        
      </Element>
    </>
  )
}

export default SkillsboostDescription;