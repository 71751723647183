import React from 'react';
// import Slider from 'react-slick';
// import { ProgramSponsorsParams, ProgramSponsorsMobileParams, ProgramSponsorsTabletParams } from '../../utils/script';
import SectionTitle from '../sectionTitle/SectionTitle';

let sponsors = [
  {
    id: 1,
    image: "/images/logo/upskill_canada_logo_v3.png",
    alt: "Palette",
  },
  {
    id: 2,
    image: "/images/sponsors/riipen_logo.png",
    alt: "Riipen",
  },
  {
    id: 3,
    image: "/images/sponsors/aimodels_logo.jpg",
    alt: "AiModels",
  },
  {
    id: 4,
    image: "/images/logo/upskill_canada_logo_v3.png",
    alt: "Palette",
  },
  {
    id: 5,
    image: "/images/sponsors/riipen_logo.png",
    alt: "Riipen",
  },
  // {
  //     image: "/images/sponsors/ictc-website-logo-200x90.svg",
  //     alt: "ICTC",
  // },
  // {
  //     id: 3,
  //     image: "/images/sponsors/technation-logo-black.svg",
  //     alt: "Technation",
  // },
  // {
  //     image: "/images/sponsors/InnovateBC_Logomark_teal_rev-1.webp",
  //     alt: "Innovate BC",
  // },
  // {
  //     id: 4,
  //     image: "/images/sponsors/ECO-Logo.svg",
  //     alt: "Eco Canada",
  // },
  // {
  //     id: 5,
  //     image: "/images/sponsors/Cican-logo-en.webp",
  //     alt: "CiCan",
  // },
  // {
  //     id: 6,
  //     image: "/images/sponsors/magnet-logo.png",
  //     alt: "Magnet",
  // },
]

const AboutUsOurFocusArea = () => {
  // const classes = `testimonial-activation testimonial-style-1 edu-slick-button slick-button-left`;
  const item = 5;
  sponsors = item ? sponsors.slice( 0, item ) : sponsors;

  return (
    <>
    <div
        name="about-us"
        className="service-wrapper-1 style-variation3 blog-main-content justify-content-center edu-section-gap"
      >
        <div className="container about-feature-list eduvibe-animated-shape">
          <div className="row g-5">
            <SectionTitle
              classes="text-center col-12"
              title="Our Focus Area"
              slogan="What We Focus"
            />
            <div className="our-focus-area-card-odd col-12 mt--40 d-flex flex-wrap">
              <div className="col-12 col-lg-7 pe-5">
                <h5 className="mt-20">Talent Development & Industry Engagement</h5>
                <p className="about-us-banner-point">We are committed to nurturing a highly skilled Canadian workforce through our programs like DataTalent and SkillsBoost, which have seen over 10,000 enrollments. We engage with Industry Leaders via Employer Led Work Integrated Learning projects across various sectors, ensuring our talent pool is ready to meet the demands of a Digital Economy expected to employ 2.26 million digitally skilled Canadian workers by 2025.</p>
              </div>
              <div className="col-12 col-lg-5">
                <img src={`${process.env.PUBLIC_URL}/images/about/about-09/talent_development.jpg`} alt="Shape Thumb" />
              </div>
            </div>
            <div className="col-12 section-divider d-flex justify-content-center mt--60">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-05-02.png`} alt="illustration" />
            </div>
            <div className="our-focus-area-card-even col-12 mt--40 d-flex flex-wrap">
              <div className="col-12 col-lg-5 order-2 order-lg-1">
                <img src={`${process.env.PUBLIC_URL}/images/about/about-09/computing_capabilities.jpg`} alt="Shape Thumb" />
              </div>
              <div className="col-12 col-lg-7 ps-5 order-1 order-lg-2">
                <h5 className="mt-20">Computing Capabilities & Technology Infrastructure</h5>
                <p className="about-us-banner-point">Our expertise lies in AI and Automation, developing and deploying AI algorithms and solutions at scale. We excel in Data Processing and Analytics, leveraging data from IoT devices and sensors, and providing robust cybersecurity solutions to protect critical infrastructure. Our focus on Data Sovereignty ensures sensitive data is securely stored and processed.</p>
              </div>
            </div>
            <div className="col-12 section-divider d-flex justify-content-center mt--60">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-05-02.png`} alt="illustration" />
            </div>
            <div className="our-focus-area-card-odd col-12 mt--40 d-flex flex-wrap">
              <div className="col-12 col-lg-7 pe-5">
                <h5 className="mt-20">Digital & Green Transformation</h5>
                <p className="about-us-banner-point">We are dedicated to driving data-driven decision-making by utilizing analytics and business intelligence tools. Our efforts in digitizing customer engagement enhance interactions and relationships. We champion the adoption of renewable energy sources and the implementation of sustainable supply chain processes to minimize environmental impact.</p>
                <p className="about-us-banner-point">We prioritize creating a sustainable job economy.  This commitment allows us to deliver training programs that equip individuals with the necessary skills for sustainability-focused roles, driving innovation while benefiting the environment and society.</p>
              </div>
              <div className="col-12 col-lg-5">
                <img src={`${process.env.PUBLIC_URL}/images/about/about-09/green_transformation.jpg`} alt="Shape Thumb" />
              </div>
            </div>
            {/* <div className="sponsors-desktop row g-5 mt--10">
              <Slider 
                className={ classes }
                { ...ProgramSponsorsParams }
              >
                { sponsors.map( ( item ) => (
                  <div key={item.id} className="sponsors-wrapper mt--20 mb--40">
                    <div className="inner">
                      <div className="icon">
                        <img src={`${process.env.PUBLIC_URL+item.image}`} alt={item.alt} />
                      </div>
                    </div>
                  </div>
                ) ) }
              </Slider>
            </div>
            
            <div className="sponsors-tablet row g-5 mt--10">
              <Slider 
                className={ classes }
                { ...ProgramSponsorsTabletParams }
              >
                { sponsors.map( ( item ) => (
                  <div key={item.id} className="sponsors-wrapper mt--20 mb--40">
                    <div className="inner">
                      <div className="icon">
                        <img src={`${process.env.PUBLIC_URL+item.image}`} alt={item.alt} />
                      </div>
                    </div>
                  </div>
                ) ) }
              </Slider>
            </div>
            
            <div className="sponsors-mobile row g-5 mt--10">
              <Slider 
                className={ classes }
                { ...ProgramSponsorsMobileParams }
              >
                { sponsors.map( ( item ) => (
                  <div key={item.id} className="sponsors-wrapper mt--20 mb--40">
                    <div className="inner">
                      <div className="icon">
                        <img src={`${process.env.PUBLIC_URL+item.image}`} alt={item.alt} />
                      </div>
                    </div>
                  </div>
                ) ) }
              </Slider>
            </div> */}
          </div>
          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-01.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-2">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-08.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-3">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-04-01.png`} alt="Shape Thumb" loading="lazy" />
            </div>
            <div className="shape-image shape-image-4">
              <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-03-02.png`} alt="Shape Thumb" loading="lazy" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutUsOurFocusArea;