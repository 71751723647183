import React from 'react';
import AMSectionTitle from './AMSectionTitle';
import AMTestimonial from './AMTestimonial';
import Slider from 'react-slick';

const logos = [
    {
        image: "Andersen.jpeg"
    },
    {
        image: "bgis_logo.jpeg"
    },
    {
        image: "bpp_professional_education_logo.jpeg"
    },
    {
        image: "CAE.jpeg"
    },
    {
        image: "canadian_food_inspection_agency_logo.jpeg"
    },
    {
        image: "Dashio.jpeg"
    },
    {
        image: "government_of_saskatchewan_logo.jpeg"
    },
    {
        image: "healthcare_systems_r_a_logo.jpeg"
    },
    {
        image: "logo3.jpeg"
    },
    {
        image: "logo4.jpeg"
    },
    {
        image: "Oxford.jpeg"
    },
    {
        image: "QCA.jpeg"
    },
    {
        image: "Teck.jpeg"
    },
    {
        image: "Telus.jpeg"
    }
]

const AMTestimonialSection = () => {
    const classes = `testimonial-activation edu-slick-button slick-button-left`;

    const logoSliderMobile = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    const logoSlider = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <div className="eduvibe-testimonial-dataTalent datatalent-section-gap edu-testimonial-area bg-image">
            <div className="container eduvibe-animated-shape">
                <div className="row g-5 datatalent-testimonials-container">
                    <div className="col-lg-12 p-0 mt-0">
                        <AMSectionTitle
                            classes = "text-left"
                            slogan = "Testimonials"
                            title = "What Our Past Participants Says About Us"
                        />
                    </div>
                    <div className="col-lg-8 col-12 p-0 mt-0">
                        <AMTestimonial />
                    </div>
                    <div className="col-lg-4 p-0">
                        <img src={`${process.env.PUBLIC_URL}/images/data-talent/testimonials/testimonials-illustration.gif`} alt="illustration" />
                    </div>
                    <div className="col-lg-12 p-0 mt-0">
                        <div className="datatalent-participants-working-at">
                            <img className="col-lg-1" src={`${process.env.PUBLIC_URL}/images/data-talent/testimonials/testimonial-company.png`} alt="illustration" />
                            <p className="col-lg-5 dpwa-description mb-md-0">Using the skills and experience gained in our programs, our past participants have gone on to work at leading companies, including:</p>
                            <div className="col-12 col-lg-6 logo-slider d-block d-md-none">
                                <Slider
                                    className={ classes }
                                    { ...logoSliderMobile }
                                >
                                    {logos.map( ( item, id ) => (
                                        <div className="single-testimonial" key={ id }>
                                            <div className="inner">
                                                <img width={100} src={`${process.env.PUBLIC_URL}/images/data-talent/logos/${item.image}`} alt="logo" />
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                            <div className="col-12 col-lg-6 logo-slider d-none d-md-block">
                                <Slider
                                    className={ classes }
                                    { ...logoSlider }
                                >
                                    {logos.map( ( item, id ) => (
                                        <div className="single-testimonial" key={ id }>
                                            <div className="inner">
                                                <img width={100} src={`${process.env.PUBLIC_URL}/images/data-talent/logos/${item.image}`} alt="logo" />
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <a className="edu-btn" href="https://www.google.com/search?q=m2m+tech&rlz=1C1CHBF_enCA1045CA1045&oq=m2m+tech&aqs=chrome.0.0i355i512j46i175i199i512j0i512l3j69i60l3.3993j0j7&sourceid=chrome&ie=UTF-8#">Get Started<i className="icon-arrow-right-line-right"></i></a> */}

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-13.png`} alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-13-01.png`} alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-04.png`} alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-4">
                        <img src={`${process.env.PUBLIC_URL}/images/shapes/shape-02-01.png`} alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AMTestimonialSection;