import React from "react";

const ModalWarningOverride = ({
  setAcceptWarning,
  setShowWarning
}) => {

  return (
    <>
    <div className="warning_wrapper">
      <div className="warning_container">
        <h3 className="warning_heading text-center">Warning! You are about to modify the Model's recommendations.</h3>
        <p>Do you really want to proceed?</p>
        <div className="button-container d-flex flex-row justify-content-between">
          <button className="accept_warning edu-btn btn-primary" onClick={() => {setAcceptWarning(true); setShowWarning(false);}}>Yes</button>
          <button className="reject_warning edu-btn btn-secondary" onClick={() => setShowWarning(false)}>Cancel</button>
        </div>
      </div>
    </div>
    </>
  )
}

export default ModalWarningOverride;