import React from 'react';

const Top5HighRiskTable = ({hideTop5HighPopup}) => {

  return (
    <div className="risk-classification-container hidePopup" onClick={hideTop5HighPopup} id="Top5HighRiskPopup">
      <div className="risk-classification-content">
        <p>These are the top 5 High Risk Entities.<br></br>
          Click on a row to go to details.</p>
      </div>
    </div>
  )
}

export default Top5HighRiskTable;
