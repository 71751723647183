import React from 'react';

const FeatureImportanceInfo = ({hideFeatureImportancePopup}) => {

  return (
    <div className="risk-classification-container hidePopup" onClick={hideFeatureImportancePopup} id="FeatureImportancePopup">
      <div className="risk-classification-content">
        <p>This waterfall bar graph shows the percentage of each feature based on its importance to a total of 100%.</p>
      </div>
    </div>
  )
}

export default FeatureImportanceInfo;